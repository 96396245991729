import classNames from 'classnames';
import React, { useMemo } from 'react';
import Tonic from './tonic';
import Variant from './variant';

export default function ChordSymbol({ chord, className }) {
  const quality = useMemo(() => {
    if (chord.quality?.toLowerCase() === 'minor') {
      return 'min';
    }
    if (chord.quality?.toLowerCase() === 'diminished') {
      return 'dim';
    }

    return null;
  }, [chord]);

  return (
    <div className={classNames('flex whitespace-nowrap overflow-ellipsis items-end', className)}>
      <Tonic tonic={chord.tonic} className="mr-1" />
      {quality && <div className="mr-1 text-sm">{quality}</div>}
      <Variant variant={chord.variant} />
    </div>
  );
}
