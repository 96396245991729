import * as Tone from 'tone';
import { DurationValue } from '@tonaljs/tonal';

// ToneJs Value
// "1m" | "1n" | "1n." | // fourBeats
// "2n" | "2n." | "2t" | // twoBeats, threeBeats
// "4n" | "4n." | "4t" | // oneBeat, oneAndHalfBeat
// "8n" | "8n." | "8t" | // halfBeat, threeQuarterBeats
// "16n" | "16n." | "16t" | // quarterBeat
// "32n" | "32n." | "32t" |
// "64n" | "64n." | "64t" |
// "128n" | "128n." | "128t" |
// "256n" | "256n." | "256t" | "0";

// DurationValue from TonalJs
// const DATA: [number, string, string[]][] = [
//   [
//     0.125,
//     "dl",
//     ["large", "duplex longa", "maxima", "octuple", "octuple whole"],
//   ],
//   [0.25, "l", ["long", "longa"]],
//   [0.5, "d", ["double whole", "double", "breve"]],
//   [1, "w", ["whole", "semibreve"]],
//   [2, "h", ["half", "minim"]],
//   [4, "q", ["quarter", "crotchet"]],
//   [8, "e", ["eighth", "quaver"]],
//   [16, "s", ["sixteenth", "semiquaver"]],
//   [32, "t", ["thirty-second", "demisemiquaver"]],
//   [64, "sf", ["sixty-fourth", "hemidemisemiquaver"]],
//   [128, "h", ["hundred twenty-eighth"]],
//   [256, "th", ["two hundred fifty-sixth"]],
// ];

/**
 * Convert a tonejs value to a value between 0-1 which represents
 * how long in one measure where 1 = a whole measure, 0.5 = half
 * a measure, etc.
 *
 * @param {Tone.Unit.Subdivision} toneJsValue - a tonejs subdivision
 * @returns {number}
 */
export function timeToMeasureDuration(toneJsValue) {
  const [measures, beats, sixteenth] = Tone.Time(toneJsValue).toBarsBeatsSixteenths().split(':');
  return Number(measures) + (Number(beats) * (1 / 4)) + (Number(sixteenth) * (1 / 16));
}

/**
 * Conver ToneJs duration value to a TonalJs duration object
 *
 * @param {Tone.Unit.Subdivision} toneJsValue
 * @returns {import('@tonaljs/duration-value').DurationValue}
 */
export function timeToDuration(toneJsValue) {
  const durationName = toneJsValue.replace('256n', 'th')
    .replace('128n', 'h')
    .replace('64n', 'sf')
    .replace('32n', 't')
    .replace('16n', 's')
    .replace('8n', 'e')
    .replace('4n', 'q')
    .replace('2n', 'h')
    .replace('1n', 'w')
    .replace('1m', 'w');

  return DurationValue.get(durationName);
}
