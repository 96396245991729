import { createContext, useContext, useReducer } from 'react';

export const RomanNumeralContext = createContext(null);

export function useRomanNumeralContext() {
  return useContext(RomanNumeralContext);
}

/**
 *
 * @param {any} numeral
 * @returns {object}
 */
export function romanNumeralClicked(numeral) {
  return {
    type: 'romanNumeralClicked',
    payload: numeral,
  };
}

/**
 *
 * @param {any} numeral
 * @returns {object}
 */
export function variantNumeralClicked(numeral) {
  return {
    type: 'variantNumeralClicked',
    payload: numeral,
  };
}

export function backClicked() {
  return {
    type: 'backClicked',
  };
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function romanNumeralReducer(state, action) {
  switch (action.type) {
    case 'romanNumeralClicked': {
      return {
        ...state,
        selectedNumeral: action.payload,
        activeMenu: 'variants',
      };
    }

    case 'variantNumeralClicked': {
      return {
        ...state,
        selectedNumeralVariant: action.payload,
      };
    }

    case 'backClicked': {
      return {
        ...state,
        activeMenu: 'numerals',
      };
    }

    default:
      return state;
  }
}

export function useRomanNumeralReducer(initialState) {
  return useReducer(romanNumeralReducer, initialState);
}
