/* eslint-disable no-use-before-define */
import Collection from './collection';
import { sha256 } from './hash';

/**
 * @typedef {Object} FirestoreArrangement
 * @property {string} name - name of arrangement
 * @property {string} id - id of the arrangement
 * @property {string[]} tags - tags of arrangement
 * @property {string[]} sections - the sections of the arrangement
 */

/**
 * @param {FirestoreArrangement} arrangement
 * @returns {FirestoreArrangement}
 */
async function toFirestore(arrangement) {
  if (!arrangement.name) {
    throw new Error(`A name is required for arrangements but received ${arrangement.name}`);
  }

  if (arrangement.sections.length === 0) {
    throw new Error('Sections must not be empty. Make sure to add them.');
  }

  if (arrangement.tags.length === 0) {
    throw new Error('Tags must not be empty. Make sure to add tags to make the arrangement  easily searchable.');
  }

  return {
    name: arrangement.name,
    tags: arrangement.tags,
    sections: arrangement.sections,
    hash: await createArrangementHash(arrangement),
  };
}

/**
 * @param {DocumentSnapshot<FirestoreArrangement>} document
 * @returns {FirestoreArrangement}
 */
function fromFirestore(document) {
  const firestoreArrangement = document.data();

  return {
    ...firestoreArrangement,
    id: document.id,
    isFavorite: arrangements.isFavorite(document.id),
  };
}

/**
 * @param {FirestoreArrangement[]} arrangement
 * @returns {string}
 */
function createArrangementHash(arrangement) {
  return sha256(arrangement.sections.join('-'));
}

// singleton
const arrangements = new Collection('arrangements', { toFirestore, fromFirestore });

export default arrangements;
