import React from 'react';
import ReactTagsInput from 'react-tagsinput';
import AutosizeInput from 'react-input-autosize';
import PropTypes from 'prop-types';
import { X } from 'react-feather';

function TagsInput({
  onChange, value, id, onlyUnique = true, ...props
}) {
  return (
    <ReactTagsInput
      id={id}
      className="border rounded px-4 py-2 border-gray-300 dark:text-white"
      value={value}
      onlyUnique={onlyUnique}
      focusedClassName="focus:ring focus:border-blue-300"
      onChange={onChange}
      renderTag={(propsForTag) => {
        const {
          // eslint-disable-next-line react/prop-types
          tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other
        } = propsForTag;
        return (
          <button {...other} type="button" key={key} className="btn btn-sm btn-secondary mr-2">
            {getTagDisplayValue(tag)}
            {!disabled
                && <X className="inline-block" onClick={() => onRemove(key)} />}
          </button>
        );
      }}
      onRender={({ ...propsForInput }) => (
        <AutosizeInput className="border border-gray-300 dark:text-white" type="text" {...propsForInput} />
      )}
      {...props}
    />
  );
}

TagsInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TagsInput;
