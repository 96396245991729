import React from 'react';
import { Heart, X } from 'react-feather';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import {
  useRhythmsReducer,
  rhythmRowClicked,
} from './rhythms.store';
import RhythmPattern from '../components/rhythmPattern';
import RhythmPreviewToolbar from '../components/rhythmPreviewToolbar';
import { useUserContext } from '../api/user';
import Rhythms from '../api/rhythms';
import SearchableCollection from '../components/searchableCollection';

function RhythmsPage() {
  const [state, dispatch] = useRhythmsReducer();
  const { isAdmin } = useUserContext();
  const history = useHistory();
  const { selectedRhythm } = state;

  return (
    <div className="grid grid-cols-4 relative">
      <div className="col-span-1 p-4 md:hidden" />

      {/* main content */}
      <div className="col-span-2 p-4 md:col-span-4">
        <div className="text-3xl mb-2">Rhythms</div>
        <div className="text-base-content-dim">Guitar Rhythms</div>

        <SearchableCollection
          collection={Rhythms}
          newButtonClicked={() => history.push('/rhythms/create')}
        >
          {(rhythms, favoriteClicked) => (
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  <tr className="bg-neutral-900">
                    <th className="bg-transparent">Name</th>
                    <th className="bg-transparent">Pattern</th>
                    <th className="bg-transparent" aria-label="Favorite">
                      <button type="button" className="btn btn-circle btn-ghost disabled:bg-transparent" disabled>
                        <Heart />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rhythms.map((rhythm) => (
                    <tr
                      key={rhythm.id}
                      className={
                     classNames('hover cursor-pointer',
                       rhythm.id === selectedRhythm?.id && 'active')
                   }
                      onClick={() => dispatch(rhythmRowClicked(rhythm))}
                    >
                      <td>{rhythm.name}</td>
                      <td>
                        <RhythmPattern rhythm={rhythm} />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-circle btn-ghost"
                          onClick={(event) => {
                            event.stopPropagation();
                            favoriteClicked(rhythm);
                          }}
                        >
                          <Heart fill={rhythm.isFavorite ? 'currentColor' : 'none'} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </SearchableCollection>
      </div>

      <div className={classNames('col-span-1 p-6 md:p-0 md:h-full md:absolute md:top-0 md:left-0 md:w-full md:z-50', !selectedRhythm && 'hidden')}>
        {selectedRhythm && (
          <div className="md:bg-neutral-800 rounded p-4">
            <div className="flex justify-between">

              <div className="text-lg mb-2 text-white">{selectedRhythm.name}</div>

              <button type="button" className="btn btn-ghost btn-sm rounded-full" onClick={() => dispatch(rhythmRowClicked(null))}>
                <X />
              </button>
            </div>

            {/* PLAYBACK */}
            <RhythmPreviewToolbar rhythm={selectedRhythm} />

            {/* PATTERN */}
            <div className="mt-10">
              <div className="text-lg mb-2 text-white">Pattern</div>
              <RhythmPattern rhythm={selectedRhythm} />
            </div>

            {/* TAGS */}
            <div className="mt-10">
              <div className="text-lg mb-2 text-white">Tags</div>
              <div className="flex items-center mb-4">
                {selectedRhythm.tags.map((tag) => (
                  <div key={tag} className="mr-4">
                    {tag}
                  </div>
                ))}
              </div>
            </div>

            {isAdmin() && (
              <Link to={`/rhythms/${selectedRhythm.id}/edit`} className="btn btn-primary w-full">Edit</Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default RhythmsPage;
