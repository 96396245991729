import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from 'react-feather';
import classNames from 'classnames';
import {
  useRomanNumeralReducer,
  romanNumeralClicked,
  variantNumeralClicked,
  backClicked,
} from './romanNumeralPicker.store';

const SlideToLeft = {
  enter: 'transition-all transform duration-[350] ease-out',
  enterFrom: 'opacity-0 -translate-x-full',
  enterTo: 'opacity-100',
  leave: 'transition-all transform duration-[350] ease-in',
  leaveFrom: 'opacity-100 translate-x-0',
  leaveTo: 'opacity-0 -translate-x-full',
};

const FadeIn = {
  enter: 'transition-all ease-out',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-all ease-in',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

const NumeralPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  variants: PropTypes.arrayOf(PropTypes.string).isRequired,
});

function RomanNumeralPopover({
  numerals,
  selectedNumeral,
  selectedNumeralVariant,
  onChange,
  close,
}) {
  const [state, dispatch] = useRomanNumeralReducer({
    numerals,
    selectedNumeral,
    selectedNumeralVariant,
    activeMenu: 'numerals',
  });
  const { activeMenu } = state;

  return (
    <>
      <Transition
        {...SlideToLeft}
        className="w-full flex-shrink-0"
        show={activeMenu === 'numerals'}
        unmount
      >
        <div className="flex flex-col items-center p-2 w-full">
          {numerals.map((numeral) => (
            <button
              key={numeral.name}
              type="button"
              className={classNames('btn mb-1 w-full flex justify-between normal-case', numeral.name === state.selectedNumeral.name ? 'btn-primary' : 'btn-ghost')}
              onClick={() => {
                dispatch(romanNumeralClicked(numeral));
              }}
            >
              <div>
                {numeral.name}
              </div>

              <ChevronRight />
            </button>
          ))}
        </div>
      </Transition>

      <Transition
        {...FadeIn}
        className="w-full flex-shrink-0"
        show={activeMenu === 'variants'}
        unmount
      >
        <div className="inline-flex flex-col items-center p-2 w-full">
          <div className="flex items-center border-solid w-full my-2">
            <button type="button" className="btn btn-ghost btn-xs flex justify-start pl-0" aria-label="go back" onClick={() => dispatch(backClicked())}>
              <ChevronLeft />
              <div className="text-xs">
                Back
              </div>
            </button>
          </div>

          {state.selectedNumeral.variants.map((numeralVariant) => (
            <button
              key={numeralVariant}
              type="button"
              className={
                classNames('btn w-full mb-1 normal-case',
                  (selectedNumeral.name === state.selectedNumeral.name
                  && numeralVariant === state.selectedNumeralVariant)
                    ? 'btn-primary'
                    : 'btn-ghost')
}
              onClick={() => {
                dispatch(variantNumeralClicked(numeralVariant));
                close();
                onChange(state.selectedNumeral, numeralVariant);
              }}
            >
              {numeralVariant}
            </button>
          ))}
        </div>
      </Transition>
    </>
  );
}

RomanNumeralPopover.propTypes = {
  numerals: PropTypes.arrayOf(NumeralPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedNumeral: NumeralPropType,
  selectedNumeralVariant: PropTypes.string,
  close: PropTypes.func.isRequired,
};

RomanNumeralPopover.defaultProps = {
  selectedNumeral: null,
  selectedNumeralVariant: null,
};

function RomanNumeralPicker({
  selectedNumeral,
  selectedNumeralVariant,
  className,
  ...props
}) {
  return (
    <Popover className={classNames('relative', className)}>
      <Popover.Button
        type="button"
        className="btn normal-case"
      >
        <span>{selectedNumeral?.name}</span>
        {selectedNumeralVariant !== '5' && <span className="text-xs opacity-60 ml-2">{selectedNumeralVariant}</span>}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-[350]"
        enterFrom="opacity-0 translate-y-1 max-h-0"
        enterTo="opacity-100 translate-y-0 max-h-44"
        entered="max-h-44"
        leave="transition ease-in duration-[350]"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className="z-10 mt-3 flex flex-nowrap bg-base-200 rounded shadow-xl absolute left-0 transition-all overflow-x-hidden w-36"
        >
          {({ close }) => (
            <RomanNumeralPopover
              selectedNumeral={selectedNumeral}
              selectedNumeralVariant={selectedNumeralVariant}
              close={close}
              {...props}
            />
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

RomanNumeralPicker.propTypes = {
  className: PropTypes.string,
  numerals: PropTypes.arrayOf(NumeralPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedNumeral: NumeralPropType,
  selectedNumeralVariant: PropTypes.string,
};

RomanNumeralPicker.defaultProps = {
  className: null,
  selectedNumeral: null,
  selectedNumeralVariant: null,
};

export default RomanNumeralPicker;
