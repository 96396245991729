import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Check } from 'react-feather';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import NavBar from '../components/navBar';
import HeroImage from '../assets/images/singer-kid.jpg';
import Spinner from '../components/spinner';
import { signupUser } from '../api/auth';

export default function Signup() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const signupQuery = useQuery(['signup', email, password], () => signupUser(email, password),
    { enabled: false, retry: false });

  const isEmailExistsError = useMemo(() => signupQuery.isError && signupQuery.error?.code === 'auth/email-already-in-use', [signupQuery]);

  function onSubmit(event) {
    event.preventDefault();
    signupQuery.refetch();
  }

  return (
    <div className="relative z-10 bg-neutral-900 overflow-hidden p-4 w-full h-screen flex flex-col">
      <div
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'top right',
          backgroundSize: 'cover',
          zIndex: -100,
        }}
        className="absolute w-full h-full top-0 left-0"
      />

      <div className="bg-base-300 bg-opacity-70 w-full h-full absolute top-0 left-0" style={{ zIndex: -50 }} />

      <NavBar />

      <div className="flex-grow flex items-center justify-center">
        <form className="card flex-shrink-0 shadow-2xl bg-base-100 w-full max-w-3xl" onSubmit={onSubmit}>
          <div className="card-body">

            <div className="text-center text-3xl font-semibold mb-10">
              {signupQuery.isSuccess ? 'Email Sent' : 'Create an Account'}
            </div>

            {/* TODO: [nice to have] add a fade in if you have time */}
            {signupQuery.isSuccess && (
            <div>
              <Check className="m-auto w-20 h-20 mt-10 mb-10" />

              <div className="text-center">
                An email was sent to
                {' '}
                <b>
                  {email}
                </b>
                .
                <br />
                Check your email for a verification.
              </div>

              <a href={window.location.href} className="text-center mt-8 text-sm w-full block">Wrong email? Click here to try again.</a>

            </div>
            )}

            {!signupQuery.isSuccess && (
            <>
              <div className="form-control mb-6">
                <label htmlFor="email">
                  <div className="label-text mb-2">Email</div>
                  <input
                    id="email"
                    type="email"
                    placeholder="you@example.com"
                    className={classNames('input input-bordered w-full',
                      isEmailExistsError && 'input-error')}
                    required
                    disabled={signupQuery.isLoading}
                    value={email || ''}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </label>

                {isEmailExistsError && (
                  <div className="text-error mt-4">Email already exists. Either use a different email or recover your email here</div>
                )}
              </div>

              <div className="form-control mb-6">
                <label htmlFor="password">
                  <div className="label-text mb-2">Password</div>
                  <input id="password" type="password" placeholder="password" className="input input-bordered w-full" required minLength={6} disabled={signupQuery.isLoading} value={password || ''} onChange={(event) => setPassword(event.target.value)} />
                </label>
              </div>
              <div className="form-control mt-6">
                <button type="submit" className="btn btn-primary" disabled={signupQuery.isLoading}>
                  {signupQuery.isLoading
                    ? (
                      <Spinner />
                    )

                    : 'Sign Up'}

                </button>
              </div>

              <Link to="/login" className="link text-sm text-center mt-8">
                Already have an account? Log in here.
              </Link>

              <div className="text-sm text-center mt-8">
                By signing up, you agree to Polytones’ Terms and Conditions of Use.
              </div>
            </>
            )}

          </div>
        </form>
      </div>

    </div>
  );
}
