import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Square as Stop, Play, Clock, Repeat, Minus, Plus,
} from 'react-feather';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import {
  useRhythmPreviewToolbarReducer,
  previewClicked,
  bpmChanged,
  chordChanged,
  metronomeClicked,
  loopClicked,
} from './rhythmPreviewToolbar.store';
import ChordPicker from './chordPicker.guitar';

function RhythmPreviewToolbar({ rhythm }) {
  const [state, dispatch] = useRhythmPreviewToolbarReducer();
  const {
    isPlaying,
    bpm,
    chord,
    isRepeatOn,
    isMetronomeOn,
    minBpm,
    maxBpm,
  } = state;

  return (
    <div className="">
      <div className="flex items-center mb-4 flex-wrap">
        <div>
          <div className="text-xs mb-2">Preview</div>
          <button
            type="button"
            className="btn mr-3"
            onClick={(event) => event.preventDefault()
              || event.stopPropagation()
              || dispatch(previewClicked({
                rhythm,
                bpm,
                chord,
                isRepeatOn,
                isMetronomeOn,
              }))}
          >
            {isPlaying ? <Stop /> : <Play />}
          </button>
        </div>

        <div>
          <div className="text-xs mb-2">Metronome</div>
          <button
            type="button"
            className={
              classNames(
                'btn mr-3',
                isMetronomeOn && 'btn-primary',
              )
            }
            onClick={() => dispatch(metronomeClicked())}
          >
            <Clock />
          </button>
        </div>

        <div>
          <div className="text-xs mb-2">Loop</div>
          <button
            type="button"
            className={
              classNames('btn mr-3',
                isRepeatOn && 'btn-primary')
            }
            onClick={() => dispatch(loopClicked())}
          >
            <Repeat />
          </button>
        </div>

        <div>
          <div className="text-xs mb-2">Tempo</div>
          <Popover className="relative">
            <>
              <Popover.Button
                className="inline-flex flex-col justify-center btn relative w-24"
              >
                <div className="text-2xl mb-3">{bpm}</div>
                <div className="opacity-50 text-xs absolute bottom-0 left-1/2 transform -translate-x-1/2">bpm</div>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform right-0 sm:px-0 lg:max-w-7xl rounded bg-base-200 shadow-2xl">
                  <div>
                    <div className="flex items-center justify-center mb-4">
                      <button type="button" className="btn" aria-label="decrement bpm" onClick={() => dispatch(bpmChanged(bpm - 1))}><Minus /></button>
                      <div className="flex flex-col items-center">
                        <div className="mx-8 text-white text-4xl">{bpm}</div>
                        <div className="text-white text-xs opacity-50">BPM</div>
                      </div>

                      <button type="button" className="btn" aria-label="increment bpm" onClick={() => dispatch(bpmChanged(bpm + 1))}><Plus /></button>
                    </div>
                    <input
                      type="range"
                      className="range range-accent"
                      min={minBpm}
                      max={maxBpm}
                      value={bpm}
                      onChange={(event) => dispatch(bpmChanged(event.target.value))}
                    />
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          </Popover>
        </div>
      </div>

      <div>
        <div className="text-xs mb-2">Chord</div>
        <Popover className="relative">
          {({ close }) => (
            <>
              <Popover.Button
                className="btn text-white"
              >
                <div className="text-2xl">{chord.tonic.title}</div>
                <div className="text-xs opacity-50 ml-2">{chord.variant}</div>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform right-0 sm:px-0 lg:max-w-7xl rounded">
                  <ChordPicker
                    chord={chord}
                    selectClicked={(tonic, variant, chordPosition) => {
                      dispatch(
                        chordChanged(tonic, variant, chordPosition),
                      );
                      close();
                    }}
                  />
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}

RhythmPreviewToolbar.propTypes = {
  rhythm: PropTypes.shape({}),
};

RhythmPreviewToolbar.defaultProps = {
  rhythm: null,
};

export default RhythmPreviewToolbar;
