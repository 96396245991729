import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import Landing from './landing';
import Login from './login';
import Signup from './signup';
import FinishSignup from './signup.finish';
import Arrangements from './arrangements';
import ChordProgressions from './chordProgressions';
import Projects from './projects';
import RhythmCreator from './rhythmCreator';
import Rhythms from './rhythms';
import Studio from './studio';
import AuthenticatedRoute from './authenticatedRoute';
import Player from '../music/player';
import NotFound from './notFound';
import Logout from './logout';
import ForgotPassword from './forgotPassword';
import ResetPassword from './resetPassword';
import About from './about';
import TermsOfUse from './terms';
import PrivacyPolicy from './privacyPolicy';
import Gdpr from '../components/gdpr';
import AuthAction from './authAction';

// stopps the player when a user navigates away from the current route
function PlayerStopper() {
  const history = useHistory();
  useEffect(() => {
    const unsubscribeFromHistory = history.listen(() => {
      Player.stop();
    });

    return () => {
      unsubscribeFromHistory();
    };
  }, [history]);

  return null;
}

function Pages() {
  return (
    <BrowserRouter>
      <PlayerStopper />

      <Switch>

        {/* Non Authenticated */}
        <Route exact path="/" component={Landing} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/sign-up" component={Signup} />
        <Route exact path="/finish-sign-up" component={FinishSignup} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/auth/action" component={AuthAction} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/about" component={About} />
        <Route exact path="/terms-of-use" component={TermsOfUse} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />

        {/* authenticated */}
        <AuthenticatedRoute exact path="/arrangements" component={Arrangements} />
        <AuthenticatedRoute exact path="/chord-progressions" component={ChordProgressions} />
        <AuthenticatedRoute exact path="/projects" component={Projects} />
        <AuthenticatedRoute exact path="/rhythms" component={Rhythms} />
        <AuthenticatedRoute exact path="/rhythms/create" component={RhythmCreator} adminRequired />
        <AuthenticatedRoute exact path="/rhythms/:rhythmId/edit" component={RhythmCreator} adminRequired />
        <AuthenticatedRoute exact path="/studio/:songId" navbar={false} component={Studio} />

        <NotFound />
      </Switch>

      <Gdpr />
    </BrowserRouter>
  );
}

export default Pages;
