import { createContext, useContext, useReducer } from 'react';

/**
 * @typedef {Object} Arrangement
 * @property {String} id - id of the the arrangement
 * @property {String} name - name of the arrangement
 * @property {String[]} sections - name of the sections
 * @property {String[]} tags - array of tags
 * @property {boolean} favorite - true if it's a user's favorite
 */

// CONTEXT
const ArrangementsContext = createContext(null);

export function useArrangementsContext() {
  return useContext(ArrangementsContext);
}

// ACTIONS
export function filterClicked(filter) {
  return {
    type: 'filterClicked',
    payload: filter,
  };
}

export function clearFiltersClicked() {
  return {
    type: 'clearFiltersClicked',
  };
}

export function arrangementRowClicked(arrangement) {
  return {
    type: 'arrangementRowClicked',
    payload: arrangement,
  };
}

export function favoriteClicked(rhythm) {
  return {
    type: 'favoriteClicked',
    payload: rhythm,
  };
}

export function createModalClosed() {
  return {
    type: 'createModalClosed',
  };
}

export function newArrangementClicked() {
  return {
    type: 'newArrangementClicked',
  };
}

export function tagsChanged(tags) {
  return {
    type: 'tagsChanged',
    payload: tags,
  };
}

export function sectionsChanged(sections) {
  return {
    type: 'sectionsChanged',
    payload: sections,
  };
}

export function nameChanged(name) {
  return {
    type: 'nameChanged',
    payload: name,
  };
}

export function arrangementCreated() {
  return {
    type: 'arrangementCreated',
  };
}

export function editArrangementClicked() {
  return {
    type: 'editArrangementClicked',
  };
}

// REDUCER
const initialState = {
  selectedArrangement: null,
  showCreateModal: false,
  tags: [],
  sections: [],
};

function arrangementsReducer(state, action) {
  switch (action.type) {
    case 'filterClicked': {
      return {
        ...state,
        filters: state.filters.map((filter) => {
          if (filter === action.payload) {
            return {
              ...filter,
              active: !filter.active,
            };
          }

          return filter;
        }),
      };
    }

    case 'clearFiltersClicked': {
      return {
        ...state,
        filters: state.filters.map((filter) => ({
          ...filter,
          active: false,
        })),
      };
    }

    case 'arrangementRowClicked': {
      return {
        ...state,
        selectedArrangement: action.payload,
      };
    }

    case 'favoriteClicked': {
      const arrangements = state.arrangements.map((arrangement) => {
        if (action.payload.id !== arrangement.id) {
          return arrangement;
        }

        return {
          ...arrangement,
          favorite: !arrangement.favorite,
        };
      });

      return {
        ...state,
        arrangements,
      };
    }

    case 'newArrangementClicked': {
      return {
        ...state,
        showCreateModal: true,
      };
    }

    case 'createModalClosed': {
      return {
        ...state,
        showCreateModal: false,
        tags: [],
      };
    }

    case 'tagsChanged': {
      return {
        ...state,
        tags: action.payload,
      };
    }

    case 'sectionsChanged': {
      return {
        ...state,
        sections: action.payload,
      };
    }

    case 'nameChanged': {
      return {
        ...state,
        name: action.payload,
      };
    }

    case 'arrangementCreated': {
      return {
        ...initialState,
      };
    }

    case 'editArrangementClicked': {
      return {
        ...state,
        name: state.selectedArrangement.name,
        sections: state.selectedArrangement.sections,
        tags: state.selectedArrangement.tags,
        showCreateModal: true,
      };
    }

    default: {
      return state;
    }
  }
}

export function useArrangementsReducer() {
  return useReducer(arrangementsReducer, initialState);
}
