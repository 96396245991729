import React from 'react';
import { Redirect } from 'react-router-dom';
import { Check } from 'react-feather';
import { useMutation } from 'react-query';
import NavBar from '../components/navBar';
import HeroImage from '../assets/images/singer-kid.jpg';
import Spinner from '../components/spinner';
import { resetPassword } from '../api/auth';

export default function LandingPage() {
  const resetPasswordQuery = useMutation(({ password, confirmPassword, oboCode }) => {
    if (password !== confirmPassword) {
      throw new Error('Your passwords do not match. Please try again');
    }

    return resetPassword(password, oboCode);
  });

  function onSubmit(event) {
    event.preventDefault();

    const queryParams = new URLSearchParams(window.location.search);

    const password = event.target.password.value;
    const confirmPassword = event.target.confirmPassword.value;
    const oboCode = queryParams.get('oobCode');

    resetPasswordQuery.mutate({ password, confirmPassword, oboCode });
  }

  function renderSubmitButtonContents() {
    if (resetPasswordQuery.isLoading) {
      return <Spinner />;
    }

    if (resetPasswordQuery.isSuccess) {
      return <Check />;
    }

    return 'Update';
  }
  return (
    <div className="relative z-10 bg-neutral-900 overflow-hidden p-6 w-full h-screen flex flex-col">

      {resetPasswordQuery.isSuccess && (
        <Redirect to="/login" />
      )}

      <div
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'top right',
          backgroundSize: 'cover',
          zIndex: -100,
        }}
        className="absolute w-full h-full top-0 left-0"
      />

      <div className="bg-base-300 bg-opacity-70 w-full h-full absolute top-0 left-0" style={{ zIndex: -50 }} />

      <NavBar />

      <div className="flex-grow flex items-center justify-center">
        <form className="card flex-shrink-0 shadow-2xl bg-base-100 w-full max-w-lg" onSubmit={onSubmit}>
          <div className="card-body">

            <div className="text-center text-3xl font-semibold mb-4">
              Update Password
            </div>

            <div className="mb-6 text-sm text-center opacity-70">
              Enter a new password.
              <br />
              You will then be redirected to the login page.
            </div>

            <div className="form-control mb-6">
              <label htmlFor="password">
                <div className="label-text mb-2">New Password</div>
                <input
                  id="password"
                  type="password"
                  placeholder="password"
                  name="password"
                  className="input input-bordered w-full"
                  required
                  disabled={resetPasswordQuery.isLoading}
                />
              </label>
            </div>

            <div className="form-control mb-6">
              <label htmlFor="confirmPassword">
                <div className="label-text mb-2">Confirm Password</div>
                <input
                  id="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  className="input input-bordered w-full"
                  required
                  disabled={resetPasswordQuery.isLoading}
                />
              </label>
            </div>

            {resetPasswordQuery.isError && (
              <div className="text-error mb-4">
                {resetPasswordQuery.error?.message}
              </div>
            )}

            <div className="form-control mb-6">
              <button type="submit" className="btn btn-primary" disabled={resetPasswordQuery.isLoading}>
                {renderSubmitButtonContents()}
              </button>
            </div>

          </div>
        </form>
      </div>

    </div>
  );
}
