import React, {
  Fragment, useState,
} from 'react';
import { Plus } from 'react-feather';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import {
  nameChanged, useProjectCreatorReducer, randomSongName, templateSelected,
} from './projectCreator.store';
import Spinner from './spinner';
import { useFTUXContext, ftuxActionPerformed } from './ftux.store';

function ProjectTemplate({
  color, title, onClick, className,
}) {
  return (
    <button
      type="button"
      className={
      classNames('btn h-auto shadow-lg bg-neutral-600 w-52 normal-case inline-flex mr-6 flex-col p-8', className)
      }
      onClick={onClick}
    >
      <div className="grid grid-cols-4 gap-2 relative w-full">
        <div className="col-span-1 h-8 bg-neutral-400 rounded opacity-20" />
        <div className="col-span-1 h-8 bg-neutral-400 rounded opacity-20" />
        <div className="col-span-1 h-8 bg-neutral-400 rounded opacity-20" />
        <div className="col-span-1 h-8 bg-neutral-400 rounded opacity-20" />

        <div className="col-span-1 h-8 bg-neutral-400 rounded opacity-20" />
        <div className="col-span-1 h-8 bg-neutral-400 rounded opacity-20" />
        <div className="col-span-1 h-8 bg-neutral-400 rounded opacity-20" />
        <div className="col-span-1 h-8 bg-neutral-400 rounded opacity-20" />

        <div className={
          classNames(
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-neutral-700 rounded-full p-4',
            color && `border-2 border-${color}-500`,
          )
}
        >
          <Plus />
        </div>
      </div>

      <div className="divider opacity-60 w-full my-0 mt-2" />

      <div className="text-lg">{title}</div>
    </button>
  );
}

function ProjectCreator({ onClose }) {
  const [state, dispatch] = useProjectCreatorReducer();
  const [songNameSuggestion] = useState(randomSongName());
  const [, ftuxDispatch] = useFTUXContext();
  const history = useHistory();

  const { name, templates, selectedTemplate } = state;

  const createProjectMutation = useMutation(() => selectedTemplate.create(
    name || songNameSuggestion,
  ), {
    onSuccess(song) {
      history.push(`/studio/${song.id}`);
    },
  });

  return (
    <div className="min-h-screen px-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-in transition-opacity duration-300 bg-black"
        enterFrom="opacity-0"
        enterTo="opacity-50"
        entered="bg-black opacity-50"
        leave="ease-out transition-opacity duration-300 bg-black"
        leaveFrom="opacity-50"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0" />
      </Transition.Child>

      {/* This element is to trick the browser into centering the modal contents. */}
      <span
        className="inline-block h-screen align-middle"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="inline-block w-full max-w-xl p-10 my-8 text-left align-middle transition-all transform shadow-xl rounded-2xl bg-base-100">
          <Dialog.Title
            as="h2"
            className="text-2xl font-medium leading-6"
          >
            Create Project
          </Dialog.Title>

          <form>
            <div className="my-6">
              <div className="mb-2 text-base-content-dim">Name</div>
              <input
                type="text"
                name="name"
                placeholder={songNameSuggestion}
                className="input input-bordered w-full"
                value={name || ''}
                onChange={(event) => dispatch(nameChanged(event.target.value))}
                disabled={createProjectMutation.isLoading}
              />

              <button type="button" className="text-xs text-base-content-dimmest mt-4 cursor-pointer" onClick={() => dispatch(nameChanged(songNameSuggestion))}>
                Suggestion:
                {' '}
                <span className="underline">{songNameSuggestion}</span>
              </button>
            </div>

            <div className="overflow-x-scroll pb-6 whitespace-nowrap">
              {templates.map((template) => (
                <ProjectTemplate
                  className={
                  selectedTemplate?.id === template.id && 'border-4 border-primary hover:border-primary'
                }
                  key={template.id}
                  color={template.color}
                  title={template.title}
                  onClick={() => dispatch(templateSelected(template))}
                />
              ))}
            </div>

            {createProjectMutation.isError && (
              <div className="my-6 text-error">
                  {createProjectMutation.error.message}
              </div>
            )}

            <div className="flex justify-end mt-4">
              <button type="button" className="btn btn-ghost mr-6" disabled={createProjectMutation.isLoading} onClick={onClose}>Cancel</button>
              <button
                type="button"
                className="btn btn-primary ftux-choose-project-template-button"
                onClick={() => {
                  ftuxDispatch(ftuxActionPerformed());
                  createProjectMutation.mutate();
                }}
                disabled={createProjectMutation.isLoading}
              >
                {createProjectMutation.isLoading ? <Spinner /> : 'Choose'}
              </button>
            </div>
          </form>
        </div>

      </Transition.Child>

    </div>

  );
}

export default ProjectCreator;
