import { createContext, useContext, useReducer } from 'react';

/**
 * @typedef {object} ChordProgression
 * @property {string} id - id of the the progression
 * @property {string} name - name of the arrangement
 * @property {string[]} chords - roman numeral strings
 * @property {string[]} tags - array of tags
 * @property {boolean} favorite - true if it's a user's favorite
 */

// CONTEXT
const ChordProgressionsContext = createContext(null);

export function useChordProgressionsContext() {
  return useContext(ChordProgressionsContext);
}

// ACTIONS
export function filterClicked(filter) {
  return {
    type: 'filterClicked',
    payload: filter,
  };
}

export function clearFiltersClicked() {
  return {
    type: 'clearFiltersClicked',
  };
}

export function chordProgressionRowClicked(chordProgression) {
  return {
    type: 'chordProgressionRowClicked',
    payload: chordProgression,
  };
}

export function favoriteClicked(rhythm) {
  return {
    type: 'favoriteClicked',
    payload: rhythm,
  };
}

export function openCreateDialog() {
  return {
    type: 'openCreateDialog',
  };
}

export function dismissCreateDialog() {
  return {
    type: 'dismissCreateDialog',
  };
}

// REDUCER
const testChordProgressions = [
  {
    name: '12 Bar minor blues',
    id: '1',
    chords: [
      'i7', 'i7', 'i7', 'i7',
      'iV7', 'iV7', 'i7', 'i7',
      'VIb7', 'V7', 'i7', 'i7',
    ],
    tags: [
      'Rock', 'Reggae',
    ],
  },
  {
    name: '12 Bar major blues',
    id: '2',
    chords: [
      'I', 'I', 'I', 'I',
      'IV', 'IV', 'I', 'I',
      'V', 'IV', 'I', 'I',
    ],
    tags: [
      'Rock', 'Pop',
    ],
  },
];

const initialState = {
  filters: [
    { name: 'Favorites', active: false },
    { name: 'Rock', active: false },
    { name: 'Jazz', active: false },
    { name: 'Blues', active: false },
    { name: 'Pop', active: false },
  ],
  chordProgressions: testChordProgressions,
  selectedChordProgression: null,
  isCreateDialogOpen: false,
};

function chordProgressionsReducer(state, action) {
  switch (action.type) {
    case 'filterClicked': {
      return {
        ...state,
        filters: state.filters.map((filter) => {
          if (filter === action.payload) {
            return {
              ...filter,
              active: !filter.active,
            };
          }

          return filter;
        }),
      };
    }

    case 'clearFiltersClicked': {
      return {
        ...state,
        filters: state.filters.map((filter) => ({
          ...filter,
          active: false,
        })),
      };
    }

    case 'chordProgressionRowClicked': {
      return {
        ...state,
        selectedChordProgression: action.payload,
      };
    }

    case 'favoriteClicked': {
      const chordProgressions = state.chordProgressions.map((chordProgression) => {
        if (action.payload.id !== chordProgression.id) {
          return chordProgression;
        }

        return {
          ...chordProgression,
          favorite: !chordProgression.favorite,
        };
      });

      return {
        ...state,
        chordProgressions,
      };
    }

    case 'dismissCreateDialog': {
      return {
        ...state,
        isCreateDialogOpen: false,
      };
    }

    case 'openCreateDialog': {
      return {
        ...state,
        isCreateDialogOpen: true,
      };
    }

    default: {
      return state;
    }
  }
}

export function useChordProgressionsReducer() {
  return useReducer(chordProgressionsReducer, initialState);
}
