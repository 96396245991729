import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../api/user';

export default function Footer({ className }) {
  const { user, authState } = useUserContext();
  const showSignUpAndLogin = authState === 'complete' && !user;

  return (
    <footer className={classNames('p-10 footer bg-base-200 text-base-content footer-center', className)}>
      <div className="grid grid-flow-col gap-4">
        <Link as="a" to="/about" className="link link-hover">About</Link>
        <Link as="a" to="/about" className="link link-hover">Terms of Service</Link>
        <Link as="a" to="/about" className="link link-hover">Privacy Policy</Link>

        {showSignUpAndLogin && (
          <>
            <Link as="a" to="/sign-up" className="link link-hover">Sign Up</Link>
            <Link as="a" to="/login" className="link link-hover">Login</Link>
          </>
        )}
      </div>
    </footer>
  );
}
