/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Plus, X } from 'react-feather';
import { useMutation } from 'react-query';
import {
  useCreateChordProgressionReducer,
  tagsChanged,
  scaleClicked,
  addChordClicked,
  chordChanged,
  chordCreated,
  nameChanged,
  deleteChordClicked,
} from './createChordProgression.store';
import TagsInput from './tagsInput';
import RomanNumeralPicker from './romanNumeralPicker';
import ChordProgressionsCollection from '../api/chordProgressions';
import Spinner from './spinner';

function CreateChordProgression({ chord: chordToEdit, onClose }) {
  const [state, dispatch] = useCreateChordProgressionReducer(chordToEdit);
  const {
    tags, scale, numerals, chords, name,
  } = state;

  // eslint-disable-next-line max-len
  const createChordProgressionMutation = useMutation([chordToEdit], (chordProgression) => {
    if (chordToEdit) {
      return ChordProgressionsCollection.update(chordToEdit.id, chordProgression);
    }

    return ChordProgressionsCollection.create(chordProgression);
  }, {
    onSuccess() {
      dispatch(chordCreated());
    },
  });

  return (

    <div className="min-h-screen px-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-in transition-opacity duration-300 bg-black"
        enterFrom="opacity-0"
        enterTo="opacity-50"
        entered="bg-black opacity-50"
        leave="ease-out transition-opacity duration-300 bg-black"
        leaveFrom="opacity-50"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0" />
      </Transition.Child>

      {/* This element is to trick the browser into centering the modal contents. */}
      <span
        className="inline-block h-screen align-middle"
        aria-hidden="true"
      >
        &#8203;
      </span>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform dark:bg-base-100 shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 dark:text-white-900"
          >
            {chordToEdit ? 'Edit ' : 'Create '}
            Chord Progression
          </Dialog.Title>

          <form className="mt-4">
            <div className="mb-6">
              <div className="mb-2">Name</div>
              <input
                type="text"
                name="name"
                placeholder="Name of guitar rhythm"
                className="input input-bordered w-full"
                value={name || ''}
                onChange={(event) => dispatch(nameChanged(event.target.value))}
              />
            </div>

            <div className="form-control mb-6">
              <label htmlFor="tags" className="label mb-2">
                <span className="label-text">Tags</span>
              </label>

              <TagsInput
                id="tags"
                value={tags}
                onChange={(newTags) => dispatch(tagsChanged(newTags))}
              />
            </div>

            <div className="btn-group flex justify-center mb-6">
              <button type="button" className={classNames('btn btn-sm', scale === 'major' && 'btn-secondary')} onClick={() => dispatch(scaleClicked('major'))}>major</button>
              <button type="button" className={classNames('btn btn-sm', scale === 'minor' && 'btn-secondary')} onClick={() => dispatch(scaleClicked('minor'))}>minor</button>
            </div>

            <div className="mb-2">Progression</div>
            <div className="mb-6 flex flex-wrap">

              {chords.map((chord, index) => (
                <div key={chord.id} className="relative mr-4 mb-4">
                  <button
                    type="button"
                    className="btn btn-circle btn-xs absolute no-animation top-0 right-0 z-10"
                    style={{
                      transform: 'translate(50%, -50%)',
                    }}
                    onClick={() => dispatch(deleteChordClicked(chord, index))}
                  >
                    <X className="w-3 h-3" />
                  </button>

                  <RomanNumeralPicker
                    key={chord.id}
                    className="flex-shrink-0"
                    numerals={numerals}
                    onChange={
                    (numeral, variant) => dispatch(
                      chordChanged(numeral, variant, index),
                    )
                  }
                    selectedNumeral={chord.numeral}
                    selectedNumeralVariant={chord.variant}
                  />

                </div>

              ))}

              <button type="button" className="btn btn-ghost bordered border-dashed border-current" onClick={() => dispatch(addChordClicked())}>
                Add
                <Plus className="ml-4" />
              </button>
            </div>

            {createChordProgressionMutation.isError && (
              <div className="my-4 text-error overflow-ellipsis overflow-auto ">{createChordProgressionMutation.error.message}</div>
            )}

            <div className="flex justify-end">
              <button type="button" className="btn btn-ghost mr-4" onClick={onClose}>Cancel</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => createChordProgressionMutation.mutate({
                  name, tags, chords, scale,
                })}
              >
                {createChordProgressionMutation.isLoading && <Spinner />}
                {!createChordProgressionMutation.isLoading && 'Save'}
              </button>
            </div>

          </form>

        </div>
      </Transition.Child>
    </div>
  );
}

CreateChordProgression.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreateChordProgression;
