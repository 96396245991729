/* eslint-disable no-restricted-syntax */
import {
  Scale, Note, Chord, Key,
} from '@tonaljs/tonal';
import { isNil, memoize } from 'lodash';
import { findFingerings } from 'chord-fingering';

/** @typedef {import('../api/song').FirestoreKeySignature} KeySignature */
/** @typedef {import('./song').DisplayableChord} DisplayableChord */

/**
 * @param {import('./song').DisplayableChord} chord a chord
 * @returns {import('./song').GuitarChordFretting[]} a guitar fretting object
 */
export function findFrettings(chord) {
  return findFingerings(chord.notes).map((fretting) => {
    const frets = [-1, -1, -1, -1, -1, -1];
    fretting.positions.forEach(({ stringIndex, fret }) => {
      frets[stringIndex] = fret;
    });

    // convert to midi notes to avoid issues when trying to use tonejs sampler
    // with double sharps and flats
    const positions = fretting.positions.map((position) => ({
      ...position,
      note: Note.simplify(position.note),
    }));

    return {
      ...fretting,
      positions,
      barres: !isNil(fretting.barre) ? [fretting.barre.fret] : [],
      frets,
    };
  });
}

/**
 * @param {import('./song').DisplayableChord} chord
 * @returns {boolean}
 */
function hasFrettings(chord) {
  return findFrettings(chord).length > 0;
}

/**
 * @param {KeySignature} keySignature the key signature
 * @returns {Array<DisplayableChord[]>} a matrix array of chords for the key signature.
 * Each index is the numeral, each item in the sub array a variant of the chord
 */
export function getChordsForKeySignature(keySignature) {
  const tonic = `${keySignature.tonic}${keySignature.accidental}`;
  const key = keySignature.scale === 'major' ? Key.majorKey(tonic) : Key.minorKey(tonic).natural;

  const qualities = key.chords
    .map((chord) => Chord.get(chord))
    .map((chord) => chord.quality.toLowerCase());

  return qualities.map((quality, index) => {
    const note = key.scale[index];

    const chord = Chord.get(`${note} ${quality}`);
    chord.numeral = index;

    const variantChords = Scale.scaleChords(quality)
      .map((variant) => ({
        ...Chord.get(`${note}${variant}`),
        variant,
        numeral: index,
      }))
      .map((variantChord) => ({
        ...variantChord,
        // eslint-disable-next-line no-use-before-define
        numeralName: getNumeralName(variantChord),
      }))
      .filter((variantChord) => variantChord.notes.every((n) => key.scale.includes(n)))
      .filter((variantChord) => variantChord.symbol !== chord.symbol)
      .filter((variantChord) => hasFrettings(variantChord));

    return [
      chord,
      ...variantChords,
    ];
  });
}

const RomanNumeralByIndex = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII'];

/**
 * @param {DisplayableChord} chord the chord
 */
export function getNumeralName(chord) {
  let numeralName = RomanNumeralByIndex[chord.numeral];

  if (chord.quality?.toLowerCase() === 'major') {
    numeralName = numeralName.toUpperCase();
  } else if (chord.quality?.toLowerCase() === 'minor') {
    numeralName = numeralName.toLowerCase();
  } else if (chord.quality?.toLowerCase() === 'diminished') {
    numeralName = `${numeralName.toLowerCase()}°`;
  }

  return numeralName;
}

/**
 * @param {Scale} scale major or minor scale
 * @returns {Array<RomanNumeral>}
 */
export const getNumerals = memoize((scale) => {
  const allChords = getChordsForKeySignature({ tonic: 'C', accidental: '', scale });

  const romanNumerals = allChords.map((chordList, index) => {
    const numeralName = getNumeralName(chordList[0]);

    return {
      name: numeralName,
      numeral: index,
      variants: chordList.map((chord) => chord.variant),
    };
  });

  return romanNumerals;
});

/**
 *
 * @param {KeySignature} keySignature
 * @param {number} numeral
 * @param {string} variant
 */
export function getChordFromNumeral(keySignature, numeral, variant) {
  const chordsLists = getChordsForKeySignature(keySignature);
  return chordsLists[numeral].find((chord) => chord.variant === variant);
}

/**
 * @param {KeySignature} keySignature the key signature
 * @returns {RomanNumeralChord[]} array of chords for the key signature
 */
export function getNumeralChordsForKey(keySignature) {
  const chordsLists = getChordsForKeySignature(keySignature);
  return chordsLists.map((chordList) => ({
    name: chordList[0].tonic,
    variants: chordList.map((chord) => chord.variant),
  })).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });
}

/**
 * cant use @tombatossals/chords-db because it's missing tons of chords
 * https://www.npmjs.com/package/chord-fingering doesnt have chord finger positions for drawing the UI, but has midi notes
 * https://github.com/szaza/guitar-chords-db-json doesnt have midi notes and chord names need to be mapped to tonal
 */
