import React from 'react';
import { Plus, X } from 'react-feather';
import classNames from 'classnames';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  useSearchableCollectionReducer,
  filterClicked,
  clearFiltersClicked,
  queryChanged,
} from './searchableCollection.store';
import { useUserContext } from '../api/user';
import Spinner from './spinner';
import { useDebounce } from '../hooks/useDebounce';

/**
 *
 * @param {object} props
 * @param {import('../api/collection').default} props.collection
 * @param {string} [props.className]
 * @param {function} props.newButtonClicked
 * @returns
 */
function SearchableCollection({
  collection,
  children,
  className,
  newButtonClicked,
}) {
  const [state, dispatch] = useSearchableCollectionReducer();
  const { isAdmin } = useUserContext();
  const { filters, query } = state;
  const debouncedQuery = useDebounce(query);

  const queryClient = useQueryClient();
  const collectionKey = [collection.collectionName, debouncedQuery, filters];
  const collectionQuery = useQuery(collectionKey, () => {
    const activeFilters = filters.filter((filter) => filter.active).map((filter) => filter.name);

    if (!debouncedQuery?.length && !activeFilters.length) {
      return collection.list();
    }

    return collection.search(debouncedQuery, activeFilters);
  }, { retry: false });

  const favoriteMutation = useMutation((collectionItem) => {
    if (collectionItem.isFavorite) {
      return collection.removeFavorite(collectionItem.id);
    }
    return collection.addFavorite(collectionItem.id);
  }, {
    retry: false,
    onMutate: async (collectionItem) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(collectionKey);

      // Snapshot the previous value
      const previousCollectionItems = queryClient.getQueryData(collectionKey);

      // Optimistically update to the new value
      queryClient.setQueryData(collectionKey, (old) => [...old].map((oldCollectionItem) => {
        if (oldCollectionItem.id !== collectionItem.id) {
          return oldCollectionItem;
        }

        return {
          ...oldCollectionItem,
          isFavorite: !oldCollectionItem.isFavorite,
        };
      }));

      // Return a context object with the snapshotted value
      return { previousCollectionItems };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newData, context) => {
      queryClient.setQueryData(collectionKey, context.previousCollectionItems);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(collectionKey);
    },
  });

  function favoriteClicked(favoriteItem) {
    favoriteMutation.mutate(favoriteItem);
  }

  function renderList() {
    if (collectionQuery.isLoading) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    if (collectionQuery.isError) {
      return (
        <div>
          {collectionQuery.error.message}
        </div>
      );
    }

    if (!collectionQuery.data?.length) {
      return (
        <div>
          <div>No results.</div>
        </div>
      );
    }

    return children(collectionQuery.data, favoriteClicked);
  }

  return (
    <div className={classNames(className)}>
      <div className="form-control mt-4 w-full">
        <div className="flex space-x-2 w-full">
          <input type="text" placeholder="Search" className="input input-bordered flex-grow" value={query || ''} onChange={(event) => dispatch(queryChanged(event.target.value))} />
          {isAdmin() && (
          <button type="button" className="btn btn-primary" onClick={newButtonClicked}>
            New
            <Plus className="ml-3" />
          </button>
          )}
        </div>
      </div>

      <div className="mt-4 flex items-center mb-6">
        <div className="flex-grow">
          {filters.map((filter) => (
            <button
              key={filter.name}
              type="button"
              className={classNames('btn btn-xs mr-2 mb-3',
                filter.active && 'btn-primary',
                !filter.active && 'bg-neutral-600')}
              onClick={() => dispatch(filterClicked(filter))}
              disabled={collectionQuery.isLoading}
            >
              {filter.name}
            </button>
          ))}
        </div>
        <button type="button" className="btn btn-ghost" onClick={() => dispatch(clearFiltersClicked())} disabled={collectionQuery.isLoading}>
          <X className="mr-2" />
          <span className="md:hidden">

            Clear Filters
          </span>
        </button>
      </div>

      {renderList()}
    </div>
  );
}

export default SearchableCollection;
