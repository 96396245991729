import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import NavBar from '../components/navBar';
import HeroImage from '../assets/images/singer-kid.jpg';
import Spinner from '../components/spinner';
import { login } from '../api/auth';

export default function LandingPage() {
  const [userCredentials, setUserCredentials] = useState();
  const loginQuery = useQuery(['login', userCredentials], async () => {
    if (!userCredentials) {
      return Promise.reject();
    }

    return login(userCredentials.email, userCredentials.password);
  }, {
    enabled: !!userCredentials,
    retry: false,
    onSuccess() {
      localStorage?.setItem('returningUser', 'true');
    },
  });

  function onSubmit(event) {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    setUserCredentials({
      email,
      password,
    });
  }

  return (
    <div className="relative z-10 bg-neutral-900 overflow-hidden p-4 w-full h-screen flex flex-col">
      {loginQuery.isSuccess && <Redirect to="/projects" push={false} />}

      <div
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'top right',
          backgroundSize: 'cover',
          zIndex: -100,
        }}
        className="absolute w-full h-full top-0 left-0"
      />

      <div className="bg-base-300 bg-opacity-70 w-full h-full absolute top-0 left-0" style={{ zIndex: -50 }} />

      <NavBar />

      <div className="flex-grow flex items-center justify-center">
        <form className="card flex-shrink-0 shadow-2xl bg-base-100 w-full max-w-3xl" onSubmit={onSubmit}>
          <div className="card-body">

            <div className="text-center text-3xl font-semibold mb-6">
              Log In
            </div>

            <div className="form-control mb-6">
              <label htmlFor="email">
                <div className="label-text mb-2">Email</div>
                <input id="email" type="email" placeholder="you@example.com" className="input input-bordered w-full" required disabled={loginQuery.isLoading} />
              </label>
            </div>
            <div className="form-control mb-6">
              <label htmlFor="password">
                <div className="label-text mb-2">Password</div>
                <input id="password" type="password" placeholder="password" className="input input-bordered w-full" required disabled={loginQuery.isLoading} />
              </label>
            </div>

            <Link to="/forgot-password" className="link text-sm text-center">
              Forgot password?
            </Link>

            {loginQuery.isError && (
              <div className="text-error mt-4">
                {loginQuery.error?.message}
              </div>
            )}

            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary" disabled={loginQuery.isLoading}>
                {loginQuery.isLoading
                  ? (
                    <Spinner />
                  )

                  : 'Log In'}

              </button>
            </div>

            <Link to="/sign-up" className="link text-sm text-center mt-8">
              {'Don\'t have an account? Sign up here.'}
            </Link>

            <div className="text-sm text-center mt-8">
              By signing up, you agree to Polytones&apos; Terms and Conditions of Use.
            </div>

          </div>
        </form>
      </div>

    </div>
  );
}
