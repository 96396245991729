/* eslint-disable no-use-before-define */
import { inRange, isUndefined, omitBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import Collection from './collection';
import { sha256 } from './hash';
import { getNumerals } from '../music/chords';

/** @typedef {import('./song').RomanNumeral} RomanNumeral */
/** @typedef {import('./song').Scale} Scale */
/**
 * @typedef {Object} FirestoreChordProgression
 * @property {string} name - name of chord progression
 * @property {string} id - id of the chord progression
 * @property {string[]} tags - tags of chord progression
 * @property {Scale} scale - the scale for the progression
 * @property {FirestoreRomanNumeralChord[]} chords - the chords to play
 */

/**
 * @typedef {object} FirestoreRomanNumeralChord
 * @property {RomanNumeral} numeral
 * @property {string} variant
 */

/**
 * @typedef {Object} DisplayableChordProgression
 * @property {string} name - name of chord progression
 * @property {string} id - id of the chord progression
 * @property {string[]} tags - tags of chord progression
 * @property {Scale} scale - the scale for the progression
 * @property {DisplayableRomanNumeralChord[]} chords - the chords to play
 */

/**
 * @typedef {object} DisplayableRomanNumeralChord
 * @property {string} id - the id for rendering in the list
 * @property {string} variant
 * @property {DisplayableNumeral} numeral
 */

/**
 * @typedef {object} DisplayableNumeral
 * @property {string} name - the roman numeral letter
 * @property {RomanNumeral} numeral - the numeral number
 * @property {string[]} variants - the variants associated with this chord
 */

/**
 * @param {DisplayableChordProgression} chordProgression
 * @returns {FirestoreChordProgression}
 */
async function toFirestore(chordProgression) {
  if (!chordProgression.name) {
    throw new Error(`A name is required for a chord progression but received ${chordProgression.name}`);
  }

  chordProgression.chords.forEach((chord) => {
    if (!inRange(chord.numeral.numeral, 0, 7)) {
      throw new Error(`a chord progression numeral must be between 0 and 6 but received ${chord.numeral.numeral}`);
    }
  });

  if (chordProgression.tags.length === 0) {
    throw new Error('Tags must not be empty. Make sure to add tags to make the chord progression easily searchable.');
  }

  const firestoreChords = chordProgression.chords.map((chord) => ({
    numeral: chord.numeral.numeral,
    variant: chord.variant,
  })).map((chord) => omitBy(chord, isUndefined));

  return {
    name: chordProgression.name,
    tags: chordProgression.tags,
    scale: chordProgression.scale,
    chords: firestoreChords,
    hash: await createChordProgressionHash(
      chordProgression.scale,
      firestoreChords,
    ),
  };
}

/**
 * @param {DocumentSnapshot<FirestoreChordProgression>} document
 * @returns {DisplayableChordProgression}
 */
function fromFirestore(document) {
  const firestoreChordProgression = document.data();
  const numerals = getNumerals(firestoreChordProgression.scale);

  return {
    ...firestoreChordProgression,
    id: document.id,
    isFavorite: chordProgressions.isFavorite(document.id),
    chords: firestoreChordProgression.chords.map((chord) => ({
      id: uuid(),
      numeral: numerals[chord.numeral],
      variant: chord.variant,
    })),
  };
}

/**
 * @param {Scale} scale
 * @param {FirestoreRomanNumeralChord[]} chords
 * @returns {string}
 */
function createChordProgressionHash(scale, chords) {
  return sha256(scale + chords.map((chord) => `${chord.numeral}${chord.variant}`).join('-'));
}

// singleton
const chordProgressions = new Collection('chordProgressions', { toFirestore, fromFirestore }, createChordProgressionHash);

export default chordProgressions;
