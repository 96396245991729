import { useEffect, useState } from 'react';

export function useThrottle(fn, ms, args) {
  const [lastCallTime, setLastCallTime] = useState(0);

  useEffect(() => {
    const now = Date.now();
    const delta = now - lastCallTime;

    if (delta > ms) {
      setLastCallTime(now);
      fn(...args);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, args);
}
