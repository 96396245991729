import { createContext, useContext, useReducer } from 'react';

// CONTEXT
const SearchableCollectionContext = createContext(null);

export function useSearchableCollectionContext() {
  return useContext(SearchableCollectionContext);
}

// ACTIONS
export function filterClicked(filter) {
  return {
    type: 'filterClicked',
    payload: filter,
  };
}

export function clearFiltersClicked() {
  return {
    type: 'clearFiltersClicked',
  };
}

export function queryChanged(query) {
  return {
    type: 'queryChanged',
    payload: query,
  };
}

// REDUCER

const initialState = {
  filters: [
    { name: 'Favorites', active: false },
    { name: 'Rock', active: false },
    { name: 'Jazz', active: false },
    { name: 'Blues', active: false },
    { name: 'Pop', active: false },
  ],
};

function searchableCollectionReducer(state, action) {
  switch (action.type) {
    case 'filterClicked': {
      return {
        ...state,
        filters: state.filters.map((filter) => {
          if (filter === action.payload) {
            return {
              ...filter,
              active: !filter.active,
            };
          }

          return filter;
        }),
      };
    }

    case 'clearFiltersClicked': {
      return {
        ...state,
        filters: state.filters.map((filter) => ({
          ...filter,
          active: false,
        })),
      };
    }

    case 'queryChanged': {
      return {
        ...state,
        query: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export function useSearchableCollectionReducer() {
  return useReducer(searchableCollectionReducer, initialState);
}
