/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState } from 'react';
import { Plus, MoreHorizontal, Speaker } from 'react-feather';
import { Link } from 'react-router-dom';
import { Transition, Dialog, Menu } from '@headlessui/react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  useProjectsReducer,
  projectClicked,
  renameModalClosed,
  newProjectClicked,
  renameClicked,
  deleteModalClosed,
  deleteProjectClicked,
  deleteProjectConfirmed,
  renameProjectConfirmed,
  newNameChanged,
  dismissCreateProjectDialog,
  queryChanged,
} from './projects.store';
import ProjectCreator from '../components/projectCreator';
import Songs from '../api/song';
import { useDebounce } from '../hooks/useDebounce';
import Spinner from '../components/spinner';
import { useFTUXContext, ftuxActionPerformed } from '../components/ftux.store';
import { useUserContext } from '../api/user';

/**
 * The projects page
 *
 * @returns {React.ReactNode} a react component
 */
function Projects() {
  const [state, dispatch] = useProjectsReducer();
  const [, ftuxDispatch] = useFTUXContext();
  const { user } = useUserContext();
  const {
    showRenameModal,
    showDeleteModal,
    showCreateProjectModal,
    selectedProject,
    newName,
    queryText,
  } = state;

  const [initialLoad, setInitialLoad] = useState(true);
  const queryClient = useQueryClient();
  const debouncedQuery = useDebounce(queryText);
  const songsQueryKey = ['songs', debouncedQuery];
  const songsQuery = useQuery(songsQueryKey, () => {
    if (!debouncedQuery?.length) {
      return Songs.list();
    }
    return Songs.search(debouncedQuery);
  }, {
    onSettled() {
      setInitialLoad(false);
    },
  });

  const deleteSongMutation = useMutation(() => Songs.delete(selectedProject.id), {
    onSuccess() {
      // null out the selected project. todo: create a different action
      dispatch(deleteProjectConfirmed());

      queryClient.invalidateQueries(songsQueryKey);
    },
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(songsQueryKey);

      // Snapshot the previous value
      const previousSong = queryClient.getQueryData(songsQueryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(
        songsQueryKey,
        (old) => [...old].filter((otherSong) => otherSong.id !== selectedProject.id),
      );

      // Return a context object with the snapshotted value
      return { previousSong };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newData, context) => {
      queryClient.setQueryData(songsQueryKey, context.previousSong);
    },

    onSettled: () => {
      queryClient.invalidateQueries(songsQueryKey);
    },
  });

  function renderList() {
    if (songsQuery.isLoading) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    if (songsQuery.isError) {
      return (
        <div>
          {songsQuery.error.message}
        </div>
      );
    }

    if (!songsQuery.data?.length) {
      return (
        <div>
          <div>No results.</div>
        </div>
      );
    }

    return (
      <table className="table w-full table-fixed z-0">
        <thead>
          <tr>
            <th className="w-3/12 bg-neutral-900">Name</th>
          </tr>
        </thead>
        <tbody>
          {songsQuery.data.map((song) => (
            <tr
              key={song.id}
              className="cursor-pointer transition-all duration-300 ease-out bg-neutral-900 bg-opacity-0 hover:bg-opacity-100 hover:text-base-content"
              onClick={() => dispatch(projectClicked(song))}
            >
              <td className="hover:bg-transparent p-0">
                <Link to={`/studio/${song.id}`}>
                  <div className="w-full flex justify-between items-center p-4">
                    <div>{song.name}</div>

                    <Menu as="div" className="relative inline-block">
                      <div>
                        <Menu.Button className="btn btn-circle btn-ghost">
                          <MoreHorizontal />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute  z-20 right-0 w-56 mt-2 origin-top-right bg-neutral-800 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="px-1 py-1 flex flex-col">
                            <Menu.Item>
                              <button type="button" className="btn btn-ghost w-full" onClick={(e) => e.preventDefault() || dispatch(renameClicked(song))}>Rename</button>
                            </Menu.Item>
                            <Menu.Item>
                              <button type="button" className="btn btn-ghost w-full" onClick={(e) => e.preventDefault() || dispatch(deleteProjectClicked(song))}>Delete</button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                  </div>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  if (initialLoad) {
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!debouncedQuery && songsQuery.isSuccess && !songsQuery.data.length) {
    return (
      <div className="flex flex-col items-center mt-10">
        <Speaker width={120} height={120} className="mb-4" />
        <div className="mb-2 font-bold text-3xl ftux-projects-link">No Projects</div>
        <div className="mb-4">Create a new project and save it to your library</div>
        <button
          type="button"
          className="btn btn-primary mb-10 ftux-create-project-button"
          onClick={() => {
            ftuxDispatch(ftuxActionPerformed());
            dispatch(newProjectClicked());
          }}
        >
          Create New Project

        </button>

        <Transition show={showCreateProjectModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => dispatch(dismissCreateProjectDialog())}
          >
            <ProjectCreator
              onClose={() => dispatch(dismissCreateProjectDialog())}
            />
          </Dialog>
        </Transition>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-4">
      <div className="col-span-1 p-4 md:hidden" />
      <div className="col-span-2 p-4 md:col-span-4">
        <div>
          <div className="text-3xl mb-2 text-base-content inline-block ftux-projects-link">Projects</div>
        </div>
        <div>Your Projects</div>

        <div className="form-control my-8 w-full">
          <div className="flex space-x-2 w-full">
            <input
              type="text"
              placeholder="Search Projects"
              className="input input-bordered flex-grow"
              value={queryText || ''}
              onChange={(event) => {
                dispatch(queryChanged(event.target.value));
              }}
            />

            <button
              type="button"
              className="btn btn-primary ftux-create-project-button"
              onClick={() => {
                ftuxDispatch(ftuxActionPerformed());
                dispatch(newProjectClicked());
              }}
            >
              New
              <Plus className="ml-3" />
            </button>
          </div>
        </div>

        {renderList()}

      </div>
      <div className="col-span-1 p-4 md:hidden" />

      <Transition show={showRenameModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => dispatch(renameModalClosed())}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-50"
              entered="opacity-50"
              leave="ease-in duration-200"
              leaveFrom="opacity-50"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform dark:bg-base-100 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6"
                >
                  Rename Song
                </Dialog.Title>

                <form className="mt-4">
                  <div className="mb-6">
                    <div className="mb-2 text-base-content-dim">New Name</div>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={newName || ''}
                      onChange={(e) => dispatch(newNameChanged(e.target.value))}
                      className="input input-bordered w-full"
                    />
                  </div>

                  <div className="flex justify-end">
                    <button type="button" className="btn btn-ghost mr-4" onClick={() => dispatch(renameModalClosed())}>Cancel</button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => dispatch(renameProjectConfirmed())}
                    >
                      Save
                    </button>
                  </div>

                </form>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Transition show={showDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => dispatch(deleteModalClosed())}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-50"
              entered="opacity-50"
              leave="ease-in duration-200"
              leaveFrom="opacity-50"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform dark:bg-base-100 shadow-xl rounded-2xl">
                <Dialog.Title
                  className="text-xl font-medium leading-6 mb-6"
                >
                  Delete Song?
                </Dialog.Title>

                <div className="mb-6 text-base-content-dim">Are you sure you want to delete this song? This action cannot be undone.</div>

                <div className="flex justify-end w-full">
                  <button type="button" className="btn btn-ghost mr-6" onClick={() => dispatch(deleteModalClosed())}>Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={() => deleteSongMutation.mutate(selectedProject)}>Delete</button>
                </div>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Transition show={showCreateProjectModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            if (!user.hasDoneFTUX) {
              return;
            }

            dispatch(dismissCreateProjectDialog());
          }}
        >
          <ProjectCreator
            onClose={() => dispatch(dismissCreateProjectDialog())}
          />
        </Dialog>
      </Transition>
    </div>
  );
}

export default Projects;
