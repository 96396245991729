import React, { useCallback, useMemo, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useUserContext } from '../api/user';
import {
  useFTUXContext,
  finished,
  userStateChanged,
  nextStepFound,
} from './ftux.store';

export default function FTUX() {
  const { user, authState } = useUserContext();
  const [ftux, dispatch] = useFTUXContext();
  const [helpers, setHelpers] = useState(null);
  const {
    waitForStep,
    steps,
    disabled,
    stepIndex,
  } = ftux;

  const joyRideCallback = useCallback(({
    action,
    // index,
    status,
    type,
    // step,
    // ...rest
  }) => {
    // console.log('joyride', {
    //   type, action, status, index, target: step.target, step, ...rest,
    // });
    if (type === EVENTS.BEACON) {
      if (status === STATUS.RUNNING) {
        helpers.open();
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)
    || action === ACTIONS.CLOSE
    || action === ACTIONS.STOP) {
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(finished());
    }
  }, [dispatch, helpers]);

  useMemo(() => {
    if (!waitForStep) {
      return () => {};
    }

    let timeout;
    let foundElement;

    function checkForTarget() {
      if (foundElement) {
        return;
      }

      foundElement = document.querySelector(waitForStep.target);

      if (foundElement) {
        clearTimeout(timeout);
        requestAnimationFrame(() => {
          dispatch(nextStepFound());
        });
      } else {
        timeout = setTimeout(checkForTarget, 500);
      }
    }

    checkForTarget();

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, waitForStep]);

  useMemo(() => {
    requestAnimationFrame(() => {
      dispatch(userStateChanged(user, authState));
    });
  }, [authState, dispatch, user]);

  if (disabled) {
    return null;
  }

  return (
    <Joyride
      run={ftux.run}
      steps={steps}
      stepIndex={stepIndex}
      callback={joyRideCallback}
      continuous={false}
      getHelpers={(h) => setHelpers(h)}
      disableCloseOnEsc
      disableOverlayClose
      hideBackButton
      spotlightClicks
      disableScrolling
    />
  );
}
