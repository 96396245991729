import { createContext, useContext, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import { getNumerals } from '../music/chords';

// CONTEXT
const CreateChordProgressionsContext = createContext(null);

export function useCreateChordProgressionsContext() {
  return useContext(CreateChordProgressionsContext);
}

// ACTIONS

export function nameChanged(name) {
  return {
    type: 'nameChanged',
    payload: name,
  };
}

export function tagsChanged(tags) {
  return {
    type: 'tagsChanged',
    payload: tags,
  };
}

export function scaleClicked(scale) {
  return (state) => ({
    ...state,
    scale,
    numerals: getNumerals(scale),
    chords: [],
  });
}

export function addChordClicked() {
  return {
    type: 'addChordClicked',
  };
}

export function chordPicked(chord) {
  return {
    type: 'chordPicked',
    payload: chord,
  };
}

export function chordChanged(numeral, variant, index) {
  return {
    type: 'chordChanged',
    payload: { numeral, variant, index },
  };
}

export function chordCreated() {
  return {
    type: 'chordCreated',
  };
}

export function deleteChordClicked(chord, index) {
  return {
    type: 'deleteChordClicked',
    payload: { chord, index },
  };
}

// REDUCER

const initialState = {
  name: '',
  tags: [],
  scale: 'major',
  chords: [],
  numerals: getNumerals('major'),
};

function createChordProgressionReducer(state, action) {
  if (typeof action === 'function') {
    return action(state);
  }

  switch (action.type) {
    case 'tagsChanged': {
      return {
        ...state,
        tags: action.payload,
      };
    }

    case 'scaleClicked': {
      return {
        ...state,
        scale: action.payload,
      };
    }

    case 'addChordClicked': {
      const numeral = state.numerals[0];
      const variant = numeral.variants[0];
      const chord = {
        numeral,
        variant,
        id: uuid(),
      };

      return {
        ...state,
        chords: [
          ...state.chords,
          chord,
        ],
      };
    }

    case 'chordChanged': {
      const { numeral, variant, index } = action.payload;
      const chords = [...state.chords];
      chords[index] = { numeral, variant, id: chords[index].id };

      return {
        ...state,
        chords,
      };
    }

    case 'chordCreated': {
      return { ...initialState };
    }

    case 'nameChanged': {
      return {
        ...state,
        name: action.payload,
      };
    }

    case 'deleteChordClicked': {
      return {
        ...state,
        chords: state.chords.filter((chord, index) => index !== action.payload.index),
      };
    }

    default:
      return state;
  }
}

export function useCreateChordProgressionReducer(chord) {
  const finalState = { ...initialState };

  if (chord) {
    Object.assign(finalState, chord);
  }

  return useReducer(createChordProgressionReducer, finalState);
}
