import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import { User, LogOut, Trash2 } from 'react-feather';
import { Popover, Transition } from '@headlessui/react';
import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { useUserContext } from '../api/user';
import DeleteAccount from './deleteAccount';

/**
 * @param {boolean} isActive
 * @returns {string}
 */
const NavLinkClassName = (isActive) => classNames('btn btn-sm opacity-90 ml-5',
  isActive && 'btn-primary',
  !isActive && 'btn-ghost');

/**
 *
 * @param {object} props
 * @param {string} [props.className]
 * @returns {React.ReactElement}
 */
function NavBarAuthenticated({ className }) {
  const { user } = useUserContext();
  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);

  return (
    <nav className={classNames('navbar mb-2 shadow-lg bg-neutral-900', className)}>
      <NavLink to="/projects" className="flex z-10 sm:flex-1">
        <Logo className="h-full w-40" />
      </NavLink>

      <div className="flex-1 sm:hidden">
        <NavLink to="/projects" className={(isActive) => `${NavLinkClassName(isActive)}`}>
          Projects
        </NavLink>

        <NavLink to="/chord-progressions" className={NavLinkClassName}>
          Progressions
        </NavLink>

        <NavLink
          to="/rhythms"
          className={NavLinkClassName}
        >
          Rhythms
        </NavLink>

        {/* <NavLink
          to="/arrangements"
          className={NavLinkClassName}
        >
          Arrangements
        </NavLink> */}
      </div>

      <Popover className={classNames('relative flex-none avatar', className)}>
        <Popover.Button className="btn btn-sm btn-ghost rounded-full p-2 w-10 h-10">
          <User />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-100"
          enterTo="opacity-100 translate-y-100"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 transform right-0 top-full menu p-4 shadow-xl bg-neutral-800 rounded-box">

            {user && (
              <div className="opacity-40 p-4 text-sm mb-2">
                <div>
                  {user.email}
                </div>
              </div>
            )}

            <li className="opacity-90 transition-opacity hover:opacity-100">
              <NavLink as="a" to="/projects">
                Projects
              </NavLink>
            </li>

            <li className="opacity-90 transition-opacity hover:opacity-100">
              <NavLink as="a" to="/chord-progressions">
                Progressions
              </NavLink>
            </li>

            <li className="opacity-90 transition-opacity hover:opacity-100">
              <NavLink
                as="a"
                to="/rhythms"
              >
                Rhythms
              </NavLink>
            </li>

            {/* <li className="opacity-90 transition-opacity hover:opacity-100">
              <NavLink
                as="a"
                to="/arrangements"
              >
                Arrangements
              </NavLink>
            </li> */}

            <div className="w-full bg-white opacity-5 my-2" style={{ height: 1 }} />

            <li className="opacity-90 transition-opacity hover:opacity-100">
              <Link as="a" to="#delete-account" className="text-sm" onClick={(e) => e.stopPropagation() || setShowDeleteAccountDialog(true)}>
                <Trash2 className="mr-2" />
                Delete Account
              </Link>
            </li>

            <div className="w-full bg-white opacity-5 my-2" style={{ height: 1 }} />

            {user && (
              <li className="opacity-90 transition-opacity hover:opacity-100">
                <NavLink
                  as="a"
                  to="/logout"
                >
                  <LogOut className="mr-2" />
                  Logout
                </NavLink>
              </li>
            )}

          </Popover.Panel>
        </Transition>
      </Popover>

      <DeleteAccount
        show={showDeleteAccountDialog}
        onClose={() => setShowDeleteAccountDialog(false)}
      />
    </nav>
  );
}

export default NavBarAuthenticated;
