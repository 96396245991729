// @ts-nocheck
/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUserContext } from '../api/user';
import Spinner from '../components/spinner';
import NavBarAuthenticated from '../components/navBar.authenticated';

/**
 * @param {object} props
 * @param {React.Component} props.component a react component
 * @param {boolean} props.adminRequired a react component
 * @param {boolean} [props.navbar=true] true to show navbar, otherwise false. default true
 * @returns {React.ReactElement}
 */
export default function AuthenticatedRoute({
  component: Component,
  componentProps,
  adminRequired,
  navbar = true,
  ...props
}) {
  const { user, authState, isAdmin } = useUserContext();

  if (authState !== 'complete') {
    return (
      <div className="text-center flex justify-center mt-20">
        <Spinner />
      </div>
    );
  }

  // user is not logged in, prompt them to login
  if (!user) {
    return <Redirect to="/" />;
  }

  if (adminRequired && !isAdmin()) {
    return (
      <div className="text-center">
        <NavBarAuthenticated />

        <div className="text-4xl mt-12 mb-4">Unauthorized</div>
        <div>
          You are unauthorized to view this page.
        </div>
      </div>
    );
  }

  return (
    <Route {...props}>
      <div className="flex flex-col">
        {navbar && <NavBarAuthenticated />}
        <Component {...componentProps} />
      </div>
    </Route>
  );
}
