import {
  doc,
  setDoc,
  getFirestore,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  arrayUnion,
  arrayRemove,
  documentId,
  addDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { get, keyBy, uniqBy } from 'lodash';

/**
   * Common functionality to be used by projects, chord progressions, rhythms and arrangements
   */
class FirebaseCollection {
  /**
   * @param {string} collectionName
   * @param {object} converter
   * @param {function} converter.toFirestore
   * @param {function} converter.fromFirestore
   */
  constructor(collectionName, converter) {
    this.collectionName = collectionName;
    this.converter = converter;
    this.favorites = new Set();
  }

  async preloadFavorites() {
    const { currentUser } = getAuth();
    if (currentUser) {
      this.favorites = await this.listFavorites();
    }
  }

  fromFirestore(document) {
    if (Array.isArray(document)) {
      return document.map((d) => this.fromFirestore(d));
    }

    return this.converter.fromFirestore(document);
  }

  /**
   * @param {string} hash
   * @returns {Promise<DocumentSnapshot>}
   */
  async getByHash(hash) {
    const db = getFirestore();
    const ref = collection(db, this.collectionName);
    const q = query(ref, where('hash', '==', hash));
    const snapshots = await getDocs(q);
    return snapshots.docs[0];
  }

  /**
   *
   * @param {string} hash
   * @returns {Promise<boolean>}
   */
  async exists(hash) {
    const snapshot = await this.getByHash(hash);
    return !!snapshot;
  }

  /**
   * @param {object} document
   * @returns {Promise<void>}
   */
  async create(document) {
    const db = getFirestore();
    const ref = collection(db, this.collectionName);
    const firestoreDoc = await this.converter.toFirestore(document);

    if (await this.exists(firestoreDoc.hash)) {
      throw new Error('Duplicates are not allowed. An item with the hashed values already exists. Please change the values above to continue. Note: name and tags are fine, change other values to make this item unique.');
    }

    const foundItem = await this.getByName(document.name);

    if (foundItem) {
      throw new Error(`An item with the name "${document.name}" already exists. Please choose a different name and try again.`);
    }

    if (document.tags.length === 0) {
      throw new Error('Tags must not be empty. Make sure to add tags to make the rhythm easily searchable.');
    }

    await addDoc(ref, firestoreDoc);
  }

  /**
   * @param {string} id
   * @param {string} name
   * @param {string[]} tags
   * @returns {Promise<void>}
   */
  async update(id, document) {
    const db = getFirestore();
    const ref = doc(db, this.collectionName, id);
    const firestoreDoc = await this.converter.toFirestore(document);

    const existingSnapshot = await this.getByHash(firestoreDoc.hash);

    if (existingSnapshot && existingSnapshot.id !== id) {
      throw new Error('Duplicates are not allowed. An item with the hashed values already exists. Please change the values above to continue. Note: name and tags are fine, change other values to make this item unique.');
    }

    if (document.name) {
      const foundItem = await this.getByName(document.name);

      if (foundItem && foundItem.id !== id) {
        throw new Error('An item with that name already exists. Please choose another name and try again.');
      }
    }

    if (document.tags.length === 0) {
      throw new Error('Tags must not be empty. Make sure to add tags to make the rhythm easily searchable.');
    }

    await setDoc(ref, firestoreDoc, {
      merge: true,
    });
  }

  /**
   * @param {string} name
   * @returns {Promise<DocumentSnapshot[]>}
   */
  async getByName(name) {
    if (!name || !name.length) {
      return null;
    }

    const db = getFirestore();
    const ref = collection(db, this.collectionName);
    const q = query(ref, limit(1), where('name', '==', name));

    const snapshots = await getDocs(q);

    if (!snapshots.docs.length) {
      return null;
    }

    return this.fromFirestore(snapshots.docs[0]);
  }

  /**
   * @param {string} name
   * @returns {Promise<DocumentSnapshot[]>}
   */
  async findByName(name) {
    if (!name || !name.length) {
      return [];
    }

    const db = getFirestore();
    const ref = collection(db, this.collectionName);
    const q = query(ref, orderBy('name'), limit(20), where('name', '>=', name), where('name', '<=', `${name}\uf8ff`));

    const snapshots = await getDocs(q);

    return this.fromFirestore(snapshots.docs);
  }

  /**
   * @param {string} id
   * @returns {Promise<DocumentSnapshot>}
   */
  async getById(id) {
    if (!id) {
      return null;
    }

    const db = getFirestore();
    const ref = doc(db, this.collectionName, id);

    const foundDoc = await getDoc(ref);

    if (!foundDoc.exists()) {
      throw new Error('This item does not exist. Make sure you have the right link and try again.');
    }

    return this.fromFirestore(foundDoc);
  }

  /**
   * @returns {Promise<GuitarRhythm[]>}
   */
  async list() {
    const db = getFirestore();
    const q = query(collection(db, this.collectionName), orderBy('name'), limit(20));
    const snapshots = await getDocs(q);

    return this.fromFirestore(snapshots.docs);
  }

  /**
   * @param {string} q
   * @returns {Promise<Object[>}
   */
  async findByTagName(q) {
    if (!q?.length || (q?.length === 1 && q[0].toLowerCase() === 'favorites')) {
      return [];
    }

    const db = getFirestore();
    const ref = collection(db, this.collectionName);

    let condition;

    if (Array.isArray(q)) {
      condition = query(ref, orderBy('name'), limit(20), where('tags', 'array-contains-any', q.map((tag) => tag.toLowerCase())));
    } else {
      condition = query(ref, orderBy('name'), limit(20), where('tags', 'array-contains', q.toLowerCase()));
    }

    const snapshots = await getDocs(condition);

    return this.fromFirestore(snapshots.docs);
  }

  async findFavorites() {
    if (!this.favorites.size) {
      return [];
    }

    const db = getFirestore();
    const ref = collection(db, this.collectionName);

    const snapshots = await getDocs(query(ref, where(documentId(), 'in', Array.from(this.favorites))));

    return this.fromFirestore(snapshots.docs);
  }

  /**
   *
   * @param {string} [q] the query text
   * @param {string[]} [filters] the filters
   * @returns {Promise<GuitarRhythm[]>}
   */
  async search(q = '', filters = []) {
    const itemsLists = await Promise.all([
      this.findByName(q),
      this.findByTagName(q),
      this.findByTagName(filters),
    ]);

    if (filters.map((filter) => filter.toLowerCase()).includes('favorites')) {
      itemsLists.push(await this.findFavorites());
    }

    const rhythms = uniqBy(itemsLists.flat(), 'id');

    return rhythms.sort((rhythmA, rhythmB) => {
      if (rhythmA.name < rhythmB.name) {
        return -1;
      }

      if (rhythmA.name > rhythmB.name) {
        return 1;
      }

      return 0;
    });
  }

  /**
   * @param {string} id
   * @returns {Promise<void>}
   */
  async addFavorite(id) {
    this.favorites.add(id);

    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = doc(db, 'users', userId);

    await setDoc(ref, {
      [`favorites.${this.collectionName}`]: arrayUnion(id),
    }, { merge: true });
  }

  /**
   * @param {string} id
   * @returns {Promise<void>}
   */
  async removeFavorite(id) {
    this.favorites.delete(id);

    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = doc(db, 'users', userId);

    await setDoc(ref, {
      [`favorites.${this.collectionName}`]: arrayRemove(id),
    }, { merge: true });
  }

  /**
   * @param {string} id
   * @returns {boolean}
   */
  isFavorite(id) {
    return this.favorites.has(id);
  }

  /**
   * Returns a list of the current user's favorites for this collection
   *
   * @returns {Set<string>}
   */
  async listFavorites() {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = doc(db, 'users', userId);

    const snapshot = await getDoc(ref);
    const userDoc = snapshot.data();
    const favoritesList = get(userDoc, `favorites.${this.collectionName}`, []);

    return new Set(favoritesList);
  }

  /**
   *
   * @param {string[]} ids
   * @returns {Promise<object<string, object>>} a object hash of the items
   */
  async getAllByIds(ids) {
    const items = await Promise.all(ids.map((id) => this.getById(id)));

    return keyBy(items, 'id');
  }
}

export default FirebaseCollection;
