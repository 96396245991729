/*
 * @param {string} string the string to hash
 * @returns {Promise<string>} a hashed string
 */
export async function sha256(string) {
  const bytes = new TextEncoder().encode(string);
  const hashedBytes = await crypto.subtle.digest('SHA-256', bytes);
  const hashHex = Array.from(new Uint8Array(hashedBytes)).map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return `0x${hashHex}`;
}
