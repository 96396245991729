import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { timeToMeasureDuration } from '../music/duration';

/**
 * @param {object} props
 * @param {object} props.rhythm
 * @param {number} [props.highlight]
 * @param {string} [props.className]
 * @returns {React.ReactElement}
 */
export default function RhythmPattern({
  rhythm,
  highlight,
  className,
  ...props
}) {
  const uiTicks = useMemo(() => {
    const { notes } = rhythm;
    const ticks = [];

    notes.forEach((note, i) => {
      const numTicks = timeToMeasureDuration(note.duration) * 16;

      ticks.push(
        ...Array(numTicks)
          .fill()
          .map((_, j) => {
            const id = `${note.id}-${i}-${j}`;
            let calculatedClassName;

            if (note.rest) {
              // rest
              calculatedClassName = 'bg-transparent border border-black dark:border-white';
            } else if (j === 0) {
              // transient
              calculatedClassName = '';
            } else {
              // sustain
              calculatedClassName = 'opacity-50';
            }

            return {
              id,
              calculatedClassName,
            };
          }),
      );
    });

    return ticks;
  }, [rhythm]);

  return (
    <div className={classNames('rhythm-pattern whitespace-nowrap overflow-clip overflow-x-auto', className)} {...props}>
      {uiTicks.map((tick) => (
        <div
          key={tick.id}
          className={classNames(
            'rounded-sm transition-all duration-300 w-1 h-1 mr-1 inline-block bg-secondary',
            // 'sm:w-1 sm:h-1',
            // 'md:w-2 md:h-2',
            // 'lg:w-3 lg:h-3',
            // 'xl:w-4 xl:h-4',
            tick.calculatedClassName,
          )}
          {...props}
        />
      ))}
    </div>
  );
}

RhythmPattern.NotePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  rest: PropTypes.bool,
  duration: PropTypes.string.isRequired,
});

RhythmPattern.RhythmPropType = PropTypes.shape({
  notes: PropTypes.arrayOf(RhythmPattern.NotePropType).isRequired,
});

RhythmPattern.propTypes = {
  rhythm: RhythmPattern.RhythmPropType.isRequired,
  highlight: PropTypes.number,
  className: PropTypes.string,
};

RhythmPattern.defaultProps = {
  highlight: null,
  className: null,
};
