/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import { Heart, X } from 'react-feather';
import classNames from 'classnames';
import { Transition, Dialog } from '@headlessui/react';
import { useMutation } from 'react-query';
import {
  useArrangementsReducer,
  arrangementRowClicked,
  createModalClosed,
  newArrangementClicked,
  tagsChanged,
  sectionsChanged,
  nameChanged,
  arrangementCreated,
  editArrangementClicked,
} from './arrangements.store';
import TagsInput from '../components/tagsInput';
import ArrangementsCollection from '../api/arrangements';
import SearchableCollection from '../components/searchableCollection';

function Arrangements() {
  const [state, dispatch] = useArrangementsReducer();
  const {
    selectedArrangement,
    showCreateModal,
    tags,
    sections,
    name,
  } = state;

  const createOrUpdateArrangementMutation = useMutation((arrangement) => {
    if (selectedArrangement) {
      return ArrangementsCollection.update(selectedArrangement.id, arrangement);
    }

    return ArrangementsCollection.create(arrangement);
  }, {
    onSuccess() {
      dispatch(arrangementCreated());
    },
  });

  return (
    <div className="grid grid-cols-4 relative">
      <div className="col-span-1 p-4 md:hidden" />
      <div className="col-span-2 p-4 md:col-span-4">
        <div className="text-3xl mb-2">Arrangements</div>
        <div className="text-base-content-dim">Song Arrangements</div>

        <SearchableCollection
          collection={ArrangementsCollection}
          newButtonClicked={() => {
            dispatch(arrangementRowClicked(null));
            dispatch(newArrangementClicked());
          }}
        >
          {(arrangements, favoriteClicked) => (
            <div className="overflow-x-auto">
              <table className="table w-full table-fixed">
                <thead>
                  <tr>
                    <th className="w-3/12">Name</th>
                    <th className="w-6/12">Structure</th>
                    <th aria-label="Favorite" className="w-3/12 text-center">
                      <button type="button" className="btn btn-circle btn-ghost" disabled>
                        <Heart />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {arrangements.map((arrangement) => (
                    <tr
                      key={arrangement.id}
                      className={
                    classNames('hover cursor-pointer',
                      arrangement.id === selectedArrangement?.id && 'active')
                  }
                      onClick={() => dispatch(arrangementRowClicked(arrangement))}
                    >
                      <td className="overflow-ellipsis overflow-auto" title={arrangement.name}>{arrangement.name}</td>
                      <td className="truncate overflow-ellipsis" title={arrangement.sections.join(' - ')}>
                        {arrangement.sections.join(' - ')}
                      </td>
                      <td className="flex justify-center">
                        <button
                          type="button"
                          className="btn btn-circle btn-ghost"
                          onClick={(event) => {
                            event.stopPropagation();
                            favoriteClicked(arrangement);
                          }}
                        >
                          <Heart fill={arrangement.isFavorite ? 'currentColor' : 'none'} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </SearchableCollection>

      </div>
      <div className={classNames('col-span-1 p-6 md:p-0 md:h-full md:absolute md:top-0 md:left-0 md:w-full md:z-50', !selectedArrangement && 'hidden')}>
        {selectedArrangement && (
        <div className="md:bg-neutral-800 rounded p-4">
          <div className="flex justify-between">
            <div className="font-bold mb-4 dark:text-white">{selectedArrangement.name}</div>
            <button type="button" className="btn btn-ghost btn-sm rounded-full" onClick={() => dispatch(arrangementRowClicked(null))}>
              <X />
            </button>
          </div>
          <div className="mb-2 font-bold">Structure</div>
          <div className="mb-4">
            {selectedArrangement.sections.join(' - ')}
          </div>

          <div className="mb-2 font-bold">Tags</div>
          <div className="mb-6 flex">
            {selectedArrangement.tags.map((tag) => (<div className="mr-4" key={tag}>{tag}</div>))}
          </div>

          <button
            type="button"
            className="btn btn-primary w-full"
            onClick={() => {
              dispatch(editArrangementClicked());
            }}
          >
            Edit
          </button>

        </div>
        )}
      </div>

      <Transition show={showCreateModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => dispatch(createModalClosed())}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-50"
              entered="opacity-50"
              leave="ease-in duration-200"
              leaveFrom="opacity-50"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform dark:bg-base-100 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6"
                >
                  Create Arrangement
                </Dialog.Title>

                <form className="mt-4">
                  <div className="mb-6">
                    <div className="mb-2">Name</div>
                    <input type="text" name="name" placeholder="Name of guitar rhythm" className="bg-transparent rounded border px-3 py-2 w-full dark:border-gray-400" value={name || ''} onChange={(event) => dispatch(nameChanged(event.target.value))} />
                  </div>

                  <div className="form-control mb-6">
                    <label htmlFor="tags" className="label mb-2">
                      <span className="label-text">Tags</span>
                    </label>

                    <TagsInput
                      id="tags"
                      value={tags}
                      onChange={(newTags) => dispatch(tagsChanged(newTags))}
                    />
                  </div>

                  <div className="form-control mb-6">
                    <label htmlFor="tags" className="label mb-2">
                      <span className="label-text">Sections</span>
                    </label>

                    <TagsInput
                      id="tags"
                      value={sections}
                      onlyUnique={false}
                      onChange={(newSections) => dispatch(sectionsChanged(newSections))}
                    />
                  </div>

                  {createOrUpdateArrangementMutation.isError && (
                    <div className="text-error my-4 overflow-auto overflow-ellipsis">{createOrUpdateArrangementMutation.error.message}</div>
                  )}

                  <div className="flex justify-end">
                    <button type="button" className="btn btn-ghost mr-4" onClick={() => dispatch(createModalClosed())}>Cancel</button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => createOrUpdateArrangementMutation
                        .mutate({ name, sections, tags })}
                    >
                      Save
                    </button>
                  </div>

                </form>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default Arrangements;
