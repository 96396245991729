/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from 'react';
import {
  Plus, X,
} from 'react-feather';
import { capitalize, pick } from 'lodash';
import TagsInput from 'react-tagsinput';
import AutosizeInput from 'react-input-autosize';
import classNames from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { ReactComponent as DownStroke } from '../assets/icons/down_stroke.svg';
import { ReactComponent as UpStroke } from '../assets/icons/up_stroke.svg';
import {
  addNoteClicked,
  strokeClicked,
  noteTypeClicked,
  deleteClicked,
  Notes,
  useRhythmCreatorReducer,
  tagsChanged,
  successCreatingRhythm,
  nameChanged,
  rhythmLoaded,
} from './rhythmCreator.store';
import { timeToMeasureDuration, timeToDuration } from '../music/duration';
import NoteIcon from '../components/noteIcon';
import RhythmPreviewToolbar from '../components/rhythmPreviewToolbar';
import Rhythms from '../api/rhythms';
import Spinner from '../components/spinner';

/** @typedef {import('../api/rhythms').GuitarRhythmNote} GuitarRhythmNote */

/**
 *
 * @param {Tone.Unit.Subdivision} toneJsValue
 * @returns {string}
 */
function getFraction(toneJsValue) {
  const duration = timeToDuration(toneJsValue);
  return duration?.fraction.join('/') || toneJsValue;
}

export default function RhythmCreator() {
  const { rhythmId } = useParams();
  const [state, dispatch] = useRhythmCreatorReducer();

  const getRhythmQuery = useQuery(['editRhythm', rhythmId], () => Rhythms.getById(rhythmId), {
    retry: false,
    onSuccess(data) {
      dispatch(rhythmLoaded(data));
    },
  });

  const {
    name,
    notes,
    tags,
  } = state;

  const noteSum = useMemo(() => {
    let sum = 0;

    notes.forEach((/** @type {GuitarRhythmNote} */ note) => {
      sum += timeToMeasureDuration(note.duration);
    });

    return sum;
  }, [notes]);

  const addButtonDisabled = useMemo(() => noteSum >= 1, [noteSum]);

  const createOrUpdateRhythmQuery = useMutation(
    [rhythmId],
    (rhythm) => {
      if (rhythmId) {
        return Rhythms.update(rhythmId, pick(rhythm, 'name', 'tags', 'notes'));
      }

      return Rhythms.create(pick(rhythm, 'name', 'tags', 'notes'));
    },
    {
      retry: false,
      onSuccess() {
        dispatch(successCreatingRhythm());
      },
    },
  );

  /**
   * @param {React.FormEvent} event
   */
  function onSubmit(event) {
    event.preventDefault();
    createOrUpdateRhythmQuery.mutate({ name, tags, notes });
  }

  if (rhythmId && getRhythmQuery.isLoading) {
    return (
      <div className="flex justify-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (rhythmId && getRhythmQuery.isError) {
    return (
      <div className="flex flex-col justify-center items-center mt-8">
        <div className="text-3xl font-bold mb-4">Error</div>
        <div className="text-error">{getRhythmQuery.error.message}</div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-4 dark:text-gray-400">
      <div className="col-span-1 p-4" />
      <div className="col-span-2 p-4">

        <div className="text-3xl mb-2">
          {rhythmId ? 'Edit' : 'Create'}
          {' '}
          Rhythm
        </div>
        <div>
          {rhythmId ? 'Edit' : 'Create'}
          {' '}
          a guitar rhythm
        </div>

        <form className="mt-4" onSubmit={onSubmit}>
          <div className="mb-6">
            <div className="mb-2">Name</div>
            <input type="text" name="name" placeholder="Name of guitar rhythm" className="bg-transparent rounded border px-3 py-2 w-full dark:border-gray-400" value={name || ''} onChange={(event) => dispatch(nameChanged(event.target.value))} />
          </div>

          <div className="form-control mb-6">
            <label htmlFor="tags" className="label mb-2">
              <span className="label-text">Tags</span>
            </label>

            <TagsInput
              id="tags"
              className="border rounded px-4 py-2 border-gray-300"
              value={tags}
              onlyUnique
              focusedClassName="focus:ring focus:border-blue-300"
              onChange={(/** @type {string[]} */ newTags) => dispatch(tagsChanged(newTags))}
              renderTag={(props) => {
                const {
                  // eslint-disable-next-line react/prop-types
                  tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other
                } = props;
                return (
                  <button {...other} type="button" key={key} className="btn btn-sm bg-green-400 mr-2">
                    {getTagDisplayValue(tag)}
                    {!disabled
                      && <X className="inline-block" onClick={() => onRemove(key)} />}
                  </button>
                );
              }}
              onRender={({ addTag, ...props }) => {
                // eslint-disable-next-line react/prop-types
                const { onChange, value, ...other } = props;
                return (
                  <AutosizeInput className="border border-gray-300" type="text" onChange={onChange} value={value} {...other} />
                );
              }}
            />
          </div>

          <div className="mb-4">
            <div className="mb-2">
              Notes
              <i>{`(${noteSum})`}</i>
            </div>

            <div className="flex w-full flex-wrap">
              {notes.map((note, index) => (
                <div key={note.id} className="dark:bg-gray-700 mb-3 mr-3 px-4 py-5 rounded flex flex-col items-center relative w-20">
                  <button
                    type="button"
                    className="btn btn-circle btn-xs absolute no-animation top-0 right-0"
                    style={{
                      transform: 'translate(50%, -50%)',
                    }}
                    onClick={() => dispatch(deleteClicked(note))}

                  >
                    <X className="w-3 h-3" />
                  </button>

                  {/* TODO: [nice to have] change to dropdown */}
                  <div className="dropdown mb-8">
                    <div className="tooltip" data-tip={capitalize(note.stroke)}>
                      <button type="button" tabIndex="0">
                        {
                        note.stroke === 'down'
                          ? <DownStroke className="w-4 h-4" />
                          : <UpStroke className="w-4 h-4" />
                      }
                      </button>
                    </div>

                    <div tabIndex="0" role="menu" className="py-2 px-4 shadow menu dropdown-content bg-base-100 rounded">
                      <div className="menu-title">Stroke</div>

                      <div className="tooltip" data-tip="Down">
                        <button
                          type="button"
                          className="bg-transparent border-none"
                          onClick={() => dispatch(strokeClicked({ note, stroke: 'down', index }))}

                        >
                          <DownStroke className="w-4 h-4" />
                        </button>
                      </div>

                      <div className="tooltip" data-tip="Up">
                        <button
                          type="button"
                          className="bg-transparent border-none"
                          onClick={() => dispatch(strokeClicked({ note, stroke: 'up', index }))}
                        >
                          <UpStroke className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown">
                    <div className="tooltip" data-tip={getFraction(note.duration)}>
                      <button type="button" tabIndex="0">
                        <NoteIcon note={note} className="w-5 h-5" />
                      </button>
                    </div>

                    <div tabIndex="0" role="menu" className="py-2 px-4 shadow menu dropdown-content bg-base-100 rounded w-30 h-96 overflow-y-auto">
                      {Notes.map((section) => (
                        <React.Fragment key={section.title}>
                          <div>{section.title}</div>

                          {section.items.map((noteType) => (
                            <div
                              key={noteType.id}
                              className="tooltip"
                              data-tip={
                                  getFraction(noteType.duration)
                                }
                            >
                              <button
                                type="button"
                                className={classNames(
                                  'bg-transparent border-none opacity-50 hover:opacity-100 cursor-pointer',
                                )}
                                onClick={() => dispatch(
                                  noteTypeClicked({ note, noteType, index }),
                                )}
                              >
                                <noteType.icon className="w-5 h-5" />
                              </button>
                            </div>
                          ))}
                        </React.Fragment>
                      ))}

                    </div>
                  </div>
                </div>
              ))}

              <button type="button" onClick={() => dispatch(addNoteClicked())} disabled={addButtonDisabled} className="btn h-36 px-7 bg-transparent border-2 border-dashed">
                <div className="flex flex-col items-center">
                  <div>Add</div>
                  <Plus />
                </div>
              </button>

            </div>
          </div>

          {createOrUpdateRhythmQuery.isError && (
          <div className="text-error my-4">
            {createOrUpdateRhythmQuery.error?.message}
          </div>
          )}
          <button type="submit" className="btn bg-primary" disabled={createOrUpdateRhythmQuery.isLoading}>
            {createOrUpdateRhythmQuery.isLoading ? <Spinner /> : 'Save'}
          </button>
        </form>
      </div>

      {/* PLAYBACK SECTION */}
      <div className="col-span-1 p-4">
        <div className="text-lg">Playback</div>
        <RhythmPreviewToolbar rhythm={{ notes }} />
      </div>
    </div>
  );
}
