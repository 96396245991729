import React from 'react';
import { Redirect } from 'react-router-dom';

export default function AuthAction() {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.get('mode') === 'verifyEmail') {
    return (
      <Redirect to={{
        pathname: '/finish-sign-up',
        search: window.location.search,
      }}
      />
    );
  }

  if (queryParams.get('mode') === 'resetPassword') {
    return (
      <Redirect to={{
        pathname: '/reset-password',
        search: window.location.search,
      }}
      />
    );
  }

  // default firebase ui for unhandled actions
  return <Redirect to="/__/auth/action" />;
}
