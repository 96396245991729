import React, { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { last } from 'lodash';
import {
  Search, Play, Heart, Square,
} from 'react-feather';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ChordProgressions from '../api/chordProgressions';
import Spinner from './spinner';
import { useStudioContext, previewProgressionClicked, newChordProgressionSelected } from '../pages/studio.store';
import { randomGuitarRhythm } from '../music/song';

/**
 *
 * @param {object} props
 * @param {import('../api/chordProgressions').DisplayableRomanNumeralChord} chord
 */
function RomanNumeralChord({ chord }) {
  return (
    <span key={chord.id}>
      <span>{chord.numeral.name}</span>
      {chord.variant !== '5' && <span className="text-xs opacity-60 ml-2">{chord.variant}</span>}
    </span>
  );
}

export default function ChooseChordProgression({ show, onClose, sectionId }) {
  const [song, dispatch] = useStudioContext();
  const [queryText, setQueryText] = useState();

  const { previewChordProgression } = song;

  const queryKey = ['addChordProgression', queryText];
  const queryClient = useQueryClient();
  const chordProgressionQuery = useQuery(queryKey, () => {
    if (!queryText) {
      return ChordProgressions.list();
    }

    return ChordProgressions.search(queryText);
  });

  const favoriteChordProgressionMutation = useMutation(async (chordProgression) => {
    if (chordProgression.isFavorite) {
      return ChordProgressions.removeFavorite(chordProgression.id);
    }
    return ChordProgressions.addFavorite(chordProgression.id);
  },
  {
    retry: false,
    onMutate: async (collectionItem) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousCollectionItems = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (old) => [...old].map((oldCollectionItem) => {
        if (oldCollectionItem.id !== collectionItem.id) {
          return oldCollectionItem;
        }

        return {
          ...oldCollectionItem,
          isFavorite: !oldCollectionItem.isFavorite,
        };
      }));

      // Return a context object with the snapshotted value
      return { previousCollectionItems };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newData, context) => {
      queryClient.setQueryData(queryKey, context.previousCollectionItems);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            entered="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg p-6 my-8 text-left align-middle transition-all transform dark:bg-base-100 shadow-xl rounded-2xl  overflow-x-hidden">
              <Dialog.Title className="text-lg font-medium leading-6 mb-2">Add Chord Progression</Dialog.Title>
              <Dialog.Description className="mb-6 opacity-70">Choose a chord progression</Dialog.Description>

              <div className="form-control relative mb-4">
                <input
                  type="text"
                  placeholder="Search chord progressions"
                  className="input input-bordered w-full pl-10"
                  value={queryText || ''}
                  onChange={(event) => setQueryText(event.target.value)}
                />
                <Search className="label-text ml-2 absolute left-0 top-3 opacity-50" />
              </div>

              {chordProgressionQuery.isLoading && <Spinner className="my-4 mx-auto" />}
              {chordProgressionQuery.isError && <div className="text-error">{chordProgressionQuery.error.messages}</div>}
              {chordProgressionQuery.isSuccess && !chordProgressionQuery.data.length && (<div className="my-4">No Results</div>)}

              {chordProgressionQuery.isSuccess && chordProgressionQuery.data.length > 0 && (
                <div>
                  <table className="table-compact w-full max-h-80 overscroll-y-auto">
                    <thead>
                      <tr className="bg-neutral-900">
                        <th aria-label="play column" className="bg-transparent" />

                        <td className="bg-transparent">Name</td>
                        <td className="bg-transparent">Progression</td>

                        <td className="bg-transparent"><button type="button" className="btn btn-xs btn-ghost rounded-full" aria-label="favorite chord progression" disabled><Heart className="w-4 h-4" /></button></td>
                      </tr>
                    </thead>

                    <tbody>
                      {chordProgressionQuery.data.map((chordProgression) => (
                        <tr
                          key={chordProgression.id}
                          className="cursor-pointer transition-opacity duration-200 ease-out opacity-50 hover:opacity-100 "
                          onClick={async () => {
                            onClose();
                            const rhythm = await randomGuitarRhythm();
                            dispatch(
                              newChordProgressionSelected(chordProgression, sectionId, rhythm),
                            );
                          }}
                        >
                          <th>
                            <button
                              type="button"
                              className="btn btn-xs btn-ghost rounded-full"
                              aria-label="play chord progression"
                              onClick={(event) => {
                                event.stopPropagation();
                                dispatch(previewProgressionClicked(chordProgression));
                              }}
                            >

                              {previewChordProgression?.id === chordProgression.id
                                ? <Square className="w-4 h-4" />
                                : <Play className="w-4 h-4" />}
                            </button>

                          </th>

                          <td>{chordProgression.name}</td>

                          <td>
                            {chordProgression.chords.map((chord) => (
                              <React.Fragment key={chord.id}>
                                <RomanNumeralChord chord={chord} />
                                {chord !== last(chordProgression.chords) && <span> - </span>}
                              </React.Fragment>
                            ))}

                          </td>

                          <td>
                            <button type="button" className="btn btn-xs btn-ghost rounded-full" aria-label="favorite chord progression" onClick={() => favoriteChordProgressionMutation.mutate(chordProgression)}>
                              <Heart className="w-4 h-4" fill={chordProgression.isFavorite ? 'currentColor' : 'none'} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
