import { random, sample as randomArrayElement } from 'lodash';
import { v4 as uuid } from 'uuid';
import Rhythms from '../api/rhythms';
import { getChordsForKeySignature, findFrettings } from './chords';
import { validKeySignatures } from './keySignature';

/**
 * @typedef {object} Song
 * @property {string} id the id
 * @property {string} name the name
 * @property {import('../api/song').FirestoreKeySignature} keySignature the key of the song
 * @property {Section[]} sections the sections of the song
 * @property {boolean} isMetronomeOn the metronome
 * @property {boolean} bpm the tempo of the song
 */

/**
 * @typedef {object} Section
 * @property {string} id the id of the section
 * @property {string} name the name of the section
 * @property {Measure[]} measures the measures
 */

/**
 * @typedef {object} Measure
 * @property {string} id the id of the measure
 * @property {Chord} chord the chord of the
 * @property {Track[]} tracks the tracks to play
 */

/**
 * @typedef {import('@tonaljs/chord').Chord} Chord
 * @property {RomanNumeral} numeral the roman numeral of the scale
 * @property {string} variant the variant
 */

/**
 * @typedef {GuitarTrack} Track
 */

/**
 * @typedef {object} GuitarTrack
 * @property {string} id the id of the track
 * @property {'guitar'} type  the instrument type
 * @property {import('../music/guitar').GuitarRhythm} rhythm the rhythm
 * @property {import('../music/guitar').GuitarChordFretting} fretting the fret position
 */

/**
 * @enum {string}
 */
const SectionType = {
  INTRO: 'Intro',
  VERSE: 'Verse',
  PRE_CHORUS: 'Pre-Chorus',
  CHORUS: 'Chorus',
  BRIDGE: 'Bridge',
  OUTRO: 'Outro',
};

/**
 * @returns {KeySignature} a random key
 */
function randomKey() {
  return randomArrayElement(validKeySignatures);
}

/**
 * @param {KeySignature} keySignature the key of the song
 * @returns {DisplayableChord} a chord
 */
export function randomChord(keySignature) {
  const chords = getChordsForKeySignature(keySignature);
  const specificChordList = randomArrayElement(chords);
  return specificChordList[0];
}

/**
 * @returns {import('../music/guitar').GuitarRhythm}
 */
export async function randomGuitarRhythm() {
  if (!randomGuitarRhythm.cache) {
    randomGuitarRhythm.cache = Rhythms.list();
  }

  const rhythms = await randomGuitarRhythm.cache;

  const rhythm = randomArrayElement(rhythms);

  return {
    ...rhythm,
  };
}

/**
 * @param {KeySignature} key the key of the song
 * @param {string} name the title of the section
 * @returns {DisplayableSection} a random section
 */
export async function createRandomSection(
  key,
  name = randomArrayElement(Object.values(SectionType)),
) {
  const numChordsPerProgression = 4; // a multiple of numMeasures and at least 2
  // max is numChordsPerProgression, and is a multiple of numChordsPerProgression
  const numRhythms = randomArrayElement([1, 2]);
  const numMeasures = 4;// numChordsPerProgression * random(1, 3);

  const guitarRhythms = await Promise.all(Array(numRhythms).fill().map(() => randomGuitarRhythm()));

  const chordProgression = Array(numChordsPerProgression).fill().map(() => randomChord(key));

  const measures = Array(numMeasures)
    .fill()
    .map((__, measureIndex) => {
      const chord = chordProgression[measureIndex % chordProgression.length];
      const frettings = findFrettings(chord);

      /** @type {GuitarTrack} */
      const guitarTrack = {
        id: uuid(),
        type: 'guitar',
        rhythm: guitarRhythms[measureIndex % guitarRhythms.length],
        fretting: frettings[0],
      };

      /** @type {DisplayableMeasure} */
      const measure = {
        id: uuid(),
        chord,
        tracks: [guitarTrack],
      };

      return measure;
    });

  return {
    id: uuid(),
    name,
    measures,
  };
}

/**
 * @returns {DisplayableSong} a new random song
 */
export function createRandomSong() {
  const keySignature = randomKey();
  const song = {
    id: uuid(),
    name: 'My Song',
    createdOn: new Date().toISOString(),
    keySignature,
    timeSignature: '4/4',
    bpm: random(75, 175),
    sections: [],
  };

  return song;
}

// const SongStructures = [
//   [
//     SectionType.INTRO,
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.CHORUS,
//     SectionType.BRIDGE,
//     SectionType.OUTRO,
//   ],
//   [
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.CHORUS,
//     SectionType.OUTRO,
//   ],
//   [
//     SectionType.VERSE,
//     SectionType.PRE_CHORUS,
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.PRE_CHORUS,
//     SectionType.CHORUS,
//   ],
//   [
//     SectionType.INTRO,
//     SectionType.VERSE,
//     SectionType.PRE_CHORUS,
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.PRE_CHORUS,
//     SectionType.CHORUS,
//     SectionType.BRIDGE,
//   ],
//   [
//     SectionType.PRE_CHORUS,
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.PRE_CHORUS,
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.PRE_CHORUS,
//     SectionType.BRIDGE,
//   ],
//   [
//     SectionType.VERSE,
//     SectionType.CHORUS,
//     SectionType.VERSE,
//     SectionType.CHORUS,
//     SectionType.BRIDGE,
//     SectionType.OUTRO,
//   ],
// ];
