import * as Tone from 'tone';

const player = new Tone.Player('/assets/sounds/metronome.mp3').toDestination();

/**
 * @param {boolean} isOn
 * @returns {Tone.Sequence}
 */
export function createMetronomePart(isOn) {
  const metronome = new Tone.Sequence(
    (time) => {
      if (!metronome.mute) {
        player.start(time);
      }
    },
    Array(4)
      .fill()
      .map((_, i) => i),
    '4n',
  );

  metronome.synth = player;
  metronome.loop = true;
  metronome.mute = !isOn;

  return metronome;
}
