import { createContext, useContext, useReducer } from 'react';
import { sample, startCase } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  commerce, company, hacker, address,
} from 'faker/locale/en_US';
import { createRandomSong } from '../music/song';
import { validKeySignatures } from '../music/keySignature';
import Song, { Scale } from '../api/song';

// CONTEXT
const ProjectCreatorContext = createContext(null);

export function useProjectCreatorContext() {
  return useContext(ProjectCreatorContext);
}

export function randomSongName() {
  const adjective = sample([
    commerce.productAdjective,
    company.catchPhraseAdjective,
    company.bsAdjective,
    hacker.adjective,
  ]);

  const noun = sample([
    commerce.productName,
    commerce.productMaterial,
    company.bsNoun,
    address.cityName,
  ]);

  return startCase(`${adjective()} ${noun()}`);
}

export function nameChanged(payload) {
  return {
    type: 'nameChanged',
    payload,
  };
}

export function templateSelected(template) {
  return {
    type: 'templateSelected',
    payload: template,
  };
}

// REDUCER
const templates = [
  {
    id: uuid(),
    title: 'Song in Major',
    color: 'blue',
    async create(name) {
      const song = createRandomSong();
      const majorKeys = validKeySignatures
        .filter((keySignature) => keySignature.scale === Scale.Major);

      song.name = name;
      song.keySignature = sample(majorKeys);

      song.id = await Song.create(song);

      return song;
    },
  },
  {
    id: uuid(),
    title: 'Song in Minor',
    color: 'green',
    async create(name) {
      const song = createRandomSong();
      const minorKeys = validKeySignatures
        .filter((keySignature) => keySignature.scale === Scale.Minor);

      song.name = name;
      song.keySignature = sample(minorKeys);

      song.id = await Song.create(song);

      return song;
    },
  },
  // {
  //   id: uuid(),
  //   title: 'Blues',
  // },
  // {
  //   id: uuid(),
  //   title: 'Rock',
  // },
];

const initialState = {
  name: '',
  selectedTemplate: templates[0],
  templates,
};

function projectCreatorReducer(state = initialState, action) {
  switch (action.type) {
    case 'nameChanged': {
      return {
        ...state,
        name: action.payload,
      };
    }

    case 'templateSelected': {
      return {
        ...state,
        selectedTemplate: action.payload,
      };
    }

    default:
      return state;
  }
}

export function useProjectCreatorReducer() {
  return useReducer(projectCreatorReducer, initialState);
}
