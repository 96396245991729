import { createContext, useContext, useReducer } from 'react';

// CONTEXT
const RhythmsContext = createContext(null);

export function useRhythmsContext() {
  return useContext(RhythmsContext);
}

// ACTIONS

export function rhythmRowClicked(rhythm) {
  return {
    type: 'rhythmRowClicked',
    payload: rhythm,
  };
}

// REDUCER

const initialState = {
  selectedRhythm: null,
};

function rhythmsReducer(state, action) {
  switch (action.type) {
    case 'rhythmRowClicked': {
      return {
        ...state,
        selectedRhythm: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export function useRhythmsReducer() {
  return useReducer(rhythmsReducer, initialState);
}
