import {
  getAuth,
  sendSignInLinkToEmail,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth';

/**
 * @param {string} email the user's email address
 * @param {string} password the user's password
 */
export async function signupUser(email, password) {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${window.location.origin}/finish-sign-up`,
    // This must be true.
    handleCodeInApp: true,
  };

  const auth = getAuth();

  window.localStorage?.setItem('emailForSignIn', email);

  await createUserWithEmailAndPassword(auth, email, password);
  await sendSignInLinkToEmail(auth, email, actionCodeSettings);
}

/**
 * Verify that a user's email address is valid.
 */
export async function verifyUserEmail() {
  const auth = getAuth();
  // eslint-disable-next-line no-alert
  const email = window.localStorage?.getItem('emailForSignIn') || window.prompt('Please provide your email for confirmation');

  // await new Promise((_, r) => setTimeout(r, 2000));

  // The client SDK will parse the code from the link for you.
  await signInWithEmailLink(auth, email, window.location.href);
}

/**
 * @param {string} email
 * @param {string} password
 */
export async function login(email, password) {
  try {
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
      throw new Error('Invalid email and password.');
    }

    throw error;
  }
}

export async function logout() {
  localStorage?.clear();

  const auth = getAuth();

  await auth.signOut();
}

/**
 * @param {string} email
 */
export async function sendPasswordReset(email) {
  try {
    const auth = getAuth();

    await sendPasswordResetEmail(auth, email, {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${window.location.origin}/reset-password`,
      // This must be true.
      handleCodeInApp: true,
    });
  } catch (error) {
    // if the user was not found, just ignore the error since we don't want people to know
    // if the user exits or not
    if (error.code === 'auth/user-not-found') {
      return;
    }

    throw error;
  }
}

/**
 * @param {string} newPassword
 * @param {string} oboCode the code that was sent to the user's email
 */
export async function resetPassword(newPassword, oboCode) {
  try {
    const auth = getAuth();
    await confirmPasswordReset(auth, oboCode, newPassword);
  } catch (error) {
    if (error.code === 'auth/invalid-action-code') {
      throw new Error('Invalid confirmation code. Please try again.');
    }

    throw error;
  }
}
