import { createContext, useContext, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import { ReactComponent as Choke } from '../assets/icons/choke.svg';
import { ReactComponent as Rest1Beat } from '../assets/icons/rest_1_beat.svg';
import { ReactComponent as RestHalfBeat } from '../assets/icons/rest_half_beat.svg';
import { ReactComponent as Rest2Beats } from '../assets/icons/rest_2_beats.svg';
import { ReactComponent as RestQuarterBeat } from '../assets/icons/rest_quarter_beat.svg';
import { ReactComponent as Rest4Beats } from '../assets/icons/rest_4_beats.svg';
import { ReactComponent as RollNSustain1Beat } from '../assets/icons/roll_n_sustain_1_beat.svg';
import { ReactComponent as RollNSustain2Beats } from '../assets/icons/roll_n_sustain_2_beats.svg';
import { ReactComponent as StrumNSustain1Beat } from '../assets/icons/strum_n_sustain_1_beat.svg';
import { ReactComponent as StrumNSustain2Beatv2 } from '../assets/icons/strum_n_sustain_2_beat_v2.svg';
import { ReactComponent as StrumNSustain2Beat } from '../assets/icons/strum_n_sustain_2_beat.svg';
import { ReactComponent as StrumNSustain2Beats } from '../assets/icons/strum_n_sustain_2_beats.svg';
import { ReactComponent as StrumNSustain3Beats } from '../assets/icons/strum_n_sustain_3_beats.svg';
import { ReactComponent as StrumNSustainQuarterBeat } from '../assets/icons/strum_n_sustain_quarter_beat.svg';
import { ReactComponent as StrumNSustainQuarterBeatV2 } from '../assets/icons/strum_n_sustain_quarter_beat_v2.svg';
import { ReactComponent as StrumNSustain4Beats } from '../assets/icons/strum_n_sustain_4_beats.svg';
import { ReactComponent as StrumNSustain1AndHalfBeats } from '../assets/icons/strum_n_sustain_1.5_beats.svg';
import { ReactComponent as StrumNSustainThreeQuarterBeats } from '../assets/icons/strum_n_sustain.75_beats.svg';
import player from '../music/player';

// CONTEXT
const RhythmCreatorContext = createContext(null);

export function useRhythmCreatorContext() {
  return useContext(RhythmCreatorContext);
}
// DATA

export const Notes = [
  {
    title: 'Chokes',
    items: [
      {
        id: uuid(),
        icon: Choke,
        articulation: 'choke',
        duration: '4n',
      },
    ],
  },

  {
    title: 'Rests',
    items: [
      {
        id: uuid(),
        icon: Rest1Beat,
        articulation: 'rest',
        duration: '4n',
      },
      {
        id: uuid(),
        icon: RestHalfBeat,
        articulation: 'rest',
        duration: '8n',
      },
      {
        id: uuid(),
        icon: Rest2Beats,
        articulation: 'rest',
        duration: '2n',
      },
      {
        id: uuid(),
        icon: RestQuarterBeat,
        articulation: 'rest',
        duration: '16n',
      },
      {
        id: uuid(),
        icon: Rest4Beats,
        articulation: 'rest',
        duration: '1m',
      },
    ],
  },

  {
    title: 'Roles',
    items: [
      {
        id: uuid(),
        icon: RollNSustain1Beat,
        articulation: 'roll',
        duration: '4n',
      },
      {
        id: uuid(),
        icon: RollNSustain2Beats,
        articulation: 'roll',
        duration: '2n',
      },
    ],
  },

  {
    title: 'Strums',
    items: [
      {
        id: uuid(),
        icon: StrumNSustain1Beat,
        articulation: 'strum',
        duration: '4n',
      },
      {
        id: uuid(),
        icon: StrumNSustain1AndHalfBeats,
        articulation: 'strum',
        duration: '4n.',
      },
      {
        id: uuid(),
        icon: StrumNSustainThreeQuarterBeats,
        articulation: 'strum',
        duration: '8n.',
      },
      {
        id: uuid(),
        icon: StrumNSustain2Beatv2,
        articulation: 'strum',
        duration: '8n',
      },
      {
        id: uuid(),
        icon: StrumNSustain2Beat,
        articulation: 'strum',
        duration: '8n',
      },
      {
        id: uuid(),
        icon: StrumNSustain2Beats,
        articulation: 'strum',
        duration: '2n',
      },
      {
        id: uuid(),
        icon: StrumNSustain3Beats,
        articulation: 'strum',
        duration: '2n.',
      },
      {
        id: uuid(),
        icon: StrumNSustainQuarterBeat,
        articulation: 'strum',
        duration: '16n',
      },
      {
        id: uuid(),
        icon: StrumNSustainQuarterBeatV2,
        articulation: 'strum',
        duration: '16n',
      },
      {
        id: uuid(),
        icon: StrumNSustain4Beats,
        articulation: 'strum',
        duration: '1m',
      },
    ],
  },
];

export const AllNoteTypes = Notes
  .map((section) => section.items.sort((a, b) => a.duration - b.duration))
  .flat();

// ACTIONS
export function addNoteClicked() {
  return {
    type: 'addNoteClicked',
  };
}
export function strokeClicked(payload) {
  return {
    type: 'strokeClicked',
    payload,
  };
}
export function noteTypeClicked(payload) {
  return {
    type: 'noteTypeClicked',
    payload,
  };
}
export function deleteClicked(payload) {
  return {
    type: 'deleteClicked',
    payload,
  };
}
export function previewClicked(payload) {
  return {
    type: 'previewClicked',
    payload,
  };
}
export function bpmChanged(bpm) {
  return {
    type: 'bpmChanged',
    payload: bpm,
  };
}
/**
 * @param {string[]} tags
 * @returns {object}
 */
export function tagsChanged(tags) {
  return {
    type: 'tagsChanged',
    payload: tags,
  };
}

export function successCreatingRhythm() {
  return {
    type: 'successCreatingRhythm',
  };
}

/**
 * @param {string} name
 * @returns {object}
 */
export function nameChanged(name) {
  return {
    type: 'nameChanged',
    payload: name,
  };
}

export function rhythmLoaded(rhythm) {
  return {
    type: 'rhythmLoaded',
    payload: rhythm,
  };
}

// REDUCER

const initialState = {
  name: '',

  /** @type {string[]} */
  tags: [],

  /** @type {import('../api/rhythms').GuitarRhythmNote[]} */
  notes: [],

  isPlaying: false,
  bpm: 120,
};

function rhythmCreatorReducer(state, action) {
  switch (action.type) {
    case 'addNoteClicked': {
      const newNote = {
        id: uuid(),
        duration: '4n',
        articulation: 'strum',
        stroke: 'down',
      };

      return {
        ...state,
        notes: [...state.notes, newNote],
      };
    }

    case 'strokeClicked': {
      const { index, stroke } = action.payload;

      const newNotes = [...state.notes];

      newNotes[index] = {
        ...newNotes[index],
        stroke,
      };

      return {
        ...state,
        notes: newNotes,
      };
    }

    case 'noteTypeClicked': {
      const { noteType, index } = action.payload;
      const newNotes = [...state.notes];

      newNotes[index] = {
        ...newNotes[index],
        articulation: noteType.articulation,
        duration: noteType.duration,
        noteTypeId: noteType.id,
      };

      return {
        ...state,
        notes: newNotes,
      };
    }

    case 'deleteClicked': {
      const note = action.payload;

      return {
        ...state,
        notes: state.notes.filter((n) => n.id !== note.id),
      };
    }

    case 'previewClicked': {
      const isPlaying = !player.isPlaying();

      if (isPlaying) {
        player.playNotes(state.notes, state.bpm);
      } else {
        player.stop();
      }

      return {
        ...state,
        isPlaying,
      };
    }

    case 'bpmChanged': {
      const bpm = Number(action.payload);

      player.setBpm(bpm);

      return {
        ...state,
        bpm,
      };
    }

    case 'tagsChanged': {
      return {
        ...state,
        tags: action.payload,
      };
    }

    case 'successCreatingRhythm': {
      return {
        ...state,
        name: '',
        tags: [],
        notes: [],
      };
    }

    case 'nameChanged': {
      return {
        ...state,
        name: action.payload,
      };
    }

    case 'rhythmLoaded': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

/**
 *
 * @returns {[React.ReducerStateWithoutAction<object>, React.Dispatch<object>]}
 */
export function useRhythmCreatorReducer() {
  return useReducer(rhythmCreatorReducer, initialState);
}
