import React from 'react';
import PropTypes from 'prop-types';
import { AllNoteTypes } from '../pages/rhythmCreator.store';

function NoteIcon({ note, ...props }) {
  const noteType = AllNoteTypes.find((nType) => (nType.id === note.noteTypeId))
  || AllNoteTypes.find((nType) => (nType.duration === note.duration)
  && (nType.articulation === note.articulation));

  if (!noteType) {
    throw new Error(`no note found ${note.articulation}`);
  }

  return <noteType.icon {...props} />;
}

NoteIcon.propTypes = {
  note: PropTypes.shape({
    duration: PropTypes.string.isRequired,
    articulation: PropTypes.string.isRequired,
    noteTypeId: PropTypes.string,
  }).isRequired,
};

export default NoteIcon;
