import React from 'react';
import { Link } from 'react-router-dom';
import { Check } from 'react-feather';
import { useMutation } from 'react-query';
import NavBar from '../components/navBar';
import HeroImage from '../assets/images/singer-kid.jpg';
import Spinner from '../components/spinner';
import { sendPasswordReset } from '../api/auth';

export default function LandingPage() {
  const forgotPasswordQuery = useMutation((email) => sendPasswordReset(email));

  function onSubmit(event) {
    event.preventDefault();

    if (forgotPasswordQuery.isSuccess) {
      return;
    }

    const email = event.target.email.value;

    forgotPasswordQuery.mutate(email);
  }

  function renderSubmitButtonContents() {
    if (forgotPasswordQuery.isLoading) {
      return <Spinner />;
    }

    if (forgotPasswordQuery.isSuccess) {
      return <Check />;
    }

    return 'Submit';
  }

  return (
    <div className="relative z-10 bg-neutral-900 overflow-hidden p-6 w-full h-screen flex flex-col">
      <div
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'top right',
          backgroundSize: 'cover',
          zIndex: -100,
        }}
        className="absolute w-full h-full top-0 left-0"
      />

      <div className="bg-base-300 bg-opacity-70 w-full h-full absolute top-0 left-0" style={{ zIndex: -50 }} />

      <NavBar />

      <div className="flex-grow flex items-center justify-center">
        <form className="card flex-shrink-0 shadow-2xl bg-base-100 w-full max-w-lg" onSubmit={onSubmit}>
          <div className="card-body">

            <div className="text-center text-3xl font-semibold mb-4">
              Forgot Password?
            </div>

            <div className="mb-6 text-sm text-center opacity-70">
              To reset your password, enter the email address associated with your account.
            </div>

            <div className="form-control mb-6">
              <label htmlFor="email">
                <div className="label-text mb-2">Email</div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="you@example.com"
                  className="input input-bordered w-full"
                  required
                  disabled={forgotPasswordQuery.isLoading || forgotPasswordQuery.isSuccess}
                />
              </label>
            </div>

            {forgotPasswordQuery.isError && (
              <div className="text-error mb-4">
                {forgotPasswordQuery.error?.message}
              </div>
            )}

            {forgotPasswordQuery.isSuccess && (
            <div className="text-center mb-4">
              An email was sent to the provided email.
              <br />
              Check your email for a verification.
            </div>
            )}

            <div className="form-control mb-6">
              <button type="submit" className="btn btn-primary" disabled={forgotPasswordQuery.isLoading}>
                {renderSubmitButtonContents()}
              </button>
            </div>

            <Link to="/login" className="link text-sm text-center">
              Back to Login
            </Link>

          </div>
        </form>
      </div>

    </div>
  );
}
