import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Initialize Firebase
export function initializeFirebase() {
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: 'AIzaSyBBdCDp3uB3myFwLt_0ZWs7ntdYofq5ozA',
    authDomain: 'musical-rhythms.firebaseapp.com',
    projectId: 'musical-rhythms',
    storageBucket: 'musical-rhythms.appspot.com',
    messagingSenderId: '446248602333',
    appId: '1:446248602333:web:9dc3e1cbfdf2b0461c6934',
    measurementId: 'G-20Q8R3N702',
  };

  const app = initializeApp(firebaseConfig);
  getAnalytics(app);

  if (process.env.REACT_APP_FB_EMULATOR === '1') {
    // eslint-disable-next-line no-console
    console.log('using firebase local emulators');
    connectAuthEmulator(getAuth(), 'http://localhost:9099');
    connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
    connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
  }
}
