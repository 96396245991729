import React from 'react';
import { NavLink } from 'react-router-dom';
import { LogOut } from 'react-feather';
import { useStudioContext } from '../pages/studio.store';
import Toolbar from './toolbar';

export default function StudioNavBar({ showToolBar = true }) {
  const [song] = useStudioContext();
  const { name, lastSaved } = song;

  function formatDate(date) {
    const now = new Date();
    let output = 'Last saved: ';

    if (now.toDateString() !== date.toDateString()) {
      output += `${date.toDateString()} `;
    }

    output += lastSaved.toLocaleTimeString();

    return output;
  }

  return (
    <nav className="items-center justify-between w-full mb-8 bg-neutral-900 p-4 shadow-lg fixed top-0 left-0 z-10 grid grid-cols-12 md:flex">
      <div className="flex items-center relative col-span-3">
        <div className="tooltip tooltip-bottom" data-tip="Exit">
          <NavLink to="/projects" className="flex z-10 btn btn-ghost" style={{ transform: 'rotateZ(-180deg)' }}>
            <LogOut />
          </NavLink>
        </div>

        <div className="ml-4 sm:hidden">
          <div>{name}</div>
          {lastSaved && (
            <div className="text-sm opacity-50">{formatDate(lastSaved)}</div>
          )}
        </div>

      </div>

      {showToolBar && <Toolbar className="flex-grow md:justify-start md:ml-8 col-span-6" />}

      <div className="text-white col-span-3">
        {/* <Settings /> */}
      </div>
    </nav>
  );
}
