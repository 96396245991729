/* eslint-disable react/prop-types */
import {
  createContext, useContext, useEffect, useReducer,
} from 'react';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import { Chord } from '@tonaljs/tonal';
import * as Tone from 'tone';
import {
  randomChord,
  createRandomSong,
} from '../music/song';
import Player, { PlayerSession } from '../music/player';
import { GuitarEvent, GuitarPart } from '../music/guitar';
import { findFrettings, getChordsForKeySignature } from '../music/chords';

export const StudioContext = createContext(null);
const initialState = createRandomSong();
initialState.showGuitarChordPicker = false;
initialState.currentFretPosition = null;
initialState.playFrom = localStorage?.getItem('playFrom') || 'play-from-beginning';

/** @typedef {import('../music/song').DisplayableSong} DisplayableSong */
/** @typedef {import('../music/song').DisplayableSection} DisplayableSection */
/** @typedef {import('../music/song').DisplayableMeasure} DisplayableMeasure */
/** @typedef {import('../music/song').KeySignature} KeySignature */

/**
 * @typedef {object} RomanNumeralChord
 * @property {string} name the name of the chord
 * @property {string[]} variants the variants of the chord
 */

/**
 * @param {DisplayableSection[]} sections array of sections
 * @param {KeySignature} fromKey which key to change from
 * @param {KeySignature} toKey which key to change to
 * @returns {DisplayableSection[]} new sections
 */
function changeKey(sections, fromKey, toKey) {
  const fromChords = getChordsForKeySignature(fromKey);
  const toChords = getChordsForKeySignature(toKey);

  return sections.map((section) => ({
    ...section,
    measures: section.measures.map((measure) => {
      let indexOfChord = -1;
      let indexOfVariant = -1;

      fromChords.forEach((chordList, i) => {
        chordList.forEach((chord, j) => {
          if (chord.tonic === measure.chord.tonic && chord.variant === measure.chord.variant) {
            indexOfChord = i;
            indexOfVariant = j;
          }
        });
      });

      const toChordsList = toChords[indexOfChord];
      const newChord = toChordsList[indexOfVariant] || toChordsList[0];

      return {
        ...measure,
        chord: newChord,
      };
    }),
  }));
}

/**
 *
 * @param {DisplayableSong} song the song
 * @param {string} measureId the measure id to find
 * @returns {{sectionIndex: number, measureIndex: number} | undefined} the found measure
 */
function findMeasureIndexById(song, measureId) {
  for (let i = 0; i < song.sections.length; i += 1) {
    const { measures } = song.sections[i];
    for (let j = 0; j < measures.length; j += 1) {
      const measure = measures[j];
      if (measure.id === measureId) {
        return { sectionIndex: i, measureIndex: j };
      }
    }
  }

  return undefined;
}

// ACTIONS
export function sectionTitleChanged(section, text) {
  return {
    type: 'sectionTitleChanged',
    payload: { section, text },
  };
}

export function keyChanged(newKeySignature) {
  return (state) => {
    const newSections = changeKey(state.sections, state.keySignature, newKeySignature);

    if (Player.isPlaying()) {
      Player.stop();
    }

    return {
      ...state,
      sections: newSections,
      keySignature: newKeySignature,
      isPlaying: false,
      playingMeasureId: null,
      session: null,
    };
  };
}

export function keyClicked(tonic) {
  return (state) => {
    if (tonic === state.keySignature.tonic) {
      return state;
    }

    const newKeySignature = {
      ...state.keySignature,
      tonic,
    };

    const newSections = changeKey(state.sections, state.keySignature, newKeySignature);

    if (Player.isPlaying()) {
      Player.stop();
    }

    return {
      ...state,
      sections: newSections,
      keySignature: newKeySignature,
      isPlaying: false,
      playingMeasureId: null,
      session: null,
    };
  };
}

export function modeClicked(scale) {
  return (state) => {
    if (scale === state.keySignature.scale) {
      return state;
    }

    const newKeySignature = {
      ...state.keySignature,
      scale,
    };

    const newSections = changeKey(state.sections, state.keySignature, newKeySignature);

    if (Player.isPlaying()) {
      Player.stop();
    }

    return {
      ...state,
      sections: newSections,
      keySignature: newKeySignature,
      isPlaying: false,
      playingMeasureId: null,
      session: null,
    };
  };
}

export function accidentalClicked(accidental) {
  return (state) => {
    if (accidental === state.keySignature.accidental) {
      // eslint-disable-next-line no-param-reassign
      accidental = '';
    }

    const newKeySignature = {
      ...state.keySignature,
      accidental,
    };

    const newSections = changeKey(state.sections, state.keySignature, newKeySignature);

    if (Player.isPlaying()) {
      Player.stop();
    }

    return {
      ...state,
      sections: newSections,
      keySignature: newKeySignature,
      isPlaying: false,
      playingMeasureId: null,
      session: null,
    };
  };
}

/**
 * @param {DisplayableMeasure} measure the clicked measure
 * @returns {Object} an action
 */
export function measureClicked(measure) {
  return (state) => ({
    ...state,
    selectedMeasure: measure,
  });
}

export function playerEvent(event) {
  return (state) => {
    if (event.measureId === state.playingMeasureId) {
      return state;
    }

    return {
      ...state,
      playingMeasureId: event.measureId,
    };
  };
}

export function rhythmSelected(rhythm, track) {
  return {
    type: 'rhythmSelected',
    payload: { rhythm, track },
  };
}

export function shuffleClicked(rhythm) {
  return {
    type: 'shuffleClicked',
    payload: rhythm,
  };
}

export function deleteClicked(rhythm) {
  return {
    type: 'deleteClicked',
    payload: rhythm,
  };
}

export function deleteMeasureClicked(measure) {
  return (state) => {
    const sections = cloneDeep(state.sections).map((section) => ({
      ...section,
      measures: section.measures.filter((m) => m.id !== measure.id),
    }));

    if (Player.isPlaying('song')) {
      Player.stop();
    }

    let { selectedMeasure } = state;

    if (measure.id === state.selectedMeasure?.id) {
      selectedMeasure = null;
    }

    return {
      ...state,
      sections,
      selectedMeasure,
    };
  };
}

export function muteMeasureClicked(measureToMute) {
  return (state) => {
    const sections = cloneDeep(state.sections).map((section) => ({
      ...section,
      measures: section.measures.map((measure) => {
        if (measure.id !== measureToMute.id) {
          return measure;
        }

        return {
          ...measure,
          isMuted: !measureToMute.isMuted,
        };
      }),
    }));

    return {
      ...state,
      sections,
    };
  };
}

export function addMeasureClicked(section, rhythm) {
  return {
    type: 'addMeasureClicked',
    payload: { section, rhythm },
  };
}

export function deleteSectionClicked(section) {
  return (state) => {
    const newState = { ...state };

    newState.sections = state.sections
      .filter((s) => s.id !== section.id);

    if (Player.isPlaying('song')) {
      Player.stop();
    }

    if (newState.sections.length === 0) {
      newState.selectedMeasure = null;
    }

    return newState;
  };
}

export function addSectionClicked(section) {
  return {
    type: 'addSectionClicked',
    payload: section,
  };
}

export function playClicked() {
  return (state) => {
    let session = null;

    if (state.isPlaying) {
      Player.stop();
    } else {
      session = PlayerSession.createForSong(state);
      Player.play(session);
    }

    return {
      ...state,
      isPlaying: !state.isPlaying,
      playingMeasureId: null,
      session,
    };
  };
}

export function metronomeClicked() {
  return {
    type: 'metronomeClicked',
  };
}

export function repeatClicked() {
  return {
    type: 'repeatClicked',
  };
}

export function guitarChordButtonClicked() {
  return {
    type: 'guitarChordButtonClicked',
  };
}

export function songLoaded(song) {
  return {
    type: 'songLoaded',
    payload: song,
  };
}

/**
 * @param {number} bpm the bpm
 * @returns {object} the action
 */
export function bpmChanged(bpm) {
  return {
    type: 'bpmChanged',
    payload: bpm,
  };
}

/**
 * @param {import('../music/chords').DisplayableChord} chord the selected chord
 * @returns {object} an action
 */
export function chordChanged(chord) {
  return {
    type: 'chordChanged',
    payload: chord,
  };
}

export function guitarPickerModalClosed() {
  return {
    type: 'guitarPickerModalClosed',
  };
}

export function playFromPositionChecked(playFrom) {
  return (state) => {
    localStorage?.setItem('playFrom', playFrom);

    return {
      ...state,
      playFrom,
    };
  };
}

export function fretPositionClicked(fretting) {
  return (state) => {
    const newSections = cloneDeep(state.sections);
    const { sectionIndex, measureIndex } = findMeasureIndexById(state, state.selectedMeasure.id);

    const measure = newSections[sectionIndex].measures[measureIndex];
    const guitarTrack = measure.tracks.find((track) => track.type === 'guitar');
    guitarTrack.fretting = fretting;

    if (!Player.isPlaying('song')) {
      const part = GuitarPart.createFromFretting(fretting, '1n');
      const session = PlayerSession.createForPart(part);

      Player.play(session);
    }

    return {
      ...state,
      sections: newSections,
      selectedMeasure: measure,
    };
  };
}

export function chordPositionSelected() {
  return {
    type: 'chordPositionSelected',
  };
}

export function songSaved() {
  return {
    type: 'songSaved',
    payload: new Date(),
  };
}

function playerStarted(session) {
  return (state) => {
    if (session.key !== 'song') {
      return state;
    }

    return {
      ...state,
      session,
      isPlaying: true,
    };
  };
}

function playerStopped() {
  return (state) => ({
    ...state,
    session: null,
    isPlaying: false,
    playingMeasureId: null,
  });
}

export function testOutputClicked() {
  return (state) => {
    const [fretting] = findFrettings(Chord.get('Am'));
    const guitarPart = GuitarPart.createFromFretting(fretting, '1n');
    const session = PlayerSession.createForPart(guitarPart);

    Player.play(session);

    return state;
  };
}

export function previewProgressionClicked(chordProgression) {
  return (state) => {
    let { previewChordProgression } = state;

    Player.stop();

    if (chordProgression.id === previewChordProgression?.id) {
      return {
        ...state,
        previewChordProgression: null,
      };
    }

    const chordsLists = getChordsForKeySignature(state.keySignature);
    const events = chordProgression.chords
      .map(({ numeral, variant }) => {
        const chordsList = chordsLists[numeral.numeral];

        // when dealing with major vs minor chord progression the variants might be different
        // this way we fall back to the first chord in the list so it will never be null
        return chordsList.find((chord) => chord.variant === variant) || chordsList[0];
      })
      .map((chord) => findFrettings(chord))
      .map((frettings, index) => GuitarEvent.createFromFretting(frettings[0], '1n', Tone.Time('1n').toSeconds() * index));

    const guitarPart = new GuitarPart(events);

    const session = PlayerSession.createForPart(guitarPart);
    session.key = 'chordProgressionPreview';
    session.loop = true;

    Player.play(session);

    previewChordProgression = chordProgression;

    return {
      ...state,
      previewChordProgression,
    };
  };
}

export function newChordProgressionSelected(chordProgression, sectionId, rhythm) {
  return (state) => {
    const sections = cloneDeep(state.sections);
    const section = sections.find((s) => s.id === sectionId);

    const chordsLists = getChordsForKeySignature(state.keySignature);

    chordProgression.chords.forEach(({ numeral, variant }) => {
      const chordsList = chordsLists[numeral.numeral];
      const chord = chordsList.find((c) => c.variant === variant) || chordsList[0];
      const [fretting] = findFrettings(chord);

      const measure = {
        id: uuid(),
        chord,
        tracks: [
          {
            id: uuid(),
            type: 'guitar',
            rhythm,
            fretting,
          },
        ],
      };

      section.measures.push(measure);
    });

    return {
      ...state,
      sections,
    };
  };
}

// REDUCER

function studioReducer(state, action) {
  if (typeof action === 'function') {
    return action(state);
  }

  switch (action.type) {
    case 'sectionTitleChanged': {
      const { section, text } = action.payload;

      const newState = { ...state };

      newState.sections = cloneDeep(state.sections).map((s) => {
        if (s.id !== section.id) {
          return s;
        }

        return {
          ...section,
          name: text,
        };
      });

      return newState;
    }

    case 'chordChanged': {
      const { selectedMeasure } = state;
      const chord = action.payload;

      if (selectedMeasure.chord.tonic === chord.tonic
        && selectedMeasure.chord.variant === chord.variant) {
        return state;
      }

      const { sectionIndex, measureIndex } = findMeasureIndexById(state, selectedMeasure.id);
      const newMeasure = {
        ...state.sections[sectionIndex].measures[measureIndex],
        chord,
      };

      newMeasure.tracks = newMeasure.tracks.map((track) => {
        if (track.type === 'guitar') {
          const [fretting] = findFrettings(chord);
          return {
            ...track,
            fretting,
          };
        }

        return track;
      });

      const sections = [...state.sections];

      sections[sectionIndex].measures[measureIndex] = newMeasure;

      return {
        ...state,
        sections,
        selectedMeasure: newMeasure,
      };
    }

    case 'bpmChanged': {
      Player.setBpm(action.payload);

      return {
        ...state,
        bpm: action.payload,
      };
    }

    case 'metronomeClicked': {
      if (Player.isPlaying()) {
        if (state.isMetronomeOn) {
          Player.session.metronome.mute = true;
        } else {
          Player.session.metronome.mute = false;
        }
      }

      return {
        ...state,
        isMetronomeOn: !state.isMetronomeOn,
      };
    }

    case 'repeatClicked': {
      return {
        ...state,
        isRepeatOn: !state.isRepeatOn,
      };
    }

    case 'rhythmSelected': {
      const { rhythm, track } = action.payload;
      const newState = cloneDeep(state);
      const {
        sectionIndex,
        measureIndex,
      } = findMeasureIndexById(newState, state.selectedMeasure.id);
      const measure = newState.sections[sectionIndex].measures[measureIndex];

      const newTrack = measure.tracks.find((t) => t.id === track.id);

      newTrack.rhythm = rhythm;

      return newState;
    }

    case 'addMeasureClicked': {
      const newState = cloneDeep(state);

      const sectionId = action.payload.section.id;
      const section = newState.sections.find((s) => s.id === sectionId);

      const chord = randomChord(state.keySignature);
      const { rhythm } = action.payload;
      const measure = {
        id: uuid(),
        chord,
        tracks: [
          {
            id: uuid(),
            type: 'guitar',
            rhythm,
            fretting: findFrettings(chord)[0],
          },
        ],
      };

      section.measures.push(measure);

      return newState;
    }

    case 'addSectionClicked': {
      const sections = [...state.sections];
      const newSection = action.payload;
      sections.push(newSection);
      return {
        ...state,
        sections,
      };
    }

    case 'deleteClicked': {
      const newState = cloneDeep(state);
      const { sectionIndex, measureIndex, rhythmIndex } = action.payload;

      // remove the rhythm at this index
      newState.sections[sectionIndex]
        .measures[measureIndex]
        .rhythms
        .splice(rhythmIndex, 1);

      // remove all measures if there are no rhythms
      // and update indecies
      newState.sections[sectionIndex].measures = newState
        .sections[sectionIndex]
        .measures
        .filter((m) => m.rhythms.length > 0)
        .map((m, newMeasureIndex) => ({
          ...m,
          measureIndex: newMeasureIndex,
          rhythms: m.rhythms.map((r, index) => ({
            ...r,
            rhythmIndex: index,
            measureIndex: newMeasureIndex,
            sectionIndex,
          })),
        }));

      return newState;
    }

    case 'guitarChordButtonClicked': {
      const guitarTrack = state.selectedMeasure.tracks.find((track) => track.type === 'guitar');
      return {
        ...state,
        showGuitarChordPicker: true,
        currentFretPosition: guitarTrack.fretting.positionString,
      };
    }

    case 'guitarPickerModalClosed': {
      return {
        ...state,
        showGuitarChordPicker: false,
        currentFretPosition: null,
      };
    }

    case 'songLoaded': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'songSaved': {
      const date = action.payload;
      return {
        ...state,
        lastSaved: date,
      };
    }

    default:
      return state;
  }
}

// CONTEXT
export function useStudioContext() {
  return useContext(StudioContext);
}

export function useStudioReducer() {
  const [state, dispatch] = useReducer(studioReducer, initialState);
  const { session } = state;

  useEffect(() => {
    function onEvent(event) {
      dispatch(playerEvent(event));
    }

    if (session) {
      if (session.key === 'song') {
        session.on('event', onEvent);
      }
    }

    return () => {
      if (session) {
        session.off('event', onEvent);
      }
    };
  }, [session]);

  useEffect(() => {
    function onStart() {
      dispatch(playerStarted(Player.session));
    }

    function onStop() {
      dispatch(playerStopped());
    }

    Player.on('start', onStart);
    Player.on('stop', onStop);

    return () => {
      Player.off('start', onStart);
      Player.off('stop', onStop);
    };
  }, []);

  return [state, dispatch];
}
