/* eslint-disable class-methods-use-this */
import {
  doc,
  getFirestore,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { v4 as uuid } from 'uuid';
import { findFrettings, getChordFromNumeral } from '../music/chords';
import { sha256 } from './hash';
import Rhythms from './rhythms';

const logger = console;

/**
 * @namespace Firestore
 */

/** @typedef {import('../music/song').Song} AppSong  */

/**
 * @typedef {object} FirestoreSong
 * @description A song that is stored in the db
 * @property {string} id the id of the song
 * @property {string} name the song name
 * @property {FirestoreKeySignature} keySignature the key of the song
 * @property {FirestoreSection[]} sections the sections of the song
 * @property {number} bpm the tempo of the song
 * @example
 * {
  id: 'test',
  name: 'new song',
  key: { tonic: 'A', accidental: '', scale: 'minor' },
  sections: [
    {
      name: 'intro',
      measure: [
        {
          chord: {
            numeral: 'I',
            variant: 'sus2',
          },
          instruments: [
            {
              type: 'guitar',
              rhythmId: 'gtr1',
              positionId: 'A#.sus2.1',
            },
          ],
        },
      ],
    },
  ],
}
 */

/**
 * @typedef {object} FirestoreKeySignature
 * @property {Tonic} tonic the tonic of the song
 * @property {Accidental} accidental sharp flat or regular
 * @property {Scale} scale the scale of the song
 */

/**
 * @typedef {object} FirestoreSection
 * @property {string} name the name of the section
 * @property {FirestoreMeasure[]} measures the measures of the song
 */

/**
 * @typedef {object} FirestoreMeasure
 * @property {FirestoreNumeralChord} chord the chord as roman numeral of the key
 * @property {FirestoreTrack} tracks the tracks to play
 */

/**
 * @typedef {object} FirestoreNumeralChord
 * @property {RomanNumeral} numeral the roman numeral of the song's key
 * @property {string} variant the variant of the chord. e.g. sus2, 7, etc.
 */

/**
 * @typedef {object} FirestoreTrack
 * @property {'guitar'} type  the instrument type
 * @property {string} rhythmId  the id of the rhythm
 */

/**
 * @typedef {FirestoreTrack} FirestoreGuitarTrack
 * @property {string} positionId the id of position that chord should be played in
 */

/**
 * @enum {string}
 */
export const Tonic = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
};

/**
 * @enum {string}
 */
export const Accidental = {
  None: '',
  Sharp: '#',
  Flat: 'b',
};

/**
 * @enum {string}
 */
export const Scale = {
  Major: 'major',
  Minor: 'minor',
};

/**
 * @enum {number}
 */
export const RomanNumeral = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
};

function chordToFirestore(chord) {
  const firestoreChord = {
    numeral: chord.numeral,
  };

  if (chord.variant) {
    firestoreChord.variant = chord.variant;
  }

  return firestoreChord;
}

/**
 * @param {AppSong} song
 * @returns {FirestoreSong}
 */
function toFirestore(song) {
  return {
    name: song.name,
    bpm: song.bpm,
    keySignature: song.keySignature,
    sections: song.sections.map((section) => ({
      name: section.name,
      measures: section.measures.map((measure) => ({
        isMuted: Boolean(measure.isMuted).valueOf(),
        chord: chordToFirestore(measure.chord),
        tracks: measure.tracks.map((track) => {
          if (track.type !== 'guitar') {
            throw new Error('track type not supported yet');
          }

          return {
            type: track.type,
            rhythmId: track.rhythm.id,
            positionId: track.fretting.positionString,
          };
        }),
      })),
    })),
  };
}

/**
 * @param {DocumentSnapshot<FirestoreSong>} firestoreSong
 * @returns {AppSong}
 */
async function fromFirestore(firestoreSongSnapshot) {
  const { id } = firestoreSongSnapshot;
  const firestoreSong = firestoreSongSnapshot.data();

  const rhythmIds = new Set();

  firestoreSong.sections.forEach((section) => {
    section.measures.forEach((measure) => {
      measure.tracks.forEach((track) => {
        if (track.type !== 'guitar') {
          throw new Error('track type not supported');
        }

        rhythmIds.add(track.rhythmId);
      });
    });
  });

  const rhythms = await Rhythms.getAllByIds(Array.from(rhythmIds));

  return {
    id,
    name: firestoreSong.name,
    bpm: firestoreSong.bpm,
    keySignature: firestoreSong.keySignature,
    sections: firestoreSong.sections.map((section) => ({
      id: uuid(),
      name: section.name,
      measures: section.measures.map((measure, sectionIndex) => {
        const chord = getChordFromNumeral(
          firestoreSong.keySignature,
          measure.chord.numeral,
          measure.chord.variant,
        );

        return {
          id: uuid(),
          chord,
          isMuted: measure.isMuted,
          tracks: measure.tracks.map((track, measureIndex) => {
            if (track.type !== 'guitar') {
              throw new Error('track type not supported yet');
            }

            const frettingsList = findFrettings(chord);
            const fretting = frettingsList
              .find((f) => f.positionString === track.positionId);

            if (!fretting) {
              logger.warn('no fretting found for measure', {
                sectionIndex,
                measureIndex,
                positionString: track.positionId,
                frettingsList,
                measure,
              });
            }

            return {
              id: uuid(),
              type: track.type,
              fretting,
              rhythm: rhythms[track.rhythmId],
            };
          }),
        };
      }),
    })),
  };
}

class Song {
  fromFirestore(document) {
    if (Array.isArray(document)) {
      return Promise.all(document.map((d) => fromFirestore(d)));
    }

    return fromFirestore(document);
  }

  /**
   *
   * @param {String} id
   * @returns {Promise<import('../music').Song>}
   */
  async getById(id) {
    if (!id) {
      return null;
    }

    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = doc(db, `/songs/${userId}/details/${id}`);

    const foundDoc = await getDoc(ref);

    if (!foundDoc.exists()) {
      throw new Error('This item does not exist. Make sure you have the right link and try again.');
    }

    return this.fromFirestore(foundDoc);
  }

  /**
   * @param {AppSong} song
   * @returns {Promise<string>} the new id
   */
  async create(song) {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = collection(db, `/songs/${userId}/details`);
    const firestoreDoc = toFirestore(song);

    const foundItem = await this.getByName(song.name);

    if (foundItem) {
      throw new Error(`An item with the name "${song.name}" already exists. Please choose a different name and try again.`);
    }

    const newDoc = await addDoc(ref, firestoreDoc);

    // add to names sub collection
    const nameRef = doc(db, `/songs/${userId}/names/${newDoc.id}`);
    await setDoc(nameRef, { name: song.name });

    return newDoc.id;
  }

  /**
   * @param {string} id
   * @param {AppSong} song
   * @returns {Promise<string>} the new id
   */
  async update(id, song) {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = doc(db, `/songs/${userId}/details/${id}`);
    const firestoreDoc = toFirestore(song);

    await this.assertNameIsUnique(id, song.name);

    await setDoc(ref, firestoreDoc, { merge: true });

    // add to names sub collection
    const nameRef = doc(db, `/songs/${userId}/names/${id}`);
    await setDoc(nameRef, { name: song.name });
  }

  async assertNameIsUnique(id, name) {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = collection(db, `/songs/${userId}/details`);
    const q = query(ref, limit(1), where('name', '==', name));

    const snapshots = await getDocs(q);

    if (!snapshots.docs.length) {
      return;
    }

    if (snapshots.docs[0].id === id) {
      return;
    }

    throw new Error(`An item with the name "${name}" already exists. Please choose a different name and try again.`);
  }

  /**
   * @param {string} name
   * @returns {Promise<DocumentSnapshot[]>}
   */
  async getByName(name) {
    if (!name || !name.length) {
      return null;
    }

    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = collection(db, `/songs/${userId}/details`);
    const q = query(ref, limit(1), where('name', '==', name));

    const snapshots = await getDocs(q);

    if (!snapshots.docs.length) {
      return null;
    }

    return this.fromFirestore(snapshots.docs[0]);
  }

  /**
   * @returns {Promise<AppSong[]>}
   */
  async list() {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = collection(db, `/songs/${userId}/details`);
    const q = query(ref, orderBy('name'), limit(10));

    const snapshots = await getDocs(q);

    return snapshots.docs.map((snapshot) => ({
      id: snapshot.id,
      name: snapshot.data().name,
    }));
  }

  /**
   *
   * @param {string} name
   * @returns {Promise<AppSong[]>}
   */
  async search(name) {
    if (!name || !name.length) {
      return [];
    }

    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = collection(db, `/songs/${userId}/names`);
    const q = query(ref, orderBy('name'), limit(20), where('name', '>=', name), where('name', '<=', `${name}\uf8ff`));

    const snapshots = await getDocs(q);

    return snapshots.docs.map((snapshot) => ({
      id: snapshot.id,
      name: snapshot.data().name,
    }));
  }

  /**
   * @param {string} id
   * @returns {Promise<void>}
   */
  async delete(id) {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const ref = doc(db, `/songs/${userId}/details/${id}`);
    const nameRef = doc(db, `/songs/${userId}/names/${id}`);

    await deleteDoc(ref);
    await deleteDoc(nameRef);
  }

  /**
   *
   * @param {AppSong} song
   */
  hash(song) {
    const firestoreSong = toFirestore(song);

    return sha256(`
    ${firestoreSong.name}
    ${firestoreSong.bpm}
    ${firestoreSong.keySignature.tonic}${firestoreSong.keySignature.accidental}${firestoreSong.keySignature.scale}
    ${firestoreSong.sections.map((section) => `
      ${section.name}
      ${section.measures.map((measure) => `
        ${measure.chord.numeral}${measure.chord.variant}
        ${measure.isMuted}
        ${measure.tracks.map((track) => {
    if (track.type !== 'guitar') {
      throw new Error('track type not supported');
    }

    return `${track.type}${track.rhythmId}${track.positionId}`;
  })}

      `)}
    `)}
    `);
  }
}

export default new Song();
