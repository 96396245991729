/* eslint-disable max-len */
import React from 'react';
import Footer from '../components/footer';
import NavBar from '../components/navBar';

export default function About() {
  return (
    <div>
      <NavBar />

      <div className="flex flex-col items-center max-w-lg m-auto mt-10 sm:px-8">
        <h1 className="text-3xl mb-6">About Polytones</h1>

        <p>
          Hi,
          {" I'm"}
          <a href="https://github.com/joeyjiron06">Joey</a>
          . I built
          {' '}
          <b>Polytones</b>
          {' '}
          as a tool help musicians and non-musicians make songs.
          There is no music theory required to make your songs sound good because the music theory is built into the app.
          This app comes loaded with tons of rhythms and chord progressions to choose from with new ones always being added.
          My mission is to make song creation accessible to everything from professional musicians to people who are just
          starting out. Everything is completely FREE and unlimited.
        </p>

        <p className="self-start">Any questions please contact me a joeyjiron06@gmail.com</p>
      </div>

      <Footer />
    </div>
  );
}
