import React from 'react';

export default function Variant({ variant, className = '' }) {
  if (variant === 'M') {
    return null;
  }

  if (variant === 'sus4') {
    return (
      <sub className={className}>
        sus
        <sup>4</sup>
      </sub>
    );
  }

  if (variant === 'M7sus4') {
    return (
      <span className={className}>
        <sup>7</sup>
        <sub>
          sus
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'maj7') {
    return (
      <sup className={className}>△</sup>
    );
  }

  if (variant === '5' || variant === '6' || variant === '4' || variant === '11') {
    return <sup className={className}>{variant}</sup>;
  }

  if (variant === 'sus2') {
    return (
      <sub className={className}>
        sus
        <sup>2</sup>
      </sub>
    );
  }

  if (variant === 'sus24') {
    return (
      <span className={className}>
        <sup>2</sup>
        <sub>
          sus
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'M9sus4') {
    return (
      <span className={className}>
        <sup>△9</sup>
        <sub>
          sus
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'Madd9') {
    return (
      <span className={className}>
        <sup>△9</sup>
      </span>
    );
  }

  if (variant === 'maj9') {
    return (
      <sub className={className}>
        add
        <sup>9</sup>
      </sub>
    );
  }
  if (variant === '6/9') {
    return (
      <span className={className}>
        <sup>6</sup>
        <sub>
          add
          <sup>9</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'maj13') {
    return (
      <sup className={className}>△13</sup>
    );
  }

  if (variant === 'M7add13') {
    return (
      <span className={className}>
        <sup>△</sup>
        <sub>
          add
          <sup>13</sup>
        </sub>
      </span>
    );
  }

  if (variant === '7#5sus4') {
    return (
      <span className={className}>
        m
        <sup className="ml-1">7</sup>
        <sub className="mr-1">aug</sub>
        <sub>
          sus
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  if (variant === '7sus4') {
    return (
      <span className={className}>
        <sup>7</sup>
        <sub>
          sus
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'm#5') {
    return (
      <span className={className}>
        m
        <sub className="ml-1">aug</sub>
      </span>
    );
  }
  if (variant === 'm7#5') {
    return (
      <span className={className}>
        m
        <sup className="ml-1">♯5</sup>
      </span>
    );
  }

  if (variant === 'm7') {
    return (
      <span className={className}>
        m
        <sup className="ml-1">7</sup>
      </span>
    );
  }

  if (variant === 'madd4') {
    return (
      <span className={className}>
        m
        <sub className="ml-1">
          add
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'm7add11') {
    return (
      <span className={className}>
        m
        <sup className="ml-1">
          7
        </sup>
        <sub className="ml-1">
          add
          <sup>11</sup>
        </sub>
      </span>
    );
  }

  if (variant === '9sus4') {
    return (
      <span className={className}>
        <sup>9</sup>
        <sub>
          sus
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'm9#5') {
    return (
      <span className={className}>
        m
        <sup className="ml-1">9 ♯5</sup>
      </span>
    );
  }

  if (variant === 'madd9') {
    return (
      <span className={className}>
        m
        <sub className="ml-1">
          add
          <sup>9</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'm9') {
    return (
      <span className={className}>
        m
        <sup>9</sup>
      </span>
    );
  }

  if (variant === 'm11A') {
    return (
      <span className={className}>
        <sub>aug</sub>
        <sup>11</sup>
      </span>
    );
  }

  if (variant === 'm11') {
    return (
      <span className={className}>
        m
        <sup>11</sup>
      </span>
    );
  }

  if (variant === 'M7#5sus4') {
    return (
      <span className={className}>
        <sup className="ml-1">7</sup>
        <sub className="mr-1">aug</sub>
        <sub>
          sus
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  if (variant === 'mb6M7') {
    return (
      <span className={className}>
        m

        <sup className="ml-1">♭6</sup>
        <sup className="ml-1">7</sup>
      </span>
    );
  }

  if (variant === 'dim') {
    return (
      <span className={className}>
        <sup>°</sup>
      </span>
    );
  }

  if (variant === 'oM7') {
    return (
      <span className={className}>
        <sup>°</sup>
        <sup className="ml-1">M7</sup>
      </span>
    );
  }

  if (variant === 'dim7') {
    return (
      <span className={className}>
        <sup>°</sup>
        <sup className="ml-1">7</sup>
      </span>
    );
  }

  if (variant === 'o7M7') {
    return (
      <span className={className}>
        <sup>° 7</sup>
        <sup className="ml-1">M7</sup>
      </span>
    );
  }

  if (variant === 'M9#5sus4') {
    return (
      <span className={className}>
        <sup>9</sup>
        <sub className="ml-1">
          aug sus
          <sup>4</sup>
        </sub>
      </span>
    );
  }

  return <div>{variant}</div>;
}
