import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Trash2, Plus } from 'react-feather';
import classNames from 'classnames';
import { Popover, Transition } from '@headlessui/react';
import Measure from './measure';
import {
  useStudioContext, addMeasureClicked, deleteSectionClicked, sectionTitleChanged,
} from '../pages/studio.store';
import { randomGuitarRhythm } from '../music/song';
import ChooseChordProgression from './chooseChordProgression';

/**
 * @param {object} props
 * @param {import('../music/song').DisplayableSection} props.section
 * @param {string} [props.className]
 * @returns {React.ReactElement}
 */
export default function Section({
  section,
  className,
  addFtuxClassName,
  ...props
}) {
  const { measures } = section;
  const [, dispatch] = useStudioContext();
  const [isShowingDialog, setIsShowingDialog] = useState(false);

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function onSectionTitleChanged(event) {
    event.preventDefault();

    dispatch(sectionTitleChanged(section, event.target.value));
  }

  return (
    <div className={`w-full bg-neutral-900 p-4 shadow-xl rounded mb-8 text-white ${classNames(className)}`} {...props}>
      <div className="group flex justify-between items-center mb-4">
        <input type="text" className="text-xl input input-ghost" value={section.name} onChange={onSectionTitleChanged} />
        <button
          type="button"
          className="cursor-pointer opacity-0 transition-opacity duration-500 hover:opacity-100 group-hover:opacity-50"
          onClick={() => dispatch(deleteSectionClicked(section))}
        >
          <Trash2 />
        </button>
      </div>

      <div className="grid grid-cols-4 gap-2">
        {measures.map((measure, index) => (
          <Measure
            key={measure.id}
            measure={measure}
            className={classNames(addFtuxClassName && index === 0 && 'ftux-measure-component')}
          />
        ))}
      </div>

      <Popover className="relative inline-block">
        {({ open }) => (
          <>
            <Popover.Button className="btn w-12 h-12">
              <Plus className={classNames('m-auto transition-all ease-in-out', open && 'transform rotate-45')} />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="duration-200 ease-in transform"
              enterFrom="opacity-0 -translate-x-1/4"
              enterTo="opacity-100 translate-x-0"
              leave="duration-200 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-1/4"
            >

              <Popover.Panel className="absolute top-0 left-full h-full ml-4 transition-all duration-100 ease-in-out flex items-center">
                <button type="button" className="btn btn-xs btn-ghost mr-2" onClick={() => setIsShowingDialog((prevVal) => !prevVal)}>
                  <Plus className="mr-2 w-4 h-4 text-secondary fill-current" />
                  Progression
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-ghost"
                  onClick={async () => {
                    const rhythm = await randomGuitarRhythm();
                    dispatch(addMeasureClicked(section, rhythm));
                  }}
                >
                  <Plus className="mr-2 w-4 h-4 text-secondary fill-current" />
                  Measure
                </button>
              </Popover.Panel>
            </Transition>
          </>
        )}

      </Popover>

      <ChooseChordProgression
        show={isShowingDialog}
        sectionId={section.id}
        onClose={() => setIsShowingDialog(false)}

      />
    </div>
  );
}

Section.propTypes = {
  className: PropTypes.string,
  section: PropTypes.shape({
    name: PropTypes.string.isRequired,
    measures: PropTypes.arrayOf(Measure.propTypes.measure).isRequired,
  }).isRequired,
};

Section.defaultProps = {
  className: '',
};
