/**
 * @typedef {object} DisplayableTonic
 * @property {string} title the title
 * @property {string} [subtitle] the subtitle for an alternative name
 * @property {string} value the value of the tonic
 */

export const Tonics = [
  { title: 'A', value: 'A' },
  { title: 'A♯', subtitle: 'B♭', value: 'A#' },
  { title: 'B', value: 'B' },
  { title: 'B♯', subtitle: 'C♭', value: 'B#' },
  { title: 'C', value: 'C' },
  { title: 'C♯', subtitle: 'D♭', value: 'C#' },
  { title: 'D', value: 'D' },
  { title: 'D♯', subtitle: 'E♭', value: 'D#' },
  { title: 'E', value: 'E' },
  { title: 'E♯', subtitle: 'F♭', value: 'E#' },
  { title: 'F', value: 'F' },
  { title: 'F♯', subtitle: 'G♭', value: 'F#' },
  { title: 'G', value: 'G' },
  { title: 'G♯', subtitle: 'A♭', value: 'G#' },
];
