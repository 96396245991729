import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Play,
  Pause,
  Settings,
  ChevronDown,

} from 'react-feather';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ReactComponent as Metronome } from '../assets/icons/metronome.svg';
import {
  useStudioContext,
  metronomeClicked,
  bpmChanged,
  keyChanged,
  playClicked,
  playFromPositionChecked,
} from '../pages/studio.store';
import { validKeySignatures } from '../music/keySignature';
import { ftuxActionPerformed, useFTUXContext } from './ftux.store';

// async function getDevices() {
//   const devices = (await navigator.mediaDevices?.enumerateDevices()) || [];

//   return Array.from(devices);
// }

function BpmTimeAndKey({ className }) {
  const [song, dispatch] = useStudioContext();

  const {
    bpm,
    timeSignature,
    keySignature,
  } = song;

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function onBpmChanged(event) {
    event.preventDefault();
    dispatch(bpmChanged(Number(event.target.value)));
  }

  return (
    <div className={classNames('inline-flex flex-col items-center rounded bg-neutral-800 mr-4 py-3 px-6', className)}>

      <div className="flex items-center mb-1">
        <input type="number" value={bpm} className="text-2xl w-24 input mr-4" onChange={onBpmChanged} />
        <div className="text-2xl mr-4">{timeSignature}</div>
        <select
          className="select select-sm bg-transparent"
          value={keySignature.id}
          onChange={(e) => dispatch(
            keyChanged(validKeySignatures.find((key) => key.id === e.target.value)),
          )}
        >
          {validKeySignatures.map((key) => (
            <option key={key.id} value={key.id}>{`${key.tonic + key.accidental} ${key.scale}`}</option>
          ))}
        </select>
      </div>

      <div className="flex justify-around items-center w-full">
        <div className="opacity-50 text-center">bpm</div>
        <div className="opacity-50 text-center">time</div>
        <div className="opacity-50 text-center">key</div>
      </div>
    </div>
  );
}

/**
 * @param {object} props
 * @param {string} [props.className]
 * @returns {React.ReactElement}
 */
export default function Toolbar({ className }) {
  const [song, dispatch] = useStudioContext();
  const [, ftuxDispatch] = useFTUXContext();
  const ref = useRef();

  // Implement me later
  // const outputDevices = useQuery('deviceList', async () => {
  //   const mediaDevices = await getDevices();

  //   const audioInputDevices = mediaDevices.filter((device) => device.kind === 'audioinput')
  //     .filter((device) => device.label && device.deviceId)
  //     .concat({
  //       deviceId: 'default',
  //       label: 'Default',
  //     });

  //   return audioInputDevices;
  // });

  const {
    isPlaying,
    isMetronomeOn,
    playFrom,
  } = song;

  return (
    <div className={`text-white inline-flex items-center justify-center ${classNames(className)}`} ref={ref}>
      {/* <div className="btn-group bg-neutral-800 mr-4 rounded flex-shrink-0"> */}
      <div className="btn-group mr-4 bg-neutral-800 rounded-md">
        <button
          type="button"
          className={`btn btn-ghost ftux-play-button ${classNames(
            isPlaying && 'btn-active opacity-100',
          )}`}
          onClick={() => {
            ftuxDispatch(ftuxActionPerformed());
            dispatch(playClicked());
          }}
          disabled={song?.sections.length === 0}
        >
          {isPlaying ? <Pause /> : <Play /> }

        </button>

        <Popover>
          <Popover.Button className="btn btn-ghost px-1 opacity-70">
            <ChevronDown />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1/4"
            enterTo="opacity-100 translate-y-1/2"
            entered="translate-y-1/2"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-1/2"
            leaveTo="opacity-0 translate-y-1/4"
          >
            <Popover.Panel className="absolute z-10 transform -translate-x-1/2 -bottom-6 rounded shadow-xl bg-neutral-800 p-4">
              <div className="form-control">
                <label className="cursor-pointer label" htmlFor="play-from-beginning">
                  <span className="label-text mr-4">Always play from beginning</span>
                  <input
                    type="radio"
                    name="radio-6"
                    value="play-from-beginning"
                    className="radio radio-secondary"
                    id="play-from-beginning"
                    onChange={(event) => dispatch(playFromPositionChecked(event.target.value))}
                    checked={playFrom === 'play-from-beginning'}
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="cursor-pointer label" htmlFor="play-from-measure">
                  <span className="label-text mr-4">Always play from current measure</span>
                  <input
                    type="radio"
                    name="radio-6"
                    value="play-from-measure"
                    className="radio radio-secondary"
                    id="play-from-measure"
                    onChange={(event) => dispatch(playFromPositionChecked(event.target.value))}
                    checked={playFrom === 'play-from-measure'}
                  />
                </label>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        {/* </div> */}

        {/* <button
          type="button"
          className={classnames(
            'opacity-50 hover:opacity-100
            transition-opacity duration-200 cursor-pointer p-4 rounded-r',
            isRepeatOn && 'bg-blue-700 opacity-100',
            isPlaying && 'cursor-not-allowed hover:opacity-50',
          )}
          onClick={() => dispatch(repeatClicked())}
          disabled={isPlaying}
        >
          <Repeat />
        </button> */}
      </div>

      <button
        type="button"
        className={`btn mr-4 ${
          classNames(isMetronomeOn && 'btn-active opacity-100')
        }`}
        onClick={() => dispatch(metronomeClicked())}
      >
        <Metronome className=" w-6 h-6" />
      </button>

      <Popover>
        <Popover.Button className="btn btn-ghost rounded-full w-0 p-0 md:w-auto md:px-4 md:opacity-70 hover:opacity-100 ">
          <Settings />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1/4"
          enterTo="opacity-100 translate-y-1/2"
          entered="translate-y-1/2"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-1/2"
          leaveTo="opacity-0 translate-y-1/4"
        >
          <Popover.Panel className="absolute z-10 transform -translate-x-1/2 -bottom-10 rounded shadow-xl">
            <BpmTimeAndKey />
          </Popover.Panel>
        </Transition>
      </Popover>

      <BpmTimeAndKey className="md:hidden" />

      {/*
      <Popover>
        <Popover.Button className="btn btn-square">
          <Speaker />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1/4"
          enterTo="opacity-100 translate-y-1/2"
          entered="translate-y-1/2"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-1/2"
          leaveTo="opacity-0 translate-y-1/4"
        >
          <Popover.Panel className="absolute z-10 transform -translate-x-1/2 -bottom-10 rounded">
            <div className="bg-gray-600 rounded dark:text-white shadow-lg z-50 w-72 p-6">
              <div className="mb-3 font-bold">Output Device</div>

              <div className="flex items-center">

                <select
                  className="select select-bordered flex-grow max-w-xs mr-2"
                  value="default"
                  onChange={(e) => {
                    console.log('changed', e.target.value);
                  }}
                >
                  {outputDevices.data?.map((device) => (
                    <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                  ))}
                </select>

                <button type="button" className="btn btn-ghost btn-sm flex-shrink-0"
                 onClick={() => dispatch(testOutputClicked())}>
                  <Volume2 className="mr-2" />
                  Test
                </button>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover> */}
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
};

Toolbar.defaultProps = {
  className: '',
};
