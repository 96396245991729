/* eslint-disable no-use-before-define */
import { pick } from 'lodash';
import { v4 as uuid } from 'uuid';
import { timeToMeasureDuration } from '../music/duration';
import Collection from './collection';
import { sha256 } from './hash';

/** @typedef {import('tone').Unit.Subdivision} Subdivision */

/**
 * @typedef {object} FirestoreGuitarRhythm
 * @property {string} name the name
 * @property {string[]} tags the tags
 * @property {FirestoreGuitarRhythmNote[]} notes the notes to play on the guitar
 */

/**
 * @typedef {object} FirestoreGuitarRhythmNote
 * @property {Subdivision} duration the tonalJS duration subdivision
 * @property {import('../music/guitar').GuitarArticulation} articulation the articulation
 * @property {import('../music/guitar').GuitarStroke} stroke the stroke to play
 */

/**
 * @typedef {Object} DisplayableGuitarRhythm
 * @property {string} name - name of rhythm
 * @property {string} id - id of the rhythm
 * @property {string[]} tags - tags of ryhthm
 * @property {DisplayableGuitarRhythmNote[]} notes the notes to play
 */

/**
 * @typedef {object} DisplayableGuitarRhythmNote
 * @property {string} id the id
 * @property {Subdivision} duration the tonalJS duration subdivision
 * @property {import('../music/guitar').GuitarArticulation} articulation the articulation
 * @property {import('../music/guitar').GuitarStroke} stroke the stroke to play
 */

/**
 * @param {GuitarRhythmNote[]} notes
 * @returns {Promise<string>} a hash
 */
function createRhythmHash(notes) {
  const text = notes.map(({ duration, articulation, stroke }) => `${duration}-${articulation}-${stroke}`).join('|');
  return sha256(text);
}

/**
 * @param {DisplayableGuitarRhythm} rhythm
 * @returns {FirestoreGuitarRhythm}
 */
async function toFirestore(rhythm) {
  const sum = rhythm.notes
    .reduce((accSum, note) => accSum + timeToMeasureDuration(note.duration), 0);

  if (sum !== 1) {
    throw new Error('All rhythms must be a full measure long. Please make sure all notes add up to 1 measure. See notes above more more details.');
  }

  const notes = rhythm.notes.map((note) => pick(note, 'articulation', 'duration', 'stroke'));

  return {
    ...rhythm,
    notes,
    hash: await createRhythmHash(notes),
  };
}

/**
 * @param {DocumentSnapshot<GuitarRhythm>} document
 * @returns {DisplayableGuitarRhythm}
 */
function fromFirestore(document) {
  const data = document.data();
  return {
    ...data,
    id: document.id,
    isFavorite: rhythms.isFavorite(document.id),
    notes: data.notes.map((note) => ({
      ...note,
      id: uuid(),
    })),
  };
}

// singleton
const rhythms = new Collection('rhythms', { toFirestore, fromFirestore });

export default rhythms;
