import React, { Fragment } from 'react';
import { Heart, X } from 'react-feather';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { last } from 'lodash';
import {
  useChordProgressionsReducer,
  chordProgressionRowClicked,
  dismissCreateDialog,
  openCreateDialog,
} from './chordProgressions.store';
import CreateChordProgression from '../components/createChordProgression';
import SearchableCollection from '../components/searchableCollection';
import ChordProgressionsCollection from '../api/chordProgressions';
import { useUserContext } from '../api/user';

/**
 *
 * @param {object} props
 * @param {import('../api/chordProgressions').DisplayableRomanNumeralChord} chord
 */
function RomanNumeralChord({ chord }) {
  return (
    <span key={chord.id}>
      <span>{chord.numeral.name}</span>
      {chord.variant !== '5' && <span className="text-xs opacity-60 ml-2">{chord.variant}</span>}
    </span>
  );
}

function ChordProgressions() {
  const [state, dispatch] = useChordProgressionsReducer();
  const {
    selectedChordProgression,
    isCreateDialogOpen,
  } = state;
  const { isAdmin } = useUserContext();

  return (
    <div className="grid grid-cols-4 relative">
      <div className="col-span-1 p-4 md:hidden" />
      <div className="col-span-2 p-4 md:col-span-4">
        <div className="text-3xl mb-2">Chord Progressions</div>
        <div className="text-base-content-dim">Song Chord Progressions</div>

        <SearchableCollection
          collection={ChordProgressionsCollection}
          newButtonClicked={() => {
            dispatch(chordProgressionRowClicked(null));
            dispatch(openCreateDialog());
          }}
        >
          {(chordProgressions, favoriteClicked) => (
            <div className="overflow-x-auto">
              <table className="table w-full table-fixed">
                <thead>
                  <tr className="bg-neutral-900">
                    <th className="w-3/12 bg-transparent">Name</th>
                    <th className="w-6/12 bg-transparent">Structure</th>
                    <th aria-label="Favorite" className="w-3/12 text-center bg-transparent">
                      <button type="button" className="btn btn-circle btn-ghost disabled:bg-transparent" disabled>
                        <Heart />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {chordProgressions.map((chordProgression) => (
                    <tr
                      key={chordProgression.id}
                      className={
                    classNames('cursor-pointer hover:bg-neutral-900',
                      chordProgression.id === selectedChordProgression?.id && 'active')
                  }
                      onClick={() => dispatch(chordProgressionRowClicked(chordProgression))}
                    >
                      <td className="overflow-ellipsis overflow-auto bg-transparent" title={chordProgression.name}>
                        {chordProgression.name}
                      </td>
                      <td className="truncate overflow-ellipsis bg-transparent" title={chordProgression.chords.map((chord) => `${chord.numeral.name}${(chord.variant !== '5' && chord.variant) || ''}`).join(' - ')}>
                        {chordProgression.chords.map((chord) => (
                          <React.Fragment key={chord.id}>
                            <RomanNumeralChord chord={chord} />
                            {chord !== last(chordProgression.chords) && <span> - </span>}
                          </React.Fragment>
                        ))}

                      </td>
                      <td className="text-center bg-transparent">
                        <button
                          type="button"
                          className="btn btn-circle btn-ghost"
                          onClick={(event) => {
                            event.stopPropagation();
                            favoriteClicked(chordProgression);
                          }}
                        >
                          <Heart fill={chordProgression.isFavorite ? 'currentColor' : 'none'} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </SearchableCollection>

      </div>
      <div className={classNames('col-span-1 p-6 md:p-0 md:h-full md:absolute md:top-0 md:left-0 md:w-full md:z-50', !selectedChordProgression && 'hidden')}>
        {selectedChordProgression && (
        <div className="md:bg-neutral-800 rounded p-4">
          <div className="flex justify-between">

            <div className="font-bold mb-4 dark:text-white">{selectedChordProgression.name}</div>

            <button type="button" className="btn btn-ghost btn-sm rounded-full" onClick={() => dispatch(chordProgressionRowClicked(null))}>
              <X />
            </button>
          </div>

          <div className="mb-2 font-bold">Chords</div>
          <div className="mb-4">
            {selectedChordProgression.chords.map((chord) => (
              <React.Fragment key={chord.id}>
                <RomanNumeralChord chord={chord} />
                {chord !== last(selectedChordProgression.chords) && <span> - </span>}
              </React.Fragment>
            ))}
          </div>

          <div className="mb-2 font-bold">Scale</div>
          <div className="mb-6 flex">
            {selectedChordProgression.scale}
          </div>

          <div className="mb-2 font-bold">Tags</div>
          <div className="mb-6 flex flex-wrap">
            {selectedChordProgression.tags.map((tag) => (<div className="mr-4" key={tag}>{tag}</div>))}
          </div>

          {isAdmin() && (
            <button type="button" className="btn btn-primary w-full" onClick={() => dispatch(openCreateDialog())}>Edit</button>
          )}
        </div>
        )}
      </div>

      <Transition show={isCreateDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => dispatch(dismissCreateDialog())}
        >
          <CreateChordProgression
            chord={selectedChordProgression}
            onClose={() => dispatch(dismissCreateDialog())}
          />
        </Dialog>
      </Transition>
    </div>
  );
}

export default ChordProgressions;
