import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/icons/logo.svg';

/**
 *
 * @param {object} props
 * @param {string} [props.className]
 * @returns {React.ReactElement}
 */
function NavBar({ className }) {
  return (
    <nav className={classNames('flex items-center justify-between', className)}>

      <Link to="/" className="z-10 py-2">
        <Logo className="h-full sm:w-32" />
      </Link>

      <div>
        {/* <Link to="#features">
          <button type="button"
          className="btn btn-ghost sm:btn-sm opacity-90 ml-5">Features</button>
        </Link> */}

        <Link to="/login">
          <button type="button" className="btn btn-ghost sm:btn-xs opacity-90 ml-5">Log In</button>
        </Link>

        <Link to="/sign-up">
          <button type="button" className="btn btn-ghost sm:btn-xs opacity-90 ml-5">Sign Up</button>
        </Link>
      </div>

    </nav>
  );
}

export default NavBar;
