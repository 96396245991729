import { createContext, useContext, useReducer } from 'react';
import { Scale, Chord } from '@tonaljs/tonal';
import { sample as randomArrayElement, uniq } from 'lodash';
import { GuitarPart } from '../music/guitar';
import Player, { PlayerSession } from '../music/player';
import { Tonics } from '../music/tonics';
import { findFrettings } from '../music/chords';

// CONTEXT
const ChordPickerContext = createContext(null);

export function useChordPickerContext() {
  return useContext(ChordPickerContext);
}

// ACTIONS

export function tonicClicked(clickedTonic) {
  return {
    type: 'tonicClicked',
    payload: clickedTonic,
  };
}

export function variantClicked(clickedVariant) {
  return {
    type: 'variantClicked',
    payload: clickedVariant,
  };
}

export function chordPositionClicked(clickedChordPosition) {
  return {
    type: 'chordPositionClicked',
    payload: clickedChordPosition,
  };
}

export function shuffleClicked() {
  return {
    type: 'shuffleClicked',
  };
}

export function nextClicked() {
  return {
    type: 'nextClicked',
  };
}

export function backClicked() {
  return {
    type: 'backClicked',
  };
}

export const ChordVariants = uniq([...Scale.scaleChords('major'), ...Scale.scaleChords('minor')]);

function findFrettingsForChordPicker(tonic, variant) {
  return findFrettings(Chord.get(`${tonic.value}${variant}`));
}

// REDUCER

const initialTonic = Tonics[0];
const initialChordVariant = '';

const initialState = {
  selectedTonic: initialTonic,
  selectedVariant: initialChordVariant,
  selectedChordPosition: null,
  frettings: [],
  showVariants: false,
};

function chordPickerReducer(state, action) {
  switch (action.type) {
    case 'tonicClicked': {
      return {
        ...state,
        selectedTonic: action.payload,
        frettings: findFrettingsForChordPicker(action.payload, state.selectedVariant),
      };
    }

    case 'variantClicked': {
      return {
        ...state,
        selectedVariant: action.payload,
        frettings: findFrettingsForChordPicker(state.selectedTonic, action.payload),
      };
    }

    case 'chordPositionClicked': {
      const part = GuitarPart.createFromFretting(action.payload, '1n');
      const session = PlayerSession.createForPart(part);
      Player.play(session);

      return {
        ...state,
        selectedChordPosition: action.payload,
      };
    }

    case 'shuffleClicked': {
      const selectedTonic = randomArrayElement(Tonics);
      const selectedVariant = randomArrayElement(ChordVariants);

      return {
        ...state,
        selectedTonic,
        selectedVariant,
      };
    }

    case 'nextClicked': {
      return {
        ...state,
        showVariants: true,
      };
    }

    case 'backClicked': {
      return {
        ...state,
        showVariants: false,
      };
    }

    default:
      return state;
  }
}

/**
 *
 * @param {Chord} chord
 * @returns {[React.ReducerStateWithoutAction<any>, React.Dispatch<any>]} a reducer
 */
export function useChordPickerReducer(chord) {
  const finalInitialState = { ...initialState };

  if (chord) {
    finalInitialState.selectedTonic = chord.tonic;
    finalInitialState.selectedVariant = chord.variant;
    finalInitialState.selectedChordPosition = chord.chordPosition;
    finalInitialState.frettings = findFrettingsForChordPicker(chord.tonic, chord.variant);
  }

  return useReducer(chordPickerReducer, finalInitialState);
}
