import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import NavBar from '../components/navBar';
import HeroImage from '../assets/images/singer-kid.jpg';
import Spinner from '../components/spinner';
import { verifyUserEmail } from '../api/auth';
import { useFTUXContext, userStartedFtux, waitForUserToStartTour } from '../components/ftux.store';

export default function SignupFinish() {
  const emailVerificationQuery = useQuery('verifyUserEmail', () => verifyUserEmail(), {
    retry: false,
  });

  const [, ftuxDispatch] = useFTUXContext();

  useMemo(() => {
    ftuxDispatch(waitForUserToStartTour());
  }, [ftuxDispatch]);

  function renderContents() {
    if (emailVerificationQuery.isLoading) {
      return (
        <div className="m-auto text-center block">
          <div className="text-3xl mb-8">Verifying Email</div>
          <div className="flex justify-center">
            <Spinner />
          </div>
        </div>
      );
    }

    if (emailVerificationQuery.error) {
      return (
        <div>
          <div className="text-3xl mb-8">Verifying Email</div>

          <div className="text-error">
            An error occurred while trying to verify your email address.
            <br />
            <Link to="/sign-up">
              Try signing in again here
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="text-3xl mb-8">Email Verified</div>

        <div className="mb-8">
          Your email has been verified. Welcome to Polytones,
          {' '}
          {'let\'s'}
          {' '}
          get started!
        </div>

        <Link
          to="/projects"
          replace
          className="btn btn-primary"
          as="a"
          onClick={() => {
            ftuxDispatch(userStartedFtux());
          }}
        >
          Start the tour
        </Link>
      </div>
    );
  }

  return (
    <div className="relative z-10 bg-neutral-900 overflow-hidden p-6 w-full h-screen flex flex-col">
      <div
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'top right',
          backgroundSize: 'cover',
          zIndex: -100,
        }}
        className="absolute w-full h-full top-0 left-0"
      />

      <div className="bg-base-300 bg-opacity-70 w-full h-full absolute top-0 left-0" style={{ zIndex: -50 }} />

      <NavBar />

      <div className="flex-grow flex items-center justify-center">
        <div className="card flex-shrink-0 shadow-2xl bg-base-100 w-full max-w-3xl">
          <div className="card-body text-center">
            {renderContents()}
          </div>
        </div>
      </div>

    </div>
  );
}
