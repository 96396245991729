/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import * as Tone from 'tone';
import { last } from 'lodash';
import { Emitter } from 'tone';
import { GuitarEvent, GuitarPart } from './guitar';
import { createMetronomePart } from './metronome';

/**
 * @typedef {import('tone')} Tone
 * @typedef {import('./guitar').GuitarEvent} GuitarEvent
 */

/**
 * @typedef {Tone.Part} PlayerSessionPart
 * @property {import('./guitar').GuitarEvent[]} events - guitar events
 * @property {boolean} loop - true to loop the part, false otherwise
 */

/**
 * @typedef {object} GuitarRhythmState
 * @property {import('./guitar').GuitarRhythm} rhythm - the rhythm
 * @property {number} bpm - the tempo
 * @property {boolean} isRepeatOn - should repeat the rhythm
 * @property {boolean} isMetronomeOn - should play a metronome
 * @property {import('@tonaljs/chord').Chord} chord - the chord to play
 */

export class PlayerSession extends Emitter {
  /**
   * @param {PlayerSessionPart[]} parts - the parts to play
   * @param {Tone.Sequence} metronome - the metronome to play
   */
  constructor(parts, metronome) {
    super();
    this.parts = parts;
    this.metronome = metronome;
    this.startAt = 0;
  }

  start() {
    this.metronome?.start(0);
    this.parts.forEach((part) => {
      part.start(0);
    });
  }

  stop() {
    this.metronome?.stop();
    this.metronome?.cancel();
    this.parts.forEach((part) => {
      part.stop();
      part.cancel();
    });
  }

  dispose() {
    this.stop();
    this.metronome?.dispose();
    this.parts.forEach((part) => part.dispose());
    super.dispose();
  }

  /**
   * @param {boolean} value set loop to true or false
   */
  set loop(value) {
    this.metronome.loop = value;
    this.parts.forEach((part) => {
      if (value && part.events) {
        const maxTime = Math.max(...part.events.map((event) => event.time
        + Tone.Time(event.duration).toSeconds()));
        part.loopStart = 0;
        part.loopEnd = maxTime;
      }

      part.loop = value;
    });
  }

  /**
   *
   * @param {GuitarRhythmState} state the state
   * @returns {PlayerSession} the session
   */
  static createFromGuitarRhythmState(state) {
    const guitarPart = GuitarPart.createFromRhythm(state.rhythm, state.chord);
    const metronome = createMetronomePart(state.isMetronomeOn);
    const session = new PlayerSession([guitarPart], metronome);
    session.loop = state.isRepeatOn;
    session.metronome.on = state.isMetronomeOn;
    session.key = 'rhythm';
    return session;
  }

  /**
   * @param {Tone.Part} part a part to create a session for
   * @returns {PlayerSession} the new session
   */
  static createForPart(part) {
    const metronome = createMetronomePart(false);
    const session = new PlayerSession([part], metronome);
    return session;
  }

  /**
   * @param {import('./song').DisplayableSong} song the song
   * @returns {PlayerSession}
   */
  static createForSong(song) {
    /** @type {GuitarEvent[]} */
    const guitarEvents = [];
    let currentTime = 0;

    song.sections.forEach((section) => {
      section.measures.forEach((measure) => {
        measure.tracks.forEach((track) => {
          if (track.type !== 'guitar') {
            throw new Error('only guitar tracks are currently supported');
          }

          const events = GuitarEvent.createFromTrack(track, currentTime, measure);

          guitarEvents.push(...events);

          const lastEvent = last(events);
          currentTime = lastEvent.time + Tone.Time(lastEvent.duration).toSeconds();
        });
      });
    });

    const metronome = createMetronomePart(song.isMetronomeOn);
    const guitarPart = new GuitarPart(guitarEvents, (event) => {
      // eslint-disable-next-line no-use-before-define
      session.emit('event', event);
    });
    const session = new PlayerSession([guitarPart], metronome);

    if (song.playFrom === 'play-from-measure' && song.selectedMeasure) {
      const eventToStartPlayback = guitarEvents
        .find((event) => event.measureId === song.selectedMeasure.id);

      if (eventToStartPlayback) {
        session.startAt = eventToStartPlayback.time;
      }
    }

    session.key = 'song';
    session.loop = true;

    return session;
  }
}

class Player extends Emitter {
  /**
   * @param {boolean} _loop
   */
  _loop = true;

  /**
   * @param {PlayerSession|null} [session]
   * @type {PlayerSession}
   */
  session = null;

  constructor() {
    super();
    Tone.Transport.on('start', () => this.emit('start'));
    Tone.Transport.on('stop', () => this.emit('stop'));
  }

  /**
   * @param {PlayerSession} session
   */
  async play(session) {
    await Tone.start();

    // stop previous session
    Tone.Transport.cancel();
    Tone.Transport.stop();

    this.session = session;
    this.session.start();

    // start the new one
    if (session.startAt !== undefined) {
      Tone.Transport.start();
      Tone.Transport.seconds = session.startAt;
    } else {
      Tone.Transport.start();
    }
  }

  stop() {
    Tone.Transport.cancel();
    Tone.Transport.stop();

    if (this.session) {
      this.session.dispose();

      // TODO: figure out why this hack is required to make stop
      // work on rhythms page for preview toolbar
      // requestAnimationFrame(() => {
      delete this.session;
      // });
    }
  }

  /**
   * @param {string} [sessionKey]
   * @returns {boolean}
   */
  isPlaying(sessionKey) {
    if (sessionKey) {
      return this.session?.key === sessionKey;
    }

    return !!this.session;
  }

  /**
   * @param {number} value
   */
  // eslint-disable-next-line class-methods-use-this
  setBpm(value) {
    Tone.Transport.bpm.value = value;
  }

  /**
   * @param {boolean} value
   */
  set loop(value) {
    this._loop = value;
    if (this.session) {
      this.session.loop = value;
    }
  }

  /**
   * @returns {boolean}
   */
  get loop() {
    return this._loop;
  }
}

// TODO: remove this singleton export a singleton
export default new Player();
