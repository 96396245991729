import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import NavBar from '../components/navBar';
import HeroImage from '../assets/images/acoustic-player.jpg';
import StudioMacImage from '../assets/images/studio-mac.png';
import ChordsImage from '../assets/images/chords.png';
import RhythmsiPadImage from '../assets/images/rhythms-iPad.png';
import AddChordProgressionImage from '../assets/images/add-chord-progression.png';
import { useUserContext } from '../api/user';
import Footer from '../components/footer';
import Spinner from '../components/spinner';

export default function LandingPage() {
  const { isReturningUser, authState, user } = useUserContext();

  if (isReturningUser() && authState !== 'complete') {
    return (
      <div className="flex items-center justify-center w-full mt-6">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      { isReturningUser() && authState === 'complete' && user && (<Redirect to="/projects" />) }

      {/* TOP SECTION */}
      <div
        className="hero min-h-screen max-h-screen bg-base-100"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'top right',
          backgroundSize: 'cover',
        }}
      >
        <div className="hero-overlay bg-opacity-0 md:bg-opacity-60" />

        <NavBar className="absolute top-0 left-0 z-10 w-full p-4" />

        <div className="flex flex-col w-full sm:justify-end">
          <div className="text-white mt-40 ml-12">
            <h1 className="text-4xl font-semibold mb-2">
              Make music
              <br />
              easy. Online.
            </h1>
            <div className="text-base-content-dim">A free song writing tool on the web</div>
            <Link to="/sign-up">
              <button type="button" className="btn btn-primary mt-4">Join now</button>
            </Link>
          </div>
        </div>
      </div>

      {/* WRITE SONGS */}
      <div className="bg-base-200 w-full hero min-h-screen">
        <div className="flex justify-between w-full p-12 md:justify-start md:flex-col max-w-6xl">
          <div className="max-w-sm md:mb-8 md:px-4">
            <h1 className="text-4xl font-semibold mb-2">Write Songs</h1>
            <div className="text-base-content-dim mb-4">Polytones allows you to come up with chords and rhythms that sound great together.</div>

            <ul className="text-base-content-dim list-disc list ml-4 leading-loose">
              <li>No music theory required</li>
              <li>Easily choose chords that sound great together</li>
              <li>Access from anywhere, anytime online</li>
            </ul>

          </div>

          <div className="max-w-3xl">
            <img src={StudioMacImage} alt="studio page on imac" />
          </div>
        </div>
      </div>

      {/* find chords */}
      <div className="bg-neutral-300 w-full hero min-h-screen-1/2">
        <div className="flex justify-between items-center p-12 md:flex-col-reverse max-w-6xl">
          <div className="mr-12 md:mr-0 max-w-3xl">
            <img
              className="rounded-2xl"
              src={ChordsImage}
              alt="chord picker"
            />
          </div>

          <div>
            <h1 className="text-neutral-900 text-4xl font-semibold mb-2">Find Chords</h1>
            <div className="text-neutral-900 opacity-60 mb-4">
              Easily choose complementary chords
            </div>

          </div>
        </div>
      </div>

      {/* FIND RHYTHMS */}
      <div className="bg-base-200 w-full hero min-h-screen-1/2 ">
        <div className="flex items-center space-between sm:h-full p-12 md:flex-col">
          <div className="max-w-sm">
            <h1 className="text-4xl font-semibold mb-2">Add Rhythms</h1>
            <div className="text-base-content-dim mb-4">Search from a large library of rhythms.</div>

            <ul className="text-base-content-dim list-disc list ml-4 leading-loose">
              <li>Choose from different genres</li>
              <li>Search by mood</li>
              <li>Apply rhythms to any chords</li>
            </ul>
          </div>

          <div className="max-w-3xl ml-12 p-12">
            <img src={RhythmsiPadImage} alt="rhythms page" />
          </div>
        </div>
      </div>

      {/* CHORD PROGRESSIONS */}
      <div className="bg-neutral-900 w-full hero min-h-screen-1/2 p-12">
        <div className="flex items-center w-full sm:h-full sm:pt-12 px-12 max-w-6xl md:flex-col-reverse">
          <div className="rounded-2xl overflow-hidden mr-12 md:mr-0">
            <img src={AddChordProgressionImage} alt="add chord progression page" />
          </div>

          <div className="md:mb-8">
            <h1 className="text-4xl font-semibold mb-2">Apply Chord Progressions</h1>
            <div className="text-base-content-dim mb-4">Search from a collection of common and uncommon chord progressions.</div>

            <ul className="text-base-content-dim list-disc list ml-4 leading-loose">
              <li>Progressions from different genres.</li>
              <li>Add the progressions to any song.</li>
            </ul>
          </div>

        </div>
      </div>

      {/* Arrangements */}
      {/* <div className="bg-base-200 w-full hero min-h-screen-1/2">
        <div className="flex flex-col w-full sm:h-full sm:pt-12 px-12">
          <div className="max-w-sm">
            <h1 className="text-4xl font-semibold mb-2">Arrange Your Song</h1>
            <div className="text-base-content-dim mb-4">
            Choose from from a large library of song arrangements.
            </div>

            <ul className="text-base-content-dim list-disc list ml-4 leading-loose">
              <li>Search by genre.</li>
              <li>Create a new song with the arrangement.</li>
            </ul>
          </div>
        </div>
      </div> */}

      <div className="bg-neutral-300 text-neutral-900 w-full p-12">
        <div className="flex flex-col items-center text-center w-full">
          <div className="max-w-sm">
            <h1 className="text-4xl font-semibold mb-2">Sign up now</h1>
            <div className="mb-4">
              Create your
              {' '}
              <span className="border-b-2 border-primary "> free </span>
              {' '}
              account today!
            </div>

            <div>
              <Link to="/sign-up">
                <button type="button" className="btn btn-primary mt-4 text-neutral-300">Join now</button>
              </Link>
            </div>

          </div>
        </div>

      </div>

      <Footer />
    </div>
  );
}
