import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  getFirestore, doc, getDoc, setDoc,
} from 'firebase/firestore';
import Rhythms from './rhythms';
import ChordProgressions from './chordProgressions';
import Arrangements from './arrangements';
import { logout } from './auth';

export const UserContext = createContext(null);

export function useUserContext() {
  return useContext(UserContext);
}

export async function deleteAccount() {
  const auth = getAuth();

  try {
    await auth.currentUser.delete();
  } catch (error) {
    if (error.code === 'auth/requires-recent-login') {
      // eslint-disable-next-line no-alert
      window.alert('Please sign-in and try again.');
      await auth.signOut();
    } else {
      throw error;
    }
  }

  await logout();
}

async function getHasDoneFTUX() {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const ref = doc(db, 'users', userId);

  const snapshot = await getDoc(ref);
  const user = snapshot.data();
  return !!user?.hasDoneFTUX;
}

export async function markFTUXDone() {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const ref = doc(db, 'users', userId);

  await setDoc(ref, {
    hasDoneFTUX: true,
  }, { merge: true });
}

export function UserProvider({ children }) {
  const [user, setUser] = useState();
  const [authState, setAuthState] = useState('idle');

  function isAdmin() {
    return user?.role === 'admin';
  }

  function isReturningUser() {
    return localStorage?.getItem('returningUser') === 'true';
  }

  useEffect(() => {
    const auth = getAuth();

    setAuthState('loading');

    if (!auth) {
      setAuthState('complete');
      return () => {};
    }

    const unsubscribe = onAuthStateChanged(auth, async (newUser) => {
      if (newUser) {
        const [result, hasDoneFTUX] = await Promise.all([
          newUser.getIdTokenResult(),
          getHasDoneFTUX(),
        ]);

        setUser({
          ...newUser,
          ...result.claims,
          hasDoneFTUX,
        });

        Rhythms.preloadFavorites();
        ChordProgressions.preloadFavorites();
        Arrangements.preloadFavorites();
      }

      setAuthState('complete');
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <UserContext.Provider value={{
      user,
      isAdmin,
      authState,
      isReturningUser,
    }}
    >
      {children}
    </UserContext.Provider>
  );
}
