import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Gdpr() {
  const [acceptedGDPR, setAcceptedGDPR] = useState(localStorage?.getItem('gdpr') === 'true');

  function gdprClicked() {
    localStorage.setItem('gdpr', 'true');
    setAcceptedGDPR(true);
  }

  if (acceptedGDPR) {
    return null;
  }

  return (
    <div className="fixed bottom-0 w-full bg-base-200 px-6 py-2 ">
      <div className="flex justify-between items-center max-w-3xl m-auto">

        <span className="opacity-60">

          We use cookies to improve your experience.
          {' '}
          <Link as="a" className="link link-hover" to="/privacy-policy">Learn More</Link>
          {' '}
        </span>

        <button type="button" className="btn btn-sm btn-primary" onClick={gdprClicked}>Got It</button>
      </div>
    </div>
  );
}
