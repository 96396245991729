import { createContext, useContext, useReducer } from 'react';
import { findFrettings, getChordsForKeySignature } from '../music/chords';
import Player, { PlayerSession } from '../music/player';
import { Tonics } from '../music/tonics';

// CONTEXT
const RhythmPreviewToolbarContext = createContext(null);

export function useRhythmPreviewToolbarContext() {
  return useContext(RhythmPreviewToolbarContext);
}

export function previewClicked(payload) {
  return (state) => {
    if (Player.isPlaying()) {
      requestAnimationFrame(() => {
        Player.stop();
      });
    } else {
      const session = PlayerSession.createFromGuitarRhythmState(payload);
      Player.play(session);
    }

    return {
      ...state,
      isPlaying: !Player.isPlaying(),
    };
  };
}
export function bpmChanged(bpm) {
  return {
    type: 'bpmChanged',
    payload: bpm,
  };
}

export function chordChanged(tonic, variant, chordPosition) {
  return {
    type: 'chordChanged',
    payload: {
      tonic,
      variant,
      chordPosition,
    },
  };
}

export function metronomeClicked() {
  return {
    type: 'metronomeClicked',
  };
}

export function loopClicked() {
  return {
    type: 'loopClicked',
  };
}

// REDUCER

const chordsLists = getChordsForKeySignature({ tonic: Tonics[0].value, accidental: '', scale: 'major' });
const initialChord = chordsLists[0][0];

const initialTonic = Tonics[0];
const initialChordVariant = initialChord.variant;
const initialChordPosition = findFrettings(initialChord)[0];

const initialState = {
  bpm: 120,
  isRepeatOn: true,
  isMetronomeOn: true,
  isPlaying: false,
  minBpm: 40,
  maxBpm: 320,
  chord: {
    tonic: initialTonic,
    variant: initialChordVariant,
    chordPosition: initialChordPosition,
  },
};

function rhythmPreviewToolbarReducer(state = initialState, action) {
  if (typeof action === 'function') {
    return action(state);
  }

  switch (action.type) {
    case 'bpmChanged': {
      const bpm = Number(action.payload);

      if (bpm < state.minBpm) {
        return state;
      }

      if (bpm > state.maxBpm) {
        return state;
      }

      Player.setBpm(bpm);

      return {
        ...state,
        bpm,
      };
    }

    case 'metronomeClicked': {
      return {
        ...state,
        isMetronomeOn: !state.isMetronomeOn,
      };
    }

    case 'loopClicked': {
      return {
        ...state,
        isRepeatOn: !state.isRepeatOn,
      };
    }

    case 'chordChanged': {
      return {
        ...state,
        chord: action.payload,
      };
    }

    default:
      return state;
  }
}

export function useRhythmPreviewToolbarReducer() {
  return useReducer(rhythmPreviewToolbarReducer, initialState);
}
