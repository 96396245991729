import classNames from 'classnames';
import React from 'react';
import { ChevronLeft, Shuffle } from 'react-feather';
import {
  useChordPickerReducer,
  tonicClicked,
  variantClicked,
  chordPositionClicked,
  shuffleClicked,
  nextClicked,
  backClicked,
  ChordVariants,
} from './chordPicker.guitar.store';
import { Tonics } from '../music/tonics';
import GuitarChord from './guitarChord';

/**
 * @param {object} props the props
 * @param {Function} props.selectClicked the callback for when chord picked
 * @param {import('../api/songApi').Chord} props.chord the chord
 * @returns {React.ReactElement} the component
 */
function ChordPicker({ selectClicked, chord }) {
  const [state, dispatch] = useChordPickerReducer(chord);
  const {
    selectedTonic,
    selectedVariant,
    selectedChordPosition,
    showVariants,
    frettings,
  } = state;

  function renderChordPicker() {
    return (
      <div>
        <div className="text-xl">
          Choose Chord
        </div>

        <div className="flex justify-center items-center">

          <div className="dropdown mr-4 mb-6 mt-6">
            <button tabIndex={0} type="button" className="btn text-3xl h-auto py-4">
              {selectedTonic.title}
              {selectedTonic.subtitle && (
              <span className="ml-2 opacity-50 text-2xl">
                {`(${selectedTonic.subtitle})`}
              </span>
              )}

            </button>
            <ul tabIndex={0} role="menu" className="menu dropdown-content mr-4 flex flex-col bg-neutral-700 rounded mt-2 w-28 max-h-40 overflow-y-auto">
              {Tonics.map((tonic) => (
                <button
                  type="button"
                  className={
                classNames(
                  'btn btn-ghost text-lg',
                  selectedTonic === tonic && 'btn-active',
                )
              }
                  key={tonic.value}
                  onClick={() => dispatch(tonicClicked(tonic))}
                >
                  {tonic.title}
                  {tonic.subtitle && (
                    <span className="ml-2 opacity-50 text-sm">
                      {`(${tonic.subtitle})`}
                    </span>
                  )}
                </button>
              ))}
            </ul>
          </div>

          <div className="dropdown">
            <button tabIndex={0} type="button" className="btn text-2xl h-auto py-4 ">
              {selectedVariant}
            </button>
            <div tabIndex={0} role="menu">
              <ul tabIndex={0} role="menu" className="menu dropdown-content mr-4 flex flex-col bg-neutral-700 rounded mt-2 w-28 max-h-40 overflow-y-auto">
                {ChordVariants.map((chordVariant) => (
                  <button
                    type="button"
                    key={chordVariant}
                    className={
                classNames('btn',
                  selectedVariant === chordVariant && 'btn-primary')
                }
                    onClick={() => dispatch(variantClicked(chordVariant))}
                  >
                    {chordVariant}
                  </button>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center">
          <button type="button" className="btn btn-ghost" onClick={() => dispatch(shuffleClicked())}>
            <Shuffle />
          </button>
          <div className="opacity-50">
            Shuffle
          </div>
        </div>

        <div className="flex justify-end mt-8">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              dispatch(nextClicked());
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  function renderVariants() {
    return (
      <div>
        <div className="flex items-center">
          <button type="button" className="btn btn-ghost btn-sm" onClick={() => dispatch(backClicked())}>
            <ChevronLeft />
          </button>

          <div className="text-xl">
            {selectedTonic.title}
            {' '}
            {selectedVariant}
          </div>

        </div>

        <div className="w-full my-6 flex flex-col max-h-80 overflow-y-auto overflow-x-hidden items-center px-8">
          {frettings.map((fretting) => (
            <button
              key={fretting.positionString}
              type="button"
              className={classNames(
                'btn w-full h-auto guitar-chord mb-2',
                selectedChordPosition?.positionString === fretting.positionString ? 'btn-primary btn-active'
                  : 'btn-ghost',
              )}
              onClick={() => dispatch(chordPositionClicked(fretting))}
            >
              <GuitarChord fretting={fretting} />
            </button>
          ))}
        </div>

        <div className="flex justify-end mt-8">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              selectClicked(
                selectedTonic,
                selectedVariant,
                selectedChordPosition,
              );
            }}
          >
            Select
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="px-6 py-4 bg-neutral-800 overflow-hidden rounded shadow-2xl">
      {showVariants ? renderVariants() : renderChordPicker()}
    </div>
  );
}

export default ChordPicker;
