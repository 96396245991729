import React from 'react';
import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.
import './styles.css';
import { QueryClientProvider } from 'react-query';
import Pages from './pages';
import { UserProvider } from './api/user';
import queryClient from './api/queryClient';
import FTUX from './components/ftux';
import { FTUXContext, useFTUXReducer } from './components/ftux.store';

// https://www.strumpatterns.com/Strums/strumsPage00.htm

export default function App() {
  const ftuxReducer = useFTUXReducer();

  return (
    <UserProvider>
      <FTUXContext.Provider value={ftuxReducer}>
        <QueryClientProvider client={queryClient}>
          <Pages />
          <FTUX />
        </QueryClientProvider>
      </FTUXContext.Provider>
    </UserProvider>
  );
}
