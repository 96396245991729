import React, { Fragment, useState, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import GuitarChord from '../components/guitarChord';
import Section from '../components/section';
import {
  addSectionClicked,
  guitarPickerModalClosed,
  fretPositionClicked,
  chordPositionSelected,
  songLoaded,
  useStudioReducer,
  StudioContext,
  songSaved,
} from './studio.store';
import { findFrettings } from '../music/chords';
import Song from '../api/song';
import Spinner from '../components/spinner';
import { useThrottle } from '../hooks/useThrottle';
import { createRandomSection } from '../music/song';
import StudioSidebar from '../components/studioSidebar';
import StudioNavBar from '../components/studioNavbar';
import { useFTUXContext, ftuxActionPerformed } from '../components/ftux.store';
import ChordSymbol from '../components/chordSymbol';

export default function Studio() {
  const { songId } = useParams();
  const [lastHash, setLastHash] = useState();
  const [song, dispatch] = useStudioReducer();
  const [, ftuxDispatch] = useFTUXContext();
  const {
    selectedMeasure,
    showGuitarChordPicker,
    currentFretPosition,
    sections,
    playingMeasureId,
  } = song;

  const songQuery = useQuery(['song', songId], () => Song.getById(songId), {
    onSuccess(newSong) {
      dispatch(songLoaded(newSong));
      // ftuxDispatch(next());
    },
  });

  useThrottle(async () => {
    if (song.id !== songId) {
      return;
    }

    const hash = await Song.hash(song);

    if (hash === lastHash) {
      return;
    }

    setLastHash(hash);
    await Song.update(songId, song);
    dispatch(songSaved());
  }, 5000, [song, songId, lastHash, dispatch]);

  const currentGuitarChordFretting = useMemo(() => {
    if (!playingMeasureId || !sections) {
      return null;
    }

    let measure;

    sections.forEach((section) => {
      const foundMeasure = section.measures.find((m) => m.id === playingMeasureId);
      if (foundMeasure) {
        measure = foundMeasure;
      }
    });

    if (!measure) {
      return null;
    }

    const track = measure.tracks.find((t) => t.type === 'guitar');

    if (!track) {
      return null;
    }

    return {
      ...track.fretting,
      chord: measure.chord,
    };
  }, [playingMeasureId, sections]);

  if (songQuery.isLoading) {
    return (
      <div className="flex justify-center mt-10">
        <Spinner />
      </div>
    );
  }

  if (songQuery.isError) {
    return (
      <div>
        <StudioNavBar showToolBar={false} />

        <div className="flex justify-center mt-10 text-error">
          {songQuery.error.message}
        </div>
      </div>
    );
  }

  return (
    <StudioContext.Provider value={[song, dispatch]}>
      <div className="flex flex-col justify-center items-center z-10">
        <StudioNavBar />

        <div className="w-screen h-screen grid grid-cols-4 relative">
          <div className="col-span-1 p-4 pt-36 md:hidden">
            {currentGuitarChordFretting && (
              <div className="flex flex-col items-center justify-center">
                <div className="mb-2 font-bold">Guitar Chords</div>
                <GuitarChord className="w-40 h-40" fretting={currentGuitarChordFretting} />
                <ChordSymbol chord={currentGuitarChordFretting.chord} />
              </div>
            )}
          </div>

          <div className="col-span-2 md:col-span-4 p-4 pt-36 h-full overflow-y-auto">
            {songQuery.isSuccess && !sections.length && (
              <div className="mb-4">
                Create some sections to your song by clicking the button below
              </div>
            )}

            <div className="flex flex-col justify-center items-center w-full">
              {sections.map(
                (/** @type {import('../music/song').DisplayableSection} */ section, index) => (
                  <Section
                    className="mb-1"
                    key={section.id}
                    section={section}
                    addFtuxClassName={index === 0}
                  />
                ),
              )}
            </div>

            <div className="mb-12">
              <button
                type="button"
                className="btn ftux-add-section-button"
                onClick={async () => {
                  ftuxDispatch(ftuxActionPerformed());
                  const section = await createRandomSection(song.keySignature);
                  dispatch(addSectionClicked(section));
                }}
              >
                Add Section
              </button>
            </div>
          </div>

          <div className={classNames('col-span-1  p-4 pt-36 h-full overflow-y-auto',
            selectedMeasure && 'md:z-50 md:w-full md:absolute md:top-0 md:left-0')}
          >
            <StudioSidebar />
          </div>
        </div>

        <Transition appear show={showGuitarChordPicker} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto max-h-screen"
            onClose={() => dispatch(guitarPickerModalClosed())}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-in transition-opacity duration-300 bg-black"
                enterFrom="opacity-0"
                enterTo="opacity-50"
                entered="bg-black opacity-50"
                leave="ease-out transition-opacity duration-300 bg-black"
                leaveFrom="opacity-50"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-base-300 shadow-xl rounded-2xl h-3/4">
                  <Dialog.Title
                    as="h2"
                    className="text-xl font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Guitar Position
                  </Dialog.Title>
                  <div className="mt-2">
                    {selectedMeasure?.chord.symbol}
                  </div>

                  <div className="mt-4 h-3/4 overflow-y-auto" style={{ maxHeight: 'calc(.5*100vh)' }}>
                    {selectedMeasure && findFrettings(selectedMeasure.chord).map((fretting) => (
                      <button
                        key={fretting.positionString}
                        type="button"
                        className={
                      classNames(
                        'btn w-40 h-40 inline-block',
                        currentFretPosition === fretting.positionString && 'btn-primary',
                        currentFretPosition !== fretting.positionString && 'btn-ghost',
                      )

                      }
                        onClick={() => dispatch(fretPositionClicked(fretting))}
                      >
                        <GuitarChord fretting={fretting} />
                      </button>
                    ))}
                  </div>

                  <button type="button" className="btn btn-primary mt-6" onClick={() => dispatch(chordPositionSelected())}>Select</button>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    </StudioContext.Provider>
  );
}
