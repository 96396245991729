import { Tonic, Accidental, Scale as ScaleType } from '../api/song';
import { getChordsForKeySignature } from './chords';

export function getAllKeySignatures() {
  const tonics = Object.values(Tonic);
  const accidentals = Object.values(Accidental);
  const scales = Object.values(ScaleType);

  const keySignatures = [];

  tonics.forEach((tonic) => {
    accidentals.forEach((accidental) => {
      scales.forEach((scale) => {
        keySignatures.push({
          tonic,
          scale,
          accidental,
        });
      });
    });
  });

  return keySignatures;
}

export const validKeySignatures = getAllKeySignatures().map((key) => ({
  ...key,
  id: key.tonic + key.accidental + key.scale,
})).filter((key) => {
  const chords = getChordsForKeySignature(key);
  const keyHasValidChords = chords.every((chordList) => chordList.every((chord) => (
    !chord.symbol.includes('##')
    && !chord.symbol.includes('bb')
  )));

  return keyHasValidChords;
});
