import React from 'react';
import { Meh } from 'react-feather';
import { useUserContext } from '../api/user';
import NavBar from '../components/navBar';
import NavBarAuthenticated from '../components/navBar.authenticated';

function NotFound() {
  const { user, authState } = useUserContext();

  function renderNotFound() {
    return (
      <div className="flex flex-col items-center mt-10">
        <Meh className="w-20 h-20 my-6" />
        <div className="text-4xl">Not Found</div>
        <div className="">This page does not exist</div>

      </div>
    );
  }

  function renderNav() {
    if (authState === 'complete' && !user) {
      return <NavBar />;
    }

    return <NavBarAuthenticated />;
  }

  return (
    <div>
      {renderNav()}

      {renderNotFound()}
    </div>
  );
}

export default NotFound;
