import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.
import { initializeFirebase } from './api/firebase';

initializeFirebase();

const rootElement = document.getElementById('root');
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement,
);
