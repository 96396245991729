import React, { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { Redirect } from 'react-router-dom';
import { useMutation } from 'react-query';
import Spinner from './spinner';
import { useUserContext, deleteAccount } from '../api/user';

export default function DeleteAccount({ show, onClose }) {
  const [email, setEmail] = useState('');

  const { user } = useUserContext();

  const deleteAccountMutation = useMutation(() => deleteAccount(user), {

  });

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        {deleteAccountMutation.isSuccess && <Redirect to="/" />}

        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            entered="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform dark:bg-base-100 shadow-xl rounded-2xl">
              <Dialog.Title className="text-lg font-medium leading-6 mb-2">Delete Account</Dialog.Title>
              <Dialog.Description className="mb-4 opacity-70">Deleting your account means all data will be lost an can never be retrieved. This includes all songs, favorites and your user account. If you wish to proceed please type your email address below.</Dialog.Description>

              <div className="pb-4 opacity-90">
                Please type
                {' '}
                {user.email}
                {' '}
                to confirm.
              </div>

              <div className="form-control relative mb-4">
                <input
                  type="text"
                  placeholder={user.email}
                  className="input input-bordered w-full"
                  disabled={deleteAccountMutation.isLoading}
                  value={email || ''}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              {deleteAccountMutation.isError && <div className="text-error">{deleteAccountMutation.error.messages}</div>}

              <div className="w-full flex justify-end mt-4">
                <button type="button" className="btn btn-ghost mr-2" onClick={onClose}>Cancel</button>
                <button type="button" className="btn btn-primary" disabled={user.email !== email} onClick={() => deleteAccountMutation.mutate()}>
                  {deleteAccountMutation.isLoading
                    ? <Spinner />
                    : 'Delete Account'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
