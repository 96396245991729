import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { Trash2, Volume2, VolumeX } from 'react-feather';
import RhythmPattern from './rhythmPattern';
import {
  measureClicked,
  deleteMeasureClicked,
  useStudioContext,
  muteMeasureClicked,
} from '../pages/studio.store';
import ChordSymbol from './chordSymbol';
import { ftuxActionPerformed, useFTUXContext } from './ftux.store';

/**
 * @param {object} props the props
 * @param {import('../music/song').DisplayableMeasure} props.measure the measure to display
 * @param {string} props.className a className to add to this component
 * @returns {React.ReactElement} the measure component
 */
export default function Measure({ measure, className, ...props }) {
  const [song, dispatch] = useStudioContext();
  const [, ftuxDispatch] = useFTUXContext();
  const { selectedMeasure, playingMeasureId } = song;

  return (
    <div className={classNames('flex flex-col mb-2', className)}>
      <div
        className={
      classNames(
        'relative cursor-pointer bg-neutral-800 border-2 border-transparent rounded p-3 text-white opacity-90 hover:opacity-100 transition-opacity duration-500 mb-1',
        !measure.isMuted && 'group',
        measure.id === selectedMeasure?.id && 'border-2 border-primary',
      )
      }
        {...props}
        onClick={() => {
          ftuxDispatch(ftuxActionPerformed());
          dispatch(measureClicked(measure));
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <Transition
          as={Fragment}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={Boolean(measure.isMuted).valueOf()}
        >
          <div className="w-full h-full absolute top-0 left-0 bg-neutral-800 bg-opacity-50 flex items-center justify-center z-10">
            <button
              type="button"
              className="btn btn-ghost btn-sm rounded-full"
              aria-label="Mute"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(muteMeasureClicked(measure));
              }}
            >
              <VolumeX className="w-4 h-4" />
            </button>
          </div>
        </Transition>

        <div className="flex justify-between overflow-hidden">
          <div className="text-xl">
            <ChordSymbol chord={measure.chord} />
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-ghost btn-sm rounded-full opacity-0 transition-opacity duration-500 group-hover:opacity-75 hover:opacity-100"
              aria-label="Mute"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(muteMeasureClicked(measure));
              }}
            >
              {measure.isMuted ? <VolumeX className="w-4 h-4" /> : <Volume2 className="w-4 h-4" />}
            </button>

            <button
              type="button"
              className="btn btn-ghost btn-sm rounded-full opacity-0 transition-opacity duration-500 group-hover:opacity-75 hover:opacity-100"
              aria-label="Trash"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(deleteMeasureClicked(measure));
              }}
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* RHYTHMS */}
        {measure.tracks.map((track) => (
          <RhythmPattern
            highlight={null}
            key={track.id}
            rhythm={track.rhythm}
            className="py-4 flex items-center content-center border border-transparent rounded-sm cursor-pointer"
          />
        ))}
      </div>

      <div className={
        classNames('w-full h-1 bg-gray-600 rounded-full overflow-hidden opacity-0 transition-opacity duration-150',
          playingMeasureId === measure.id && 'opacity-100')
      }
      >
        <div
          className="w-full h-full transition-all bg-primary"
        />
      </div>

    </div>
  );
}

Measure.MeasurePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  // rhythms: PropTypes.arrayOf(
  //   RhythmPattern.RhythmPropType,
  // ).isRequired,
});

Measure.propTypes = {
  measure: Measure.MeasurePropType.isRequired,
  className: PropTypes.string,
};

Measure.defaultProps = {
  className: '',
};
