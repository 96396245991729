/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import * as Tone from 'tone';
import { random } from 'lodash';
import { Midi } from '@tonaljs/tonal';
import { Emitter } from 'tone';

/**
 * @typedef {Object} GuitarRhythm
 * @property {string} name - name of rhythm
 * @property {string} id - id of the rhythm
 * @property {string[]} tags - tags of ryhthm
 * @property {GuitarNote[]} notes the notes to play
 */

/**
 * @typedef {object} GuitarNote
 * @property {GuitarArticulation} articulation
 * @property {GuitarStroke} stroke
 * @property {Tone.Unit.Subdivision} duration
 */

/**
 * @typedef {object} GuitarChordFretting
 * @property {number} [barre] where to bar the chord
 * @property {string} positionString the reduced position string
 * @property {number} difficulty how difficult it is to play the chord
 * @property {GuitarChordFretPosition[]} positions the fret positions with notes for each string
 * @property {number[]} frets the fret positions
 */

/**
 * @typedef {object} GuitarChordFretPosition
 * @property {string} stringNote the note of the string
 * @property {number} stringIndex the index of the string from 0-5, but
 * @property {string} note which notes to play
 * @property {number} fret which fret to play
 */

/**
 * @typedef {object} GuitarEvent
 * @property {GuitarArticulation} articulation the articulation of the event
 * @property {Tone.Unit.Frequency[]} notes the notes to play
 * @property {Tone.Unit.Subdivision} duration the duration of the event
 * @property {Tone.Unit.NormalRange} velocity the velocity of the ecvent
 * @property {GuitarStroke} stroke up or down stroke
 * @property {number} time the current tonejs time to play the note
 * @property {boolean} isMuted if the event is muted or not
 */

/**
 * @param {string} key
 * @param {number} from
 * @param {number} to
 * @returns {Object}
 */
function createSampleFilenames(key, from, to) {
  const filenames = {};

  for (let i = from; i <= to; i += 1) {
    const note = key + String(i);
    const midi = Midi.toMidi(note);
    if (midi !== null) {
      filenames[midi] = `/assets/sounds/guitar/sustain/${encodeURIComponent(note)}_Sus_Down1.mp3`;
    }
  }

  return filenames;
}

// Standard guitar tuning
// E2–A2–D3–G3–B3–E4

const GuitarSamplerConfig = {
  urls: {
    // CHOKES
    10: '/assets/sounds/guitar/choke/choke1.mp3',
    11: '/assets/sounds/guitar/choke/choke2.mp3',
    12: '/assets/sounds/guitar/choke/choke3.mp3',
    13: '/assets/sounds/guitar/choke/choke4.mp3',
    14: '/assets/sounds/guitar/choke/choke5.mp3',
    15: '/assets/sounds/guitar/choke/choke6.mp3',
    16: '/assets/sounds/guitar/choke/choke7.mp3',
    17: '/assets/sounds/guitar/choke/choke8.mp3',

    // NOTES
    // starts a 40
    ...createSampleFilenames('a#', 2, 5),
    ...createSampleFilenames('a', 2, 5),
    ...createSampleFilenames('b', 2, 5),
    ...createSampleFilenames('c#', 3, 6),
    ...createSampleFilenames('c', 3, 6),
    ...createSampleFilenames('d#', 3, 5),
    ...createSampleFilenames('d', 3, 6),
    ...createSampleFilenames('e', 2, 5),
    ...createSampleFilenames('f#', 2, 5),
    ...createSampleFilenames('f', 2, 5),
    ...createSampleFilenames('g#', 2, 5),
    ...createSampleFilenames('g', 2, 5),
    // ends at 86
  },
  onload() {
    // eslint-disable-next-line no-console
    console.log('guitar samples loaded');
  },
  onerror(error) {
    // eslint-disable-next-line no-console
    console.log('error loading guitar samples', error);
  },
};

/**
 * @enum {string}
 */
export const GuitarArticulation = {
  Strum: 'strum',
  Choke: 'choke',
  Rest: 'rest',
  Role: 'role',
};

/**
 * @enum {string}
 */
export const GuitarStroke = {
  Up: 'up',
  Down: 'down',
};

function asymetric(amount, nSamples, wsTable) {
  let i; let
    x;
  for (i = 0; i < nSamples; i += 1) {
    x = (i * 2) / nSamples - 1;
    if (x < -0.08905) {
      wsTable[i] = (-3 / 4)
      // eslint-disable-next-line no-restricted-properties
      * (1 - (Math.pow((1 - (Math.abs(x) - 0.032857)), 12))
      + (1 / 3) * (Math.abs(x) - 0.032847)) + 0.01;
    } else if (x >= -0.08905 && x < 0.320018) {
      wsTable[i] = (-6.153 * (x * x)) + 3.9375 * x;
    } else {
      wsTable[i] = 0.630035;
    }
  }
}

// function classicDistorsion(k) {
//   const nSamples = 44100;
//   const curve = new Float32Array(nSamples);
//   const deg = Math.PI / 180; let i = 0; let
//     x;

//   for (; i < nSamples; i += 1) {
//     x = (i * 2) / nSamples - 1;
//     // eslint-disable-next-line no-mixed-operators
//     curve[i] = (3 + k) * x * 57 * deg / (Math.PI + k * Math.abs(x));
//   }
//   return curve;
// }

function asymetricDistortion(distortionValue) {
  const c = new Float32Array(44100);
  const kTuna = distortionValue / 1500;
  asymetric(kTuna, 44100, c);
  return c;
}

// function standardDistortion(distorsionValue) {
//   const k = distorsionValue;
//   const c = classicDistorsion(k);
//   return c;
// }

function calculateDistortionValue(sliderValue) {
  const value = 150 * parseFloat(sliderValue);
  const minp = 0;
  const maxp = 1500;

  // The result should be between 10 an 1500
  const minv = Math.log(10);
  const maxv = Math.log(1500);

  // calculate adjustment factor
  const scale = (maxv - minv) / (maxp - minp);

  return Math.exp(minv + scale * (value - minp));
}

class Guitar extends Tone.Sampler {
  constructor() {
    super(GuitarSamplerConfig);

    const lowShelf1 = new Tone.BiquadFilter();
    lowShelf1.type = 'lowshelf';
    lowShelf1.frequency.value = 720;
    lowShelf1.gain.value = -6;

    const lowShelf2 = new Tone.BiquadFilter();
    lowShelf2.type = 'lowshelf';
    lowShelf2.frequency.value = 320;
    lowShelf2.gain.value = 1.600000023841858;

    const preampGain = new Tone.Gain();
    preampGain.gain.value = 1.0;

    const saturation1 = new Tone.WaveShaper();
    saturation1.curve = asymetricDistortion(calculateDistortionValue(7.8));

    const highPass = new Tone.BiquadFilter();
    highPass.type = 'highpass';
    highPass.frequency.value = 6;
    highPass.Q.value = 0.707099974155426;

    const lowShelf3 = new Tone.BiquadFilter();
    lowShelf3.type = 'lowshelf';
    lowShelf3.frequency.value = 720;
    lowShelf3.gain.value = -6;

    const preampStage2Gain = new Tone.Gain();
    preampStage2Gain.gain.value = 1;

    // const saturation2 = new Tone.WaveShaper();
    // saturation2.curve = standardDistortion(calculateDistortionValue(0.09));

    const bassFilter = new Tone.BiquadFilter();
    bassFilter.frequency.value = 100;
    bassFilter.type = 'lowshelf';
    bassFilter.gain.value = 6.7 - 10 * 7;
    bassFilter.Q.value = 0.7071;

    const midFilter = new Tone.BiquadFilter();
    midFilter.frequency.value = 1700;
    midFilter.type = 'peaking';
    midFilter.gain.value = (7.1 - 5) * 4; // To check with Lepou
    midFilter.Q.value = 0.7071; // To check with Lepou

    const trebleFilter = new Tone.BiquadFilter();
    trebleFilter.frequency.value = 6500;
    trebleFilter.type = 'highshelf';
    trebleFilter.Q.value = (3.2 - 10) * 10; // To check with Lepou

    const presenceFilter = new Tone.BiquadFilter();
    presenceFilter.frequency.value = 3900;
    presenceFilter.type = 'peaking';
    presenceFilter.gain.value = (6.9 - 5) * 2;
    presenceFilter.Q.value = 0.7071; // To check with Lepou

    const eqhicut = new Tone.BiquadFilter();
    eqhicut.frequency.value = 10000;
    eqhicut.type = 'peaking';
    eqhicut.gain.value = -25;

    const eqlocut = new Tone.BiquadFilter();
    eqlocut.frequency.value = 60;
    eqlocut.type = 'peaking';
    eqlocut.gain.value = -19;

    const cabinetImpulseResponse = new Tone.Convolver('/assets/sounds/assets_impulses_cabinet_FenderChampAxisStereo.wav');

    // var preset2 = {
    // "name":"Clean and Warm","boost":false,"LS1Freq":720,
    // "LS1Gain":-6,"LS2Freq":320,"LS2Gain":1.600000023841858,
    // "gain1":1,"distoName1":"asymetric","K1":"7.8","HP1Freq":6,
    // "HP1Q":0.707099974155426,"LS3Freq":720,"LS3Gain":-6,"gain2":1,
    // "distoName2":"standard","K2":"0.9","OG":"7.0","BF":"6.7",
    // "MF":"7.1","TF":"3.2","PF":"6.9","EQ":[10,5,-7,-7,16,0],
    // "MV":"7.2","RN":"Fender Hot Rod","RG":"1.4","CN":"Marshall 1960, axis","CG":"8.8"};

    this.connect(lowShelf1);
    lowShelf1.connect(lowShelf2);
    lowShelf2.connect(preampGain);
    preampGain.connect(saturation1);
    saturation1.connect(highPass);
    highPass.connect(lowShelf3);
    lowShelf3.connect(preampStage2Gain);
    preampStage2Gain.connect(trebleFilter);

    // preampStage2Gain.connect(saturation2);
    // saturation2.connect(trebleFilter);

    trebleFilter.connect(bassFilter);
    bassFilter.connect(midFilter);
    midFilter.connect(presenceFilter);
    presenceFilter.connect(eqlocut);

    eqlocut.connect(eqhicut);
    eqhicut.connect(cabinetImpulseResponse);
    cabinetImpulseResponse.toDestination();
  }

  /**
   * @param {GuitarEvent} event the event to play
   * @param {Tone.Unit.Seconds} time when to play the event
   * @returns {void}
   */
  play(event, time) {
    this.releaseAll(time);

    if (event.isMuted) {
      return;
    }

    switch (event.articulation) {
      case GuitarArticulation.Strum:
        this.strum(event, time);
        return;
      case GuitarArticulation.Choke:
        this.choke(event, time);
        return;
      case GuitarArticulation.Rest:
        this.rest();
        return;
      case GuitarArticulation.Role:
        this.role(event, time);
        return;
      default:
        throw new Error(`guitar articulation not implemented ${event.articulation}`);
    }
  }

  /**
   * @param {GuitarEvent} event
   * @param {Tone.Unit.Seconds} time
   */
  strum(event, time) {
    let { notes } = event;

    if (event.stroke === GuitarStroke.Up) {
      notes = [...event.notes].reverse();
    }

    notes.forEach((note, index) => {
      this.triggerAttackRelease(
        note,
        event.duration,
        time + (random(0.0175, 0.0205) * index),
        event.velocity,
      );
    });
  }

  /**
   * @param {GuitarEvent} event
   * @param {Tone.Unit.Seconds} time
   */
  choke(event, time) {
    this.triggerAttackRelease(
      random(10, 17), // choose a random choke sound
      event.duration,
      time,
      1,
    );
  }

  /**
   * @param {GuitarEvent} event
   * @param {Tone.Unit.Seconds} time
   */
  role(event, time) {
    let { notes } = event;

    if (event.stroke === GuitarStroke.Up) {
      notes = [...event.notes].reverse();
    }

    notes.forEach((note, index) => {
      this.triggerAttackRelease(
        note,
        event.duration,
        time + (random(0.0375, 0.0425) * index),
        event.velocity,
      );
    });
  }

  // eslint-disable-next-line class-methods-use-this
  rest() {
    // this.triggerAttackRelease()
  }
}

const guitar = new Guitar();
guitar.toDestination();

export class GuitarEvent {
  /**
   * @param {import('./song').GuitarTrack} track
   * @param {number} startTime
   * @param {import('../pages/studio.store').DisplayableMeasure} measure
   * @returns {GuitarEvent[]}
   */
  static createFromTrack(track, startTime = 0, measure) {
    let currentTime = startTime;

    const notes = track.fretting.positions.map((p) => p.note);

    return track.rhythm.notes.map((note) => {
      const time = currentTime;
      currentTime += Tone.Time(note.duration).toSeconds();

      return {
        ...note,
        isMuted: measure.isMuted,
        measureId: measure.id,
        articulation: note.articulation,
        stroke: note.stroke,
        duration: note.duration,
        notes,
        time,
        velocity: 0.5,
      };
    });
  }

  /**
   * @param {GuitarChordFretting} fretting
   * @param {Tone.Unit.Subdivision} duration - how long to play the note
   * @param {Tone.Unit.Time} [time] - when to play the note
   * @returns {GuitarEvent[]}
   */
  static createFromFretting(fretting, duration, time = 0) {
    return {
      notes: fretting.positions.map((position) => position.note),
      time,
      velocity: 0.5,
      duration,
      articulation: GuitarArticulation.Strum,
      stroke: GuitarStroke.Down,
    };
  }
}

export class GuitarPart extends Tone.Part {
  /**
   * @param {GuitarEvent[]} events
   * @param {(event: GuitarEvent, time: Tone.Unit.Time)} callback
   */
  constructor(events, callback) {
    super((time, event) => {
      try {
        if (!event.isMuted) {
          guitar.play(event, time);
        }

        if (callback) {
          callback(event, time);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error playing chords', { error, event });
      }
    }, events);
    this.events = events;
    this.emitter = new Emitter();
  }

  on(event, listener) {
    this.emitter.on(event, listener);
  }

  off(event, listener) {
    this.emitter.off(event, listener);
  }

  dispose() {
    this.emitter.dispose();
    super.dispose();
  }

  /**
   *
   * @param {import('./song').GuitarChordFretting} fretting - a fretting object
   * @param {Tone.Unit.Subdivision} duration - how long to play the note
   * @returns {GuitarPart}
   */
  static createFromFretting(fretting, duration) {
    const events = [GuitarEvent.createFromFretting(fretting, duration)];

    return new GuitarPart(events);
  }

  /**
   * @param {Rhythm} rhythm
   * @param {import('@tonaljs/chord').Chord} chord
   * @returns {GuitarPart}
   */
  static createFromRhythm(rhythm, chord) {
    let currentTime = 0;

    const events = rhythm.notes.map((note) => {
      const { duration } = note;
      const time = currentTime;
      currentTime += Tone.Time(duration).toSeconds();

      return {
        ...note,
        notes: chord.chordPosition.positions.map((position) => position.note),
        time,
        velocity: 0.5,
        duration,
      };
    });

    return new GuitarPart(events);
  }
}

export default guitar;
