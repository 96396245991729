/* eslint-disable react/jsx-filename-extension */
import React, { createContext, useContext, useReducer } from 'react';
import { markFTUXDone } from '../api/user';

// CONTEXT
export const FTUXContext = createContext(null);

export function useFTUXContext() {
  return useContext(FTUXContext);
}

export function finished() {
  return (state) => {
    markFTUXDone();

    return {
      ...state,
      run: false,
    };
  };
}

export function ftuxActionPerformed() {
  return (state) => {
    const currentStepIndex = state.stepIndex;

    const nextStepIndex = currentStepIndex + 1;
    const nextStep = { ...state.steps[nextStepIndex] };
    nextStep.index = nextStepIndex;

    return {
      ...state,
      waitForStep: nextStep,
      run: false,
    };
  };
}

export function stepBefore(step) {
  return (state) => ({
    ...state,
    continuous: !!step.showNext,
  });
}

export function nextStepFound() {
  return (state) => ({
    ...state,
    waitForStep: null,
    stepIndex: state.waitForStep.index,
    run: true,
  });
}

export function userStateChanged(user, authState) {
  return (state) => ({
    ...state,
    authState,
    disabled: !!state.waitForUserToStartTour || authState !== 'complete' || !user || !!user.hasDoneFTUX,
  });
}

export function userStartedFtux() {
  return (state) => ({
    ...state,
    disabled: false,
  });
}

export function waitForUserToStartTour() {
  return (state) => ({
    ...state,
    waitForUserToStartTour: true,
  });
}

// REDUCER

function StepContent({ text, showNextButton }) {
  const [, dispatch] = useFTUXContext();

  return (
    <div>
      <div>{text}</div>

      {showNextButton && (
        <div className="flex justify-end mt-4">
          <button type="button" className="btn btn-sm" onClick={() => dispatch(ftuxActionPerformed())}>Next</button>
        </div>
      )}
    </div>
  );
}

const steps = [
  {
    target: '.ftux-projects-link',
    content: <StepContent text="This is the projects page where all your projects can be found." showNextButton />,
    hideFooter: true,
  },
  {
    target: '.ftux-create-project-button',
    content: <StepContent text="Create a project by clicking this button." />,
    hideFooter: true,
  },
  {
    target: '.ftux-choose-project-template-button',
    content: <StepContent text="You can create a song from templates. Click here to create your first project." />,
    hideFooter: true,
  },
  {
    target: '.ftux-add-section-button',
    content: <StepContent text="This is the song editor page. Lets start by adding a section to your song. Click this button." />,
    hideFooter: true,
  },
  {
    target: '.ftux-measure-component',
    content: <StepContent text="This is a measure which is 4 beats of the song. Click on it to start editing." />,
    hideFooter: true,
  },
  {
    target: '.ftux-studio-sidebar',
    content: <StepContent text="This is the measure editor. You can change the chord for this specific measure, the rhythm that is being played and the position that the chord is played in." showNextButton />,
    placement: 'left-start',
    hideFooter: true,
  },
  {
    target: '.ftux-play-button',
    content: <StepContent text="Here is the playback bar. You can use this bar to play/stop the song. Click play to end the tour." />,
    hideFooter: true,
  },
];

const initialState = {
  disabled: true,
  run: true,
  waitForUserToStartTour: false,
  stepIndex: 0,
  steps,
  waitForStep: { ...steps[0], index: 0 },
};

function ftuxReducer(state = initialState, action) {
  try {
    return action(state);
  } catch (error) {
    return state;
  }
}

export function useFTUXReducer() {
  return useReducer(ftuxReducer, initialState);
}
