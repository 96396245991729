import React, { useMemo } from 'react';
import ReactChords from '@tombatossals/react-chords/lib/Chord';
import { isNil } from 'lodash';
import classNames from 'classnames';

const instrument = {
  strings: 6,
  fretsOnChord: 4,
  name: 'Guitar',
  keys: [],
  tunings: {
    standard: ['E', 'A', 'D', 'G', 'B', 'E'],
  },
};

/**
 * @param {object} props
 * @param {import('../music/song').GuitarChordFretting} props.fretting
 * @param {string} [props.className]
 * @returns {React.ReactElement}
 */
function GuitarChord({ fretting, className, ...props }) {
  const chord = useMemo(() => {
    const frets = [-1, -1, -1, -1, -1, -1];
    const barres = !isNil(fretting.barre) ? [fretting.barre.fret] : [];
    let baseFret;

    fretting.positions.forEach(({ stringIndex, fret }) => {
      frets[stringIndex] = fret;
    });

    if (frets.some((f) => f > 4)) {
      baseFret = Math.min(...frets.filter((fret) => fret > 0));
      baseFret -= 1;

      if (barres[0]) {
        barres[0] -= baseFret;
      }

      frets.forEach((fret, index) => {
        if (fret > 0) {
          // eslint-disable-next-line operator-assignment
          frets[index] = frets[index] - baseFret;
        }
      });

      baseFret += 1;
    }

    return {
      ...fretting,
      barres,
      baseFret,
      frets,
    };
  }, [fretting]);

  return (
    <div className={classNames('guitar-chord', className)} {...props}>
      <ReactChords instrument={instrument} chord={chord} />
    </div>
  );
}

export default GuitarChord;
