import { cloneDeep } from 'lodash';
import { createContext, useContext, useReducer } from 'react';

// CONTEXT
const ProjectsContext = createContext(null);

/**
 * @template T
 * @typedef {object} Action
 * @property {string} type the action type
 * @property {T} payload the payload
 */

/**
 *
 */
export function useProjectsContext() {
  return useContext(ProjectsContext);
}

/**
 * @typedef {object} Project
 * @property {string} id the id
 * @property {string} name the name of the project
 */

// ACTIONS

/**
 * @param {Project} project project
 * @returns {Action<Project>} the action with a project payload
 */
export function projectClicked(project) {
  return {
    type: 'projectClicked',
    payload: project,
  };
}

/**
 * @returns {Action} the action
 */
export function renameModalClosed() {
  return {
    type: 'renameModalClosed',
  };
}

/**
 *
 */
export function newProjectClicked() {
  return {
    type: 'newProjectClicked',
  };
}

/**
 * @param {Project} project the project to delete
 * @returns {Action} the action
 */
export function deleteProjectClicked(project) {
  return {
    type: 'deleteProjectClicked',
    payload: project,
  };
}

/**
 * @param {Project} project the project
 * @returns {Action} the action
 */
export function renameClicked(project) {
  return {
    type: 'renameClicked',
    payload: project,
  };
}

/**
 * @returns {Action} the action
 */
export function deleteModalClosed() {
  return {
    type: 'deleteModalClosed',
  };
}

/**
 * @returns {Action} the action
 */
export function deleteProjectConfirmed() {
  return {
    type: 'deleteProjectConfirmed',
  };
}

/**
 *
 */
export function renameProjectConfirmed() {
  return {
    type: 'renameProjectConfirmed',
  };
}

export function newNameChanged(text) {
  return {
    type: 'newNameChanged',
    payload: text,
  };
}

export function dismissCreateProjectDialog() {
  return {
    type: 'dismissCreateProjectDialog',
  };
}

export function queryChanged(queryText) {
  return (state) => ({
    ...state,
    queryText,
  });
}

// REDUCER

const initialState = {
  showRenameModal: false,
  showDeleteModal: false,
  selectedProject: false,
  showCreateProjectModal: false,
  newName: null,
  queryText: null,
  tags: [],
};

/**
 * @param {object} state the state
 * @param {Action<any>} action the action
 * @returns {object} the current state
 */
function projectsReducer(state, action) {
  if (typeof action === 'function') {
    return action(state);
  }

  switch (action.type) {
    case 'projectClicked': {
      return state;
    }

    case 'newProjectClicked': {
      return {
        ...state,
        showCreateProjectModal: true,
      };
    }

    case 'renameClicked': {
      return {
        ...state,
        showRenameModal: true,
        selectedProject: action.payload,
      };
    }

    case 'renameModalClosed': {
      return {
        ...state,
        showRenameModal: false,
      };
    }

    case 'newNameChanged': {
      return {
        ...state,
        newName: action.payload,
      };
    }

    case 'renameProjectConfirmed': {
      const projects = cloneDeep(state.projects);
      const project = projects.find((p) => p.id === state.selectedProject.id);
      project.name = state.newName;

      return {
        ...state,
        showRenameModal: false,
        newName: null,
        projects,
      };
    }

    case 'deleteProjectClicked': {
      return {
        ...state,
        selectedProject: action.payload,
        showDeleteModal: true,
      };
    }

    case 'deleteModalClosed': {
      return {
        ...state,
        showDeleteModal: false,
      };
    }

    case 'deleteProjectConfirmed': {
      return {
        ...state,
        showDeleteModal: false,
      };
    }

    case 'dismissCreateProjectDialog': {
      return {
        ...state,
        showCreateProjectModal: false,
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * @returns {Array} the object
 */
export function useProjectsReducer() {
  return useReducer(projectsReducer, initialState);
}
