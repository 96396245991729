import React, { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { inRange } from 'lodash';
import {
  Search,
  Heart,
  Play,
  Square,
  X,
} from 'react-feather';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  chordChanged,
  useStudioContext,
  rhythmSelected,
  fretPositionClicked,
  measureClicked,
} from '../pages/studio.store';
import { findFrettings, getChordsForKeySignature } from '../music/chords';
import RhythmPattern from './rhythmPattern';
import Rhythms from '../api/rhythms';
import Spinner from './spinner';
import { useDebounce } from '../hooks/useDebounce';
import Player, { PlayerSession } from '../music/player';
import GuitarChord from './guitarChord';

/**
 * @param {HTMLElement} element
 * @param {'vertical' | 'horizontal'} orientation
 * @returns
 */
function isElementInView(element, orientation = 'vertical') {
  const rect = element.getBoundingClientRect();
  const parentRect = element.parentElement.getBoundingClientRect();

  if (orientation === 'vertical') {
    return inRange(rect.top, parentRect.top, parentRect.bottom)
    && inRange(rect.bottom, parentRect.top, parentRect.bottom);
  }

  if (orientation === 'horizontal') {
    return inRange(rect.left, parentRect.left, parentRect.right)
    && inRange(rect.right, parentRect.left, parentRect.right);
  }

  throw new Error(`unsupported orientation ${orientation}`);
}

/**
 *
 * @param {HTMLElement} element
 * @param {string} orientation
 */
function scrollIntoViewIfNotVisible(element, orientation) {
  if (!element) {
    return;
  }

  if (isElementInView(element, orientation)) {
    return;
  }

  element.scrollIntoView();
}

export default function StudioSidebar() {
  const [song, dispatch] = useStudioContext();
  const [rhythmSearchText, setRhythmSearchText] = useState('');
  const [previewRhythmId, setPreviewRhythmId] = useState(null);
  const [activeGuitarTab, setActiveGuitarTab] = useState('rhythms');
  const debouncedRhythmSearchText = useDebounce(rhythmSearchText, 500);

  const {
    selectedMeasure,
    keySignature,
  } = song;

  const chordsLists = useMemo(() => getChordsForKeySignature(keySignature), [keySignature]);
  const variantChords = useMemo(() => chordsLists
    ?.find(([chord]) => chord.tonic === selectedMeasure?.chord?.tonic)
    ?.filter((_, i) => i > 0),
  [chordsLists, selectedMeasure]);

  const frettings = useMemo(() => {
    if (!selectedMeasure?.chord) {
      return [];
    }

    const guitarTrack = selectedMeasure.tracks.find((track) => track.type === 'guitar');

    return findFrettings(selectedMeasure.chord).map((fretting) => ({
      ...fretting,
      isActive: guitarTrack?.fretting?.positionString === fretting.positionString,
    }));
  },
  [selectedMeasure]);

  const queryClient = useQueryClient();
  const rhythmsKey = ['sidebar/rhythms', debouncedRhythmSearchText];
  const rhythmsQuery = useQuery(rhythmsKey, () => {
    if (!debouncedRhythmSearchText) {
      return Rhythms.list();
    }

    return Rhythms.search(debouncedRhythmSearchText);
  });

  const favoriteMutation = useMutation((collectionItem) => {
    if (collectionItem.isFavorite) {
      return Rhythms.removeFavorite(collectionItem.id);
    }
    return Rhythms.addFavorite(collectionItem.id);
  }, {
    retry: false,
    onMutate: async (collectionItem) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(rhythmsKey);

      // Snapshot the previous value
      const previousCollectionItems = queryClient.getQueryData(rhythmsKey);

      // Optimistically update to the new value
      queryClient.setQueryData(rhythmsKey, (old) => [...old].map((oldCollectionItem) => {
        if (oldCollectionItem.id !== collectionItem.id) {
          return oldCollectionItem;
        }

        return {
          ...oldCollectionItem,
          isFavorite: !oldCollectionItem.isFavorite,
        };
      }));

      // Return a context object with the snapshotted value
      return { previousCollectionItems };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newData, context) => {
      queryClient.setQueryData(rhythmsKey, context.previousCollectionItems);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(rhythmsKey);
    },
  });

  useEffect(() => {
    if (!selectedMeasure) {
      return () => {};
    }

    const id = requestAnimationFrame(() => {
      if (!selectedMeasure) {
        return;
      }

      const guitarTrack = selectedMeasure.tracks.find((track) => track.type === 'guitar');

      const tonicElement = document.querySelector(`[data-tonic="${selectedMeasure.chord.tonic}"]`);
      const variantElement = document.querySelector(`[data-variant="${selectedMeasure.chord.variant || ''}"]`);
      const frettingElement = document.querySelector(`[data-fretting="${guitarTrack?.fretting.positionString || ''}"]`);

      scrollIntoViewIfNotVisible(tonicElement);
      scrollIntoViewIfNotVisible(frettingElement, 'horizontal');

      if (variantElement) {
        scrollIntoViewIfNotVisible(variantElement);
      } else {
        const [variantListElement] = document.querySelectorAll('.variant-list');
        if (variantListElement) {
          variantListElement.scrollTop = 0;
        }
      }
    });

    return function cleanup() {
      cancelAnimationFrame(id);
    };
  }, [selectedMeasure]);

  useEffect(() => {
    let cleanup;
    if (activeGuitarTab === 'positions') {
      const id = requestAnimationFrame(() => {
        const guitarTrack = selectedMeasure.tracks.find((track) => track.type === 'guitar');
        const frettingElement = document.querySelector(`[data-fretting="${guitarTrack?.fretting.positionString || ''}"]`);
        scrollIntoViewIfNotVisible(frettingElement, 'horizontal');
      });
      cleanup = () => cancelAnimationFrame(id);
    }

    return cleanup;
  }, [selectedMeasure, activeGuitarTab]);

  function previewRhythmClicked(rhythm) {
    if (Player.isPlaying() && Player.session.key === 'rhythm') {
      Player.stop();
      setPreviewRhythmId(null);
    } else {
      const guitarTrack = selectedMeasure.tracks.find((track) => track.type === 'guitar');

      const session = PlayerSession.createFromGuitarRhythmState({
        rhythm,
        bpm: song.bpm,
        isRepeatOn: true,
        isMetronomeOn: false,
        chord: {
          ...selectedMeasure.chord,
          chordPosition: guitarTrack.fretting,
        },
      });
      Player.play(session);

      setPreviewRhythmId(rhythm.id);
    }
  }

  // RENDER

  if (!selectedMeasure) {
    return null;
  }

  return (
    <div className="mb-10 rounded overflow-hidden bg-neutral-800 ftux-studio-sidebar">
      <div className="h-0 md:h-auto flex justify-end" />

      {/* CHORD */}
      <div className="w-full text-center  border-t-2 border-b-2 border-neutral-900 py-2 relative border-opacity-25">
        <b>Chord</b>
        <div className="absolute right-3 top-1">

          <button type="button" className="btn btn-sm btn-ghost" onClick={() => dispatch(measureClicked(null))}>
            <X />
          </button>
        </div>
      </div>

      <div className="flex h-48">
        <div className="flex-grow p-4 overflow-y-auto h-full relative">
          {chordsLists?.map(([chord]) => (
            <button
              key={chord.name}
              type="button"
              className={
              classNames(
                'btn w-full normal-case mb-1',
                (selectedMeasure.chord.tonic === chord.tonic && 'btn-primary') || 'btn-ghost',
              )
            }
              onClick={() => dispatch(chordChanged(chord))}
              data-tonic={chord.tonic}
            >
              {chord.name}
            </button>
          ))}
        </div>

        <div className="divider divider-vertical mx-1 bg-neutral-800 w-1" />
        <div className="p-4 overflow-y-auto h-full w-60 variant-list">
          {variantChords?.map((chordVariant) => (
            <button
              key={chordVariant.variant}
              type="button"
              data-variant={chordVariant.variant || ''}
              className={
              classNames(
                'btn w-full normal-case mb-1',
                (selectedMeasure.chord.symbol === chordVariant.symbol && 'btn-primary') || 'btn-ghost',
              )
            }
              onClick={() => dispatch(chordChanged(chordVariant))}
            >
              {chordVariant.variant || chordVariant.symbol || chordVariant.name }
            </button>
          ))}
        </div>
      </div>

      <div className="w-full text-center border-t-2 border-b-2 border-neutral-900 py-2 relative border-opacity-25">
        <b>Guitar</b>
      </div>

      <div className="flex flex-col p-4">
        <div className="tabs tabs-boxed inline-block self-center mb-4">
          <button type="button" className={classNames('tab', activeGuitarTab === 'rhythms' && 'tab-active')} onClick={() => setActiveGuitarTab('rhythms')}>Rhythms</button>
          <button type="button" className={classNames('tab', activeGuitarTab === 'positions' && 'tab-active')} onClick={() => setActiveGuitarTab('positions')}>Positions</button>
        </div>

        {activeGuitarTab === 'rhythms' && (
          <div>
            <div className="flex flex-col items-center mb-4">
              <div>{selectedMeasure.tracks[0].rhythm.name}</div>
              <RhythmPattern rhythm={selectedMeasure.tracks[0].rhythm} className="flex-shrink-0" />
            </div>

            <div className="form-control relative mb-4">
              <input type="text" placeholder="Search rhythms..." className="input input-bordered input-sm w-full pl-8" value={rhythmSearchText || ''} onChange={(event) => setRhythmSearchText(event.target.value)} />
              <Search className="label-text ml-2 absolute left-0 w-4 h-4 top-2 opacity-50" />

            </div>

            {rhythmsQuery.isLoading && (<Spinner className="m-auto" />)}
            {rhythmsQuery.isError && (<div className="text-error">{rhythmsQuery.error.message}</div>)}
            {rhythmsQuery.isSuccess && rhythmsQuery.data.length === 0 && (<div>No results.</div>)}
            {rhythmsQuery.isSuccess && rhythmsQuery.data.length > 0 && (
            <div className="w-full mb-4">
              <div className="flex bg-neutral-900 items-center px-2 py-1 rounded mb-2">
                <div aria-label="Play" className="text-center flex-shrink-0 mr-2 w-8 h-8 flex items-center justify-center opacity-10">
                  <Play className="w-4 h-4" />
                </div>

                <div className="flex-grow">Name</div>

                <div aria-label="Favorite" className="text-center flex-shrink-0 w-8 h-8 flex items-center justify-center opacity-10">
                  <Heart className="w-4 h-4" />
                </div>
              </div>
              <div>
                {rhythmsQuery.data.map((rhythm) => (
                  // TODO: fixme
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <div
                    key={rhythm.id}
                    className="flex px-2 items-center border-b-2 border-neutral-500 border-opacity-10 bg-neutral-600 transition-all ease-out bg-opacity-0 hover:bg-opacity-100 cursor-pointer rounded w-full text-left"
                    onClick={() => dispatch(rhythmSelected(rhythm, selectedMeasure.tracks[0]))}
                    role="cell"
                    onKeyDown={() => {}}
                  >
                    <div aria-label="Play" className="text-center flex-shrink-0">
                      <button
                        type="button"
                        className="btn btn-circle btn-sm btn-ghost mr-2 opacity-75 hover:opacity-100"
                        onClick={(event) => {
                          event.stopPropagation();
                          previewRhythmClicked(rhythm);
                        }}
                      >
                        {previewRhythmId === rhythm.id ? <Square className="w-4 h-4" /> : <Play className="w-4 h-4" /> }
                      </button>
                    </div>

                    <div className="flex-grow text-sm">{rhythm.name}</div>
                    <div className="flex justify-center">
                      <button
                        type="button"
                        className="btn btn-circle btn-ghost  btn-sm"
                        onClick={(event) => {
                          event.stopPropagation();
                          favoriteMutation.mutate(rhythm);
                        }}
                      >
                        <Heart fill={rhythm.isFavorite ? 'currentColor' : 'none'} className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            )}
          </div>
        )}

        {activeGuitarTab === 'positions' && (
          <div className="block overflow-x-auto bg-neutral-700 rounded p-2 whitespace-nowrap relative">
              {frettings.map((fretting) => (
                <button
                  type="button"
                  className={classNames(
                    'inline-block w-40 h-40 btn mr-4',
                    fretting.isActive && 'btn-primary btn-outline',
                    !fretting.isActive && 'btn-ghost',
                  )}
                  onClick={() => dispatch(fretPositionClicked(fretting))}
                  data-fretting={fretting.positionString}
                >
                  <GuitarChord
                    key={fretting.positionString}
                    fretting={fretting}
                  />
                </button>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
